@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&family=Nunito+Sans:wght@400;600;700&family=Oxanium:wght@400;500;600;700&family=Poppins:wght@400;500;600&family=Roboto:wght@400;500;700&display=swap'); 
.card {
    font-size: 0.875rem;
}
*,
::after,
::before {
    box-sizing: border-box;
}
html {
    font-family: 'Oxanium', cursive !important;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}
@-ms-viewport {
    width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family:'Barlow', sans-serif;;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}
address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}
dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}
dt {
    font-weight: 500;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}
blockquote {
    margin: 0 0 1rem;
}
dfn {
    font-style: italic;
}
b,
strong {
    font-weight: bolder;
}
small {
    font-size: 80%;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
a {
    color: #eec666;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
a:hover {
    color: #eec666;
    text-decoration: underline;
}
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
    outline: 0;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}
.loader_wrapper{
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%}
    .gif_loader{position:absolute;
        z-index:5;
        top:-160px}
figure {
    margin: 0 0 1rem;
}
img {
    vertical-align: middle;
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
table {
    border-collapse: collapse;
}
caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}
th {
    text-align: inherit;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
    -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
    -webkit-appearance: listbox;
}
textarea {
    overflow: auto;
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}
progress {
    vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
output {
    display: inline-block;
}
summary {
    display: list-item;
    cursor: pointer;
}
template {
    display: none;
}
[hidden] {
    display: none !important;
}
@media print {
    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    img,
    tr {
        page-break-inside: avoid;
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd !important;
    }
}
*,
::after,
::before {
    box-sizing: border-box;
}
html {
    font-family: 'Oxanium', cursive !important;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}
@-ms-viewport {
    width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}
[tabindex="-1"]:focus {
    outline: 0 !important;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Oxanium', cursive;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}
address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}
dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}
dt {
    font-weight: 500;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}
blockquote {
    margin: 0 0 1rem;
}
dfn {
    font-style: italic;
}
b,
strong {
    font-weight: bolder;
}
small {
    font-size: 80%;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
a {
    color: #eec666;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
a:hover {
    color: #eec666;
    text-decoration: underline;
}
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
    outline: 0;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}
figure {
    margin: 0 0 1rem;
}
img {
    vertical-align: middle;
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
table {
    border-collapse: collapse;
}
caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}
th {
    text-align: inherit;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
    -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
    -webkit-appearance: listbox;
}
textarea {
    overflow: auto;
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}
progress {
    vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
output {
    display: inline-block;
}
summary {
    display: list-item;
    cursor: pointer;
}
template {
    display: none;
}
[hidden] {
    display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
}
.h1,
h1 {
    font-size: 3.3125rem;
}
.h2,
h2 {
    font-size: 2.25rem;
}
.h3,
h3 {
    font-size: 1.5625rem;
}
.h4,
h4 {
    font-size: 1.125rem;
}
.h5,
h5 {
    font-size: 1.0625rem;
}
.h6,
h6 {
    font-size: 0.75rem;
}
.lead {
    font-size: 1.25rem;
    font-weight: 300;
}
.display-1 {
    font-size: 7rem;
    font-weight: 300;
    line-height: 1.2;
}
.display-2 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}
.display-3 {
    font-size: 2.8125rem;
    font-weight: 300;
    line-height: 1.2;
}
.display-4 {
    font-size: 2.125rem;
    font-weight: 300;
    line-height: 1.2;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
    font-size: 80%;
    font-weight: 400;
}
.mark,
mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.list-inline {
    padding-left: 0;
    list-style: none;
}
.list-inline-item {
    display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}
.initialism {
    font-size: 90%;
    text-transform: uppercase;
}
.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}
.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}
.blockquote-footer::before {
    content: "\2014 \00A0";
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #fafafa;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    max-width: 100%;
    height: auto;
}
.figure {
    display: inline-block;
}
.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}
.figure-caption {
    font-size: 90%;
    color: #6c757d;
}
code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
code {
    font-size: 87.5%;
    color: #e91e63;
    word-break: break-word;
}
a > code {
    color: inherit;
}
kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
    box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500;
    box-shadow: none;
}
pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}
.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}
.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-first {
    order: -1;
}
.order-last {
    order: 13;
}
.order-0 {
    order: 0;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.order-4 {
    order: 4;
}
.order-5 {
    order: 5;
}
.order-6 {
    order: 6;
}
.order-7 {
    order: 7;
}
.order-8 {
    order: 8;
}
.order-9 {
    order: 9;
}
.order-10 {
    order: 10;
}
.order-11 {
    order: 11;
}
.order-12 {
    order: 12;
}
.offset-1 {
    margin-left: 8.333333%;
}
.offset-2 {
    margin-left: 16.666667%;
}
.offset-3 {
    margin-left: 25%;
}
.offset-4 {
    margin-left: 33.333333%;
}
.offset-5 {
    margin-left: 41.666667%;
}
.offset-6 {
    margin-left: 50%;
}
.offset-7 {
    margin-left: 58.333333%;
}
.offset-8 {
    margin-left: 66.666667%;
}
.offset-9 {
    margin-left: 75%;
}
.offset-10 {
    margin-left: 83.333333%;
}
.offset-11 {
    margin-left: 91.666667%;
}
@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-sm-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-sm-first {
        order: -1;
    }
    .order-sm-last {
        order: 13;
    }
    .order-sm-0 {
        order: 0;
    }
    .order-sm-1 {
        order: 1;
    }
    .order-sm-2 {
        order: 2;
    }
    .order-sm-3 {
        order: 3;
    }
    .order-sm-4 {
        order: 4;
    }
    .order-sm-5 {
        order: 5;
    }
    .order-sm-6 {
        order: 6;
    }
    .order-sm-7 {
        order: 7;
    }
    .order-sm-8 {
        order: 8;
    }
    .order-sm-9 {
        order: 9;
    }
    .order-sm-10 {
        order: 10;
    }
    .order-sm-11 {
        order: 11;
    }
    .order-sm-12 {
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.333333%;
    }
    .offset-sm-2 {
        margin-left: 16.666667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.333333%;
    }
    .offset-sm-5 {
        margin-left: 41.666667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.333333%;
    }
    .offset-sm-8 {
        margin-left: 66.666667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.333333%;
    }
    .offset-sm-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-md-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-md-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-md-first {
        order: -1;
    }
    .order-md-last {
        order: 13;
    }
    .order-md-0 {
        order: 0;
    }
    .order-md-1 {
        order: 1;
    }
    .order-md-2 {
        order: 2;
    }
    .order-md-3 {
        order: 3;
    }
    .order-md-4 {
        order: 4;
    }
    .order-md-5 {
        order: 5;
    }
    .order-md-6 {
        order: 6;
    }
    .order-md-7 {
        order: 7;
    }
    .order-md-8 {
        order: 8;
    }
    .order-md-9 {
        order: 9;
    }
    .order-md-10 {
        order: 10;
    }
    .order-md-11 {
        order: 11;
    }
    .order-md-12 {
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.333333%;
    }
    .offset-md-2 {
        margin-left: 16.666667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.333333%;
    }
    .offset-md-5 {
        margin-left: 41.666667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.333333%;
    }
    .offset-md-8 {
        margin-left: 66.666667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.333333%;
    }
    .offset-md-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-lg-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-lg-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-lg-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-lg-first {
        order: -1;
    }
    .order-lg-last {
        order: 13;
    }
    .order-lg-0 {
        order: 0;
    }
    .order-lg-1 {
        order: 1;
    }
    .order-lg-2 {
        order: 2;
    }
    .order-lg-3 {
        order: 3;
    }
    .order-lg-4 {
        order: 4;
    }
    .order-lg-5 {
        order: 5;
    }
    .order-lg-6 {
        order: 6;
    }
    .order-lg-7 {
        order: 7;
    }
    .order-lg-8 {
        order: 8;
    }
    .order-lg-9 {
        order: 9;
    }
    .order-lg-10 {
        order: 10;
    }
    .order-lg-11 {
        order: 11;
    }
    .order-lg-12 {
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.333333%;
    }
    .offset-lg-2 {
        margin-left: 16.666667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.333333%;
    }
    .offset-lg-5 {
        margin-left: 41.666667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.333333%;
    }
    .offset-lg-8 {
        margin-left: 66.666667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.333333%;
    }
    .offset-lg-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-xl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-xl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-xl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-xl-first {
        order: -1;
    }
    .order-xl-last {
        order: 13;
    }
    .order-xl-0 {
        order: 0;
    }
    .order-xl-1 {
        order: 1;
    }
    .order-xl-2 {
        order: 2;
    }
    .order-xl-3 {
        order: 3;
    }
    .order-xl-4 {
        order: 4;
    }
    .order-xl-5 {
        order: 5;
    }
    .order-xl-6 {
        order: 6;
    }
    .order-xl-7 {
        order: 7;
    }
    .order-xl-8 {
        order: 8;
    }
    .order-xl-9 {
        order: 9;
    }
    .order-xl-10 {
        order: 10;
    }
    .order-xl-11 {
        order: 11;
    }
    .order-xl-12 {
        order: 12;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.333333%;
    }
    .offset-xl-2 {
        margin-left: 16.666667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.333333%;
    }
    .offset-xl-5 {
        margin-left: 41.666667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.333333%;
    }
    .offset-xl-8 {
        margin-left: 66.666667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.333333%;
    }
    .offset-xl-11 {
        margin-left: 91.666667%;
    }
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}
.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}
.table tbody + tbody {
    border-top: 2px solid rgba(0, 0, 0, 0.06);
}
.table .table {
    background-color: #fafafa;
}
.table-sm td,
.table-sm th {
    padding: 0.3rem;
}
.table-bordered {
    border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered td,
.table-bordered th {
    border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
    background-color: #e3c3e9;
}
.table-hover .table-primary:hover {
    background-color: #dab0e2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #dab0e2;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
    background-color: #d6d8db;
}
.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
    background-color: #cde9ce;
}
.table-hover .table-success:hover {
    background-color: #bbe1bd;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #bbe1bd;
}
.table-info,
.table-info > td,
.table-info > th {
    background-color: #b8ecf3;
}
.table-hover .table-info:hover {
    background-color: #a2e6ef;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #a2e6ef;
}
.table-warning,
.table-warning > td,
.table-warning > th {
    background-color: #ffe2b8;
}
.table-hover .table-warning:hover {
    background-color: #ffd89f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #ffd89f;
}
.table-danger,
.table-danger > td,
.table-danger > th {
    background-color: #fccac7;
}
.table-hover .table-danger:hover {
    background-color: #fbb3af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #fbb3af;
}
.table-light,
.table-light > td,
.table-light > th {
    background-color: #fdfdfe;
}
.table-hover .table-light:hover {
    background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
    background-color: #c6c8ca;
}
.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th {
    background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
    color: #fafafa;
    background-color: #212529;
    border-color: #32383e;
}
.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: rgba(0, 0, 0, 0.06);
}
.table-dark {
    color: #fafafa;
    background-color: #212529;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #32383e;
}
.table-dark.table-bordered {
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}
@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md > .table-bordered {
        border: 0;
    }
}
@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}
@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
    border: 0;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.4375rem 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border: 1px solid #d2d2d2;
    border-radius: 0;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form-control:focus {
    color: #495057;
    background-color: rgba(0, 0, 0, 0);
    border-color: #d176e1;
    outline: 0;
    box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
    height: calc(2.4375rem + 2px);
}
select.form-control:focus::-ms-value {
    color: #495057;
    background-color: rgba(0, 0, 0, 0);
}
.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}
.col-form-label {
    padding-top: calc(0.4375rem + 1px);
    padding-bottom: calc(0.4375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}
.col-form-label-lg {
    padding-top: calc(0.5625rem + 1px);
    padding-bottom: calc(0.5625rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}
.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}
.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-append > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-append > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text {
    padding-right: 0;
    padding-left: 0;
}
.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
    padding: 0.25rem 0;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
    height: calc(2.125rem + 2px);
}
.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
    padding: 0.5625rem 0;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
    height: calc(4.125rem + 2px);
}
.form-group {
    margin-bottom: 1rem;
}
.form-text {
    display: block;
    margin-top: 0.25rem;
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}
.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
}
.form-check-label {
    margin-bottom: 0;
}
.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}
.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #4caf50;
}
.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(76, 175, 80, 0.8);
    border-radius: 0.2rem;
}
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
    border-color: #4caf50;
}
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #4caf50;
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
    display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
    color: #4caf50;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #4caf50;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #a3d7a5;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #6ec071;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #4caf50;
}
.custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f44336;
}
.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(244, 67, 54, 0.8);
    border-radius: 0.2rem;
}
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    border-color: #f44336;
}
.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
    display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: #f44336;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #f44336;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    background-color: #fbb4af;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
    background-color: #f77066;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #f44336;
}
.custom-file-input.is-invalid ~ .custom-file-label::before,
.was-validated .custom-file-input:invalid ~ .custom-file-label::before {
    border-color: inherit;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.form-inline .form-check {
    width: 100%;
}
@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .input-group {
        width: auto;
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.46875rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:focus,
.btn:hover {
    text-decoration: none;
}
.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.btn.disabled,
.btn:disabled {
    opacity: 0.65;
    box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    background-image: none;
    box-shadow: none;
}
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25), none;
}
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}
.btn-primary {
    color: #fff;
    background-color: #eec666;
    border-color: #eec666;
    box-shadow: none;
}
.btn-primary:hover {
    color: #fff;
    background-color: #802091;
    border-color: #771e86;
}
.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #eec666;
    border-color: #eec666;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #771e86;
    border-color: #6e1b7c;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    box-shadow: none;
}
.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-success {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: none;
}
.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40;
}
.btn-success.focus,
.btn-success:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-info {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4;
    box-shadow: none;
}
.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1;
}
.btn-info.focus,
.btn-info:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-warning {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800;
    box-shadow: none;
}
.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00;
}
.btn-warning.focus,
.btn-warning:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-danger {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: none;
}
.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d;
}
.btn-danger.focus,
.btn-danger:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: none;
}
.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    box-shadow: none;
}
.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary {
    color: #eec666;
    background-color: transparent;
    background-image: none;
    border-color: #eec666;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #eec666;
    border-color: #eec666;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #eec666;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #eec666;
    border-color: #eec666;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
    color: #4caf50;
    background-color: transparent;
    background-image: none;
    border-color: #4caf50;
}
.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-info {
    color: #00bcd4;
    background-color: transparent;
    background-image: none;
    border-color: #00bcd4;
}
.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-warning {
    color: #ff9800;
    background-color: transparent;
    background-image: none;
    border-color: #ff9800;
}
.btn-outline-warning:hover {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-danger {
    color: #f44336;
    background-color: transparent;
    background-image: none;
    border-color: #f44336;
}
.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}
.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}
.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
    font-weight: 400;
    color: #eec666;
    background-color: transparent;
}
.btn-link:hover {
    color: #eec666;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}
.btn-link.focus,
.btn-link:focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
}
.btn-link.disabled,
.btn-link:disabled {
    color: #999;
}
.btn-group-lg > .btn,
.btn-lg {
    padding: 1.125rem 2.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
    padding: 0.40625rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.1875rem;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-block + .btn-block {
    margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
    width: 100%;
}
.fade {
    opacity: 0;
    transition: opacity 0.15s linear;
}
.fade.show {
    opacity: 1;
}
.collapse {
    display: none;
}
.collapse.show {
    display: block;
}
tr.collapse.show {
    display: table-row;
}
tbody.collapse.show {
    display: table-row-group;
}
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
.dropdown,
.dropup {
    position: relative;
}
.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropright .dropdown-menu {
    margin-top: 0;
    margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropright .dropdown-toggle::after {
    vertical-align: 0;
}
.dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}
.dropleft .dropdown-toggle::after {
    display: none;
}
.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}
.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.625rem 1.25rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #eec666;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
}
.dropdown-menu.show {
    display: block;
}
.dropdown-header {
    display: block;
    padding: 0.5rem 1.25rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
    position: relative;
    flex: 0 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
    z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
    z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px;
}
.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group {
    width: auto;
}
.btn-group > .btn:first-child {
    margin-left: 0;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}
.dropdown-toggle-split::after {
    margin-left: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
}
.btn-group.show .dropdown-toggle {
    box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none;
}
.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.input-group > .custom-file:focus,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
    z-index: 3;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
    margin-left: -1px;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file {
    display: flex;
    align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
    display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px;
}
.input-group-prepend {
    margin-right: -1px;
}
.input-group-append {
    margin-left: -1px;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.4375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
    margin-top: 0;
}
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}
.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #eec666;
    box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #dfa0ea;
    box-shadow: none;
}
.custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
}
.custom-control-label {
    margin-bottom: 0;
}
.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #1e2023;
	/* color: #000; */
	border: 1px solid #e943ab;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #eec666;
    box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(156, 39, 176, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(156, 39, 176, 0.5);
}
.custom-radio .custom-control-label::before {
    border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #eec666;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(156, 39, 176, 0.5);
}
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.4375rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #d2d2d2;
    border-radius: 0.25rem;
    appearance: none;
}
.custom-select:focus {
    border-color: #d176e1;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(209, 118, 225, 0.5);
}
.custom-select:focus::-ms-value {
    color: #495057;
    background-color: rgba(0, 0, 0, 0);
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}
.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}
.custom-select::-ms-expand {
    opacity: 0;
}
.custom-select-sm {
    height: calc(2.125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
}
.custom-select-lg {
    height: calc(4.125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
}
.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.4375rem + 2px);
    margin-bottom: 0;
}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.4375rem + 2px);
    margin: 0;
    opacity: 0;
}
.custom-file-input:focus ~ .custom-file-control {
    border-color: #d176e1;
    box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-file-input:focus ~ .custom-file-control::before {
    border-color: #d176e1;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
}
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.4375rem + 2px);
    padding: 0.46875rem 1rem;
    line-height: 1.3;
    color: #495057;
    background-color: transparent;
    border: 0 solid #d2d2d2;
    border-radius: 0;
    box-shadow: none;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.4375rem + 2px) - 0 * 2);
    padding: 0.46875rem 1rem;
    line-height: 1.3;
    color: #495057;
    content: "Browse";
    background-color: transparent;
    border-left: 0 solid #d2d2d2;
    border-radius: 0;
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}
.nav-link.disabled {
    color: #6c757d;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
    margin-bottom: -1px;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fafafa;
    border-color: #dee2e6 #dee2e6 #fafafa;
}
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.nav-pills .nav-link {
    border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #eec666;
}
.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}
.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}
.tab-content > .tab-pane {
    display: none;
}
.tab-content > .active {
    display: block;
}
.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}
.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}
@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
    .navbar-expand-sm .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}
@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
    .navbar-expand-md .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}
@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
    .navbar-expand-lg .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}
@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
    .navbar-expand-xl .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}
.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav {
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}
.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand .navbar-toggler {
    display: none;
}
.navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
}
.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
    color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
}
.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
    color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff;
}
.social_list {
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: center;
}
.social_list li {
    width: auto;
}
.social_links_header {
    background-image: linear-gradient(to right, #fe1301 0, #ff7713 100%);
    border-radius: 40px !important;
    box-sizing: border-box;
    color: #7d8082 !important;
    display: block;
    height: 45px;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 3px !important;
    text-decoration: none;
    width: 45px;
    z-index: 2;
    box-shadow: 0 0 1px 5px rgb(47 51 54 / 88%);
    font-size: 16px !important;
}
.social_links_header:hover {
    color: #fff;
}
.social_links_header i {
    align-items: center;
    background-image: linear-gradient(#2b2a2c, #4a5157);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    transition: background 0.5s ease;
    width: 100%;
    text-shadow: 4px 6px 5px rgb(0 0 0 / 71%);
}
.social_links_header:hover i {
    background: 0 0;
    color: #fff;
}
a.mobile_menu {
    color: #fff;
}
a.mobile_menu svg {
    vertical-align: middle;
    margin-right: 8px;
}
a.mobile_menu:hover {
    color: #ff7800;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0.25rem;
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}
.card-title {
    margin-bottom: 0.75rem;
}
.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}
.card-text:last-child {
    margin-bottom: 0;
}
.card-link:hover {
    text-decoration: none;
}
.card-link + .card-link {
    margin-left: 1.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}
.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}
.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}
.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}
.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
    display: flex;
    flex-direction: column;
}
.card-deck .card {
    margin-bottom: 15px;
}
@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}
.card-group {
    display: flex;
    flex-direction: column;
}
.card-group > .card {
    margin-bottom: 15px;
}
@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }
    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group > .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:first-child .card-header,
    .card-group > .card:first-child .card-img-top {
        border-top-right-radius: 0;
    }
    .card-group > .card:first-child .card-footer,
    .card-group > .card:first-child .card-img-bottom {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:last-child .card-header,
    .card-group > .card:last-child .card-img-top {
        border-top-left-radius: 0;
    }
    .card-group > .card:last-child .card-footer,
    .card-group > .card:last-child .card-img-bottom {
        border-bottom-left-radius: 0;
    }
    .card-group > .card:only-child {
        border-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-header,
    .card-group > .card:only-child .card-img-top {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-footer,
    .card-group > .card:only-child .card-img-bottom {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
        border-radius: 0;
    }
}
.card-columns .card {
    margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}
.breadcrumb-item.active {
    color: #6c757d;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #eec666;
    background-color: transparent;
    border: 0 solid #dee2e6;
}
.page-link:hover {
    color: #eec666;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #eec666;
    border-color: #eec666;
}
.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color: #dee2e6;
}
.pagination-lg .page-link {
    padding: 0.75rem 0;
    font-size: 1.25rem;
    line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
    padding: 0.25rem 0;
    font-size: 0.875rem;
    line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}
@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}
.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-heading {
    color: inherit;
}
.alert-link {
    font-weight: 500;
}
.alert-dismissible {
    padding-right: 4rem;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}
.alert-primary {
    color: #51145c;
    background-color: #ebd4ef;
    border-color: #e3c3e9;
}
.alert-primary hr {
    border-top-color: #dab0e2;
}
.alert-primary .alert-link {
    color: #2c0b32;
}
.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}
.alert-secondary hr {
    border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
    color: #202326;
}
.alert-success {
    color: #285b2a;
    background-color: #dbefdc;
    border-color: #cde9ce;
}
.alert-success hr {
    border-top-color: #bbe1bd;
}
.alert-success .alert-link {
    color: #18381a;
}
.alert-info {
    color: #00626e;
    background-color: #ccf2f6;
    border-color: #b8ecf3;
}
.alert-info hr {
    border-top-color: #a2e6ef;
}
.alert-info .alert-link {
    color: #00353b;
}
.alert-warning {
    color: #854f00;
    background-color: #ffeacc;
    border-color: #ffe2b8;
}
.alert-warning hr {
    border-top-color: #ffd89f;
}
.alert-warning .alert-link {
    color: #523100;
}
.alert-danger {
    color: #7f231c;
    background-color: #fdd9d7;
    border-color: #fccac7;
}
.alert-danger hr {
    border-top-color: #fbb3af;
}
.alert-danger .alert-link {
    color: #551713;
}
.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}
.alert-light hr {
    border-top-color: #ececf6;
}
.alert-light .alert-link {
    color: #686868;
}
.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}
.alert-dark hr {
    border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
    color: #040505;
}
.textComingSoon{
    font-size: 50px;
    color: #12a2e4;
    font-weight: 700;
}
.lockStakeMain{
    background: #1f2025 !important;
}
.lockContentRight{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}
.walletMainbg{
    background: rgb(31,31,36) !important;
    background: linear-gradient(77deg, rgba(31,31,36,1) 0%, rgba(72,50,37,1) 100%) !important; 
}
.walletSupportLeft .input-group{
    background: #4a494d;
    border-radius: .25rem;
}
.walletSupportLeft .form-group label{
    font-size: 20px;
    color: #edeaea;
    margin-bottom: 10px;
}
.walletSupportLeft .form-control{
    background: none;
    color: #ffffff;
    padding: 5px 15px;
}
.walletSupportLeft .input-group .input-group-append{
    display: inline-flex;
    align-items: center;
    padding: 0 15px;
    background: #0099e0;
    color: #ffffff;
    border-radius: .25rem;
    cursor: pointer;
}
@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #eec666;
    transition: width 0.6s ease;
}
.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}
.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}
.media {
    display: flex;
    align-items: flex-start;
}
.media-body {
    flex: 1;
}
.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}
.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: inherit;
    border: 0 solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.list-group-item:focus,
.list-group-item:hover {
    z-index: 1;
    text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    background-color: inherit;
}
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #eec666;
    border-color: #eec666;
}
.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
}
.list-group-item-primary {
    color: #51145c;
    background-color: #e3c3e9;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #51145c;
    background-color: #dab0e2;
}
.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #51145c;
    border-color: #51145c;
}
.list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #383d41;
    background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
}
.list-group-item-success {
    color: #285b2a;
    background-color: #cde9ce;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #285b2a;
    background-color: #bbe1bd;
}
.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #285b2a;
    border-color: #285b2a;
}
.list-group-item-info {
    color: #00626e;
    background-color: #b8ecf3;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #00626e;
    background-color: #a2e6ef;
}
.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00626e;
    border-color: #00626e;
}
.list-group-item-warning {
    color: #854f00;
    background-color: #ffe2b8;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #854f00;
    background-color: #ffd89f;
}
.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854f00;
    border-color: #854f00;
}
.list-group-item-danger {
    color: #7f231c;
    background-color: #fccac7;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #7f231c;
    background-color: #fbb3af;
}
.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f231c;
    border-color: #7f231c;
}
.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #818182;
    background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}
.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1b1e21;
    background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
}
.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.badge:empty {
    display: none;
}
.btn .badge {
    position: relative;
    top: -1px;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-primary {
    color: #fff;
    background-color: #eec666;
}
.badge-primary[href]:focus,
.badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #771e86;
}
.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}
.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #545b62;
}
.badge-success {
    color: #fff;
    background-color: #4caf50;
}
.badge-success[href]:focus,
.badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #3d8b40;
}
.badge-info {
    color: #fff;
    background-color: #00bcd4;
}
.badge-info[href]:focus,
.badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #008fa1;
}
.badge-warning {
    color: #212529;
    background-color: #ff9800;
}
.badge-warning[href]:focus,
.badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #cc7a00;
}
.badge-danger {
    color: #fff;
    background-color: #f44336;
}
.badge-danger[href]:focus,
.badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #ea1c0d;
}
.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}
.badge-light[href]:focus,
.badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
}
.badge-dark {
    color: #fff;
    background-color: #343a40;
}
.badge-dark[href]:focus,
.badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
}
.modal-open {
    overflow: hidden;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
}
.modal.show .modal-dialog {
    transform: translate(0, 0);
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    outline: 0;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: 0.9;
}
.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
    margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
}
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }
    .modal-content {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    }
    .modal-sm {
        max-width: 300px;
    }
}
@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: 'Oxanium', cursive;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}
.tooltip.show {
    opacity: 0.9;
}
.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}
.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: rgba(97, 97, 97, 0.9);
}
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: rgba(97, 97, 97, 0.9);
    border-radius: 0.25rem;
}
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: 'Oxanium', cursive;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
    margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow {
    bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
    border-width: 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-top .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
    margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] .arrow,
.bs-popover-right .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-right .arrow::after {
    left: 1px;
    border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
    margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
    top: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-bottom .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
    margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow,
.bs-popover-left .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-left .arrow::after {
    right: 1px;
    border-left-color: #fff;
}
.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
    display: none;
}
.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}
.carousel {
    position: relative;
}
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    backface-visibility: hidden;
    perspective: 1000px;
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
}
.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translate3d(0, 0, 0);
    }
}
.active.carousel-item-right,
.carousel-item-next {
    transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
    .active.carousel-item-right,
    .carousel-item-next {
        transform: translate3d(100%, 0, 0);
    }
}
.active.carousel-item-left,
.carousel-item-prev {
    transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
    .active.carousel-item-left,
    .carousel-item-prev {
        transform: translate3d(-100%, 0, 0);
    }
}
.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}
.carousel-control-prev {
    left: 0;
}
.carousel-control-next {
    right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}
.align-baseline {
    vertical-align: baseline !important;
}
.align-top {
    vertical-align: top !important;
}
.align-middle {
    vertical-align: middle !important;
}
.align-bottom {
    vertical-align: bottom !important;
}
.align-text-bottom {
    vertical-align: text-bottom !important;
}
.align-text-top {
    vertical-align: text-top !important;
}
.bg-primary {
    background-color: #eec666 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #771e86 !important;
}
.bg-secondary {
    background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #545b62 !important;
}
.bg-success {
    background-color: #4caf50 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #3d8b40 !important;
}
.bg-info {
    background-color: #00bcd4 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #008fa1 !important;
}
.bg-warning {
    background-color: #ff9800 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #cc7a00 !important;
}
.bg-danger {
    background-color: #f44336 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #ea1c0d !important;
}
.bg-light {
    background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important;
}
.bg-dark {
    background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important;
}
.bg-white {
    background-color: #fff !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.border {
    border: 1px solid #dee2e6 !important;
}
.border-top {
    border-top: 1px solid #dee2e6 !important;
}
.border-right {
    border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
    border-left: 1px solid #dee2e6 !important;
}
.border-0 {
    border: 0 !important;
}
.border-top-0 {
    border-top: 0 !important;
}
.border-right-0 {
    border-right: 0 !important;
}
.border-bottom-0 {
    border-bottom: 0 !important;
}
.border-left-0 {
    border-left: 0 !important;
}
.border-primary {
    border-color: #eec666 !important;
}
.border-secondary {
    border-color: #6c757d !important;
}
.border-success {
    border-color: #4caf50 !important;
}
.border-info {
    border-color: #00bcd4 !important;
}
.border-warning {
    border-color: #ff9800 !important;
}
.border-danger {
    border-color: #f44336 !important;
}
.border-light {
    border-color: #f8f9fa !important;
}
.border-dark {
    border-color: #343a40 !important;
}
.border-white {
    border-color: #fff !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}
.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
    border-radius: 50% !important;
}
.rounded-0 {
    border-radius: 0 !important;
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.d-none {
    display: none !important;
}
.d-inline {
    display: inline !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-block {
    display: block !important;
}
.d-table {
    display: table !important;
}
.d-table-row {
    display: table-row !important;
}
.d-table-cell {
    display: table-cell !important;
}
.d-flex {
    display: flex !important;
}
.d-inline-flex {
    display: inline-flex !important;
}
@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}
@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive::before {
    display: block;
    content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.embed-responsive-21by9::before {
    padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
    padding-top: 56.25%;
}
.embed-responsive-4by3::before {
    padding-top: 75%;
}
.embed-responsive-1by1::before {
    padding-top: 100%;
}
.flex-row {
    flex-direction: row !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.flex-nowrap {
    flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}
.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}
.align-items-start {
    align-items: flex-start !important;
}
.align-items-end {
    align-items: flex-end !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-baseline {
    align-items: baseline !important;
}
.align-items-stretch {
    align-items: stretch !important;
}
.align-content-start {
    align-content: flex-start !important;
}
.align-content-end {
    align-content: flex-end !important;
}
.align-content-center {
    align-content: center !important;
}
.align-content-between {
    align-content: space-between !important;
}
.align-content-around {
    align-content: space-around !important;
}
.align-content-stretch {
    align-content: stretch !important;
}
.align-self-auto {
    align-self: auto !important;
}
.align-self-start {
    align-self: flex-start !important;
}
.align-self-end {
    align-self: flex-end !important;
}
.align-self-center {
    align-self: center !important;
}
.align-self-baseline {
    align-self: baseline !important;
}
.align-self-stretch {
    align-self: stretch !important;
}
@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}
.float-left {
    float: left !important;
}
.float-right {
    float: right !important;
}
.float-none {
    float: none !important;
}
@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
}
@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
}
@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
}
@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
}
.position-static {
    position: static !important;
}
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.position-fixed {
    position: fixed !important;
}
.position-sticky {
    position: sticky !important;
}
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}
@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.next::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    clip-path: none;
}
.w-25 {
    width: 25% !important;
}
.w-50 {
    width: 50% !important;
}
.w-75 {
    width: 75% !important;
}
.w-100 {
    width: 100% !important;
}
.h-25 {
    height: 25% !important;
}
.h-50 {
    height: 50% !important;
}
.h-75 {
    height: 75% !important;
}
.h-100 {
    height: 100% !important;
}
.mw-100 {
    max-width: 100% !important;
}
.mh-100 {
    max-height: 100% !important;
}
.m-0 {
    margin: 0 !important;
}
.mt-0,
.my-0 {
    margin-top: 0 !important;
}
.mr-0,
.mx-0 {
    margin-right: 0 !important;
}
.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
    margin-left: 0 !important;
}
.m-1 {
    margin: 0.25rem !important;
}
.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}
.m-2 {
    margin: 0.5rem !important;
}
.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}
.m-3 {
    margin: 1rem !important;
}
.mt-3,
.my-3 {
    margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}
.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}
.m-4 {
    margin: 1.5rem !important;
}
.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}
.m-5 {
    margin: 3rem !important;
}
.mt-5,
.my-5 {
    margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}
.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}
.p-0 {
    padding: 0 !important;
}
.pt-0,
.py-0 {
    padding-top: 0 !important;
}
.pr-0,
.px-0 {
    padding-right: 0 !important;
}
.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
    padding-left: 0 !important;
}
.p-1 {
    padding: 0.25rem !important;
}
.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}
.p-2 {
    padding: 0.5rem !important;
}
.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}
.p-3 {
    padding: 1rem !important;
}
.pt-3,
.py-3 {
    padding-top: 1rem !important;
}
.pr-3,
.px-3 {
    padding-right: 1rem !important;
}
.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
    padding-left: 1rem !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}
.p-5 {
    padding: 3rem !important;
}
.pt-5,
.py-5 {
    padding-top: 3rem !important;
}
.pr-5,
.px-5 {
    padding-right: 3rem !important;
}
.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
    padding-left: 3rem !important;
}
.m-auto {
    margin: auto !important;
}
.mt-auto,
.my-auto {
    margin-top: auto !important;
}
.mr-auto,
.mx-auto {
    margin-right: auto !important;
}
.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
    margin-left: auto !important;
}
@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}
.text-justify {
    text-align: justify !important;
}
.text-nowrap {
    white-space: nowrap !important;
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.text-center {
    text-align: center !important;
}
@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}
@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}
@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}
@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}
.text-lowercase {
    text-transform: lowercase !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}
.font-weight-light {
    font-weight: 300 !important;
}
.font-weight-normal {
    font-weight: 400 !important;
}
.font-weight-bold {
    font-weight: 500 !important;
}
.font-italic {
    font-style: italic !important;
}
.text-white {
    color: #fff !important;
}
.text-primary {
    color: #eec666 !important;
}
a.text-primary:focus,
a.text-primary:hover {
    color: #771e86 !important;
}
.text-secondary {
    color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
    color: #545b62 !important;
}
.text-success {
    color: #4caf50 !important;
}
a.text-success:focus,
a.text-success:hover {
    color: #3d8b40 !important;
}
.text-info {
    color: #00bcd4 !important;
}
a.text-info:focus,
a.text-info:hover {
    color: #008fa1 !important;
}
.text-warning {
    color: #ff9800 !important;
}
a.text-warning:focus,
a.text-warning:hover {
    color: #cc7a00 !important;
}
.text-danger {
    color: #f44336 !important;
    font-weight: 700;
    font-size: 15px !important;
}
a.text-danger:focus,
a.text-danger:hover {
    color: #ea1c0d !important;
}
.text-light {
    color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
    color: #dae0e5 !important;
}
a.text-dark:focus,
a.text-dark:hover {
    color: #1d2124 !important;
}
.bmd-help,
.text-muted {
    color: #6c757d !important;
}
.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}
a:focus {
    outline: 0;
}
button:focus {
    outline: 0;
}
.bmd-layout-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
}
.bmd-layout-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.bmd-layout-header {
    z-index: 3;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-shrink: 0;
    justify-content: flex-start;
    width: 100%;
    max-height: 1000px;
    transform: translateZ(0);
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-content {
    position: relative;
    z-index: 1;
    display: inline-block;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-spacer {
    flex-grow: 1;
}
.bmd-layout-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: transparent;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@supports (pointer-events: auto) {
    .bmd-layout-backdrop {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition-property: opacity;
        visibility: visible;
        pointer-events: none;
    }
}
.btn {
    position: relative;
    padding: 12px 30px;
    margin: 0.3125rem 1px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.428571;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    border-radius: 0.2rem;
    outline: 0;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: box-shadow, transform;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:active:focus.focus,
.btn:active:focus:focus,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}
.btn.btn-primary {
    color: #fff;
    background-color: #eec666;
    border-color: #eec666;
    box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary:hover {
    color: #fff;
    background-color: #9124a3;
    border-color: #701c7e;
}
.btn.btn-primary.focus,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
    color: #fff;
    background-color: #9124a3;
    border-color: #701c7e;
}
.btn.btn-primary.active,
.btn.btn-primary:active,
.open > .btn.btn-primary.dropdown-toggle,
.show > .btn.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #9124a3;
    border-color: #701c7e;
    box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary.active.focus,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.btn.btn-primary:active.focus,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover,
.open > .btn.btn-primary.dropdown-toggle.focus,
.open > .btn.btn-primary.dropdown-toggle:focus,
.open > .btn.btn-primary.dropdown-toggle:hover,
.show > .btn.btn-primary.dropdown-toggle.focus,
.show > .btn.btn-primary.dropdown-toggle:focus,
.show > .btn.btn-primary.dropdown-toggle:hover {
    color: #fff;
    background-color: #9124a3;
    border-color: #3f1048;
}
.open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #eec666;
}
.open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #9124a3;
}
.btn.btn-primary.disabled.focus,
.btn.btn-primary.disabled:focus,
.btn.btn-primary:disabled.focus,
.btn.btn-primary:disabled:focus {
    background-color: #eec666;
    border-color: #eec666;
}
.btn.btn-primary.disabled:hover,
.btn.btn-primary:disabled:hover {
    background-color: #eec666;
    border-color: #eec666;
}
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
    box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.btn.btn-primary.btn-link {
    background-color: transparent;
    color: #eec666;
    box-shadow: none;
}
.btn.btn-primary.btn-link:active,
.btn.btn-primary.btn-link:focus,
.btn.btn-primary.btn-link:hover {
    background-color: transparent;
    color: #eec666;
}
.btn.btn-secondary {
    color: rgba(0, 0, 0, 0.87);
    background-color: #fafafa;
    border-color: #ccc;
    box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #f2f2f2;
    border-color: #adadad;
}
.btn.btn-secondary.focus,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #f2f2f2;
    border-color: #adadad;
}
.btn.btn-secondary.active,
.btn.btn-secondary:active,
.open > .btn.btn-secondary.dropdown-toggle,
.show > .btn.btn-secondary.dropdown-toggle {
    color: rgba(0, 0, 0, 0.87);
    background-color: #f2f2f2;
    border-color: #adadad;
    box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary.active.focus,
.btn.btn-secondary.active:focus,
.btn.btn-secondary.active:hover,
.btn.btn-secondary:active.focus,
.btn.btn-secondary:active:focus,
.btn.btn-secondary:active:hover,
.open > .btn.btn-secondary.dropdown-toggle.focus,
.open > .btn.btn-secondary.dropdown-toggle:focus,
.open > .btn.btn-secondary.dropdown-toggle:hover,
.show > .btn.btn-secondary.dropdown-toggle.focus,
.show > .btn.btn-secondary.dropdown-toggle:focus,
.show > .btn.btn-secondary.dropdown-toggle:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #f2f2f2;
    border-color: #8c8c8c;
}
.open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #fafafa;
}
.open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #f2f2f2;
}
.btn.btn-secondary.disabled.focus,
.btn.btn-secondary.disabled:focus,
.btn.btn-secondary:disabled.focus,
.btn.btn-secondary:disabled:focus {
    background-color: #fafafa;
    border-color: #ccc;
}
.btn.btn-secondary.disabled:hover,
.btn.btn-secondary:disabled:hover {
    background-color: #fafafa;
    border-color: #ccc;
}
.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover {
    box-shadow: 0 14px 26px -12px rgba(250, 250, 250, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(250, 250, 250, 0.2);
}
.btn.btn-secondary.btn-link {
    background-color: transparent;
    color: #fafafa;
    box-shadow: none;
}
.btn.btn-secondary.btn-link:active,
.btn.btn-secondary.btn-link:focus,
.btn.btn-secondary.btn-link:hover {
    background-color: transparent;
    color: #fafafa;
}
.btn.btn-info {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4;
    box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info:hover {
    color: #fff;
    background-color: #00aec5;
    border-color: #008697;
}
.btn.btn-info.focus,
.btn.btn-info:focus,
.btn.btn-info:hover {
    color: #fff;
    background-color: #00aec5;
    border-color: #008697;
}
.btn.btn-info.active,
.btn.btn-info:active,
.open > .btn.btn-info.dropdown-toggle,
.show > .btn.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00aec5;
    border-color: #008697;
    box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info.active.focus,
.btn.btn-info.active:focus,
.btn.btn-info.active:hover,
.btn.btn-info:active.focus,
.btn.btn-info:active:focus,
.btn.btn-info:active:hover,
.open > .btn.btn-info.dropdown-toggle.focus,
.open > .btn.btn-info.dropdown-toggle:focus,
.open > .btn.btn-info.dropdown-toggle:hover,
.show > .btn.btn-info.dropdown-toggle.focus,
.show > .btn.btn-info.dropdown-toggle:focus,
.show > .btn.btn-info.dropdown-toggle:hover {
    color: #fff;
    background-color: #00aec5;
    border-color: #004b55;
}
.open > .btn.btn-info.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #00bcd4;
}
.open > .btn.btn-info.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #00aec5;
}
.btn.btn-info.disabled.focus,
.btn.btn-info.disabled:focus,
.btn.btn-info:disabled.focus,
.btn.btn-info:disabled:focus {
    background-color: #00bcd4;
    border-color: #00bcd4;
}
.btn.btn-info.disabled:hover,
.btn.btn-info:disabled:hover {
    background-color: #00bcd4;
    border-color: #00bcd4;
}
.btn.btn-info:active,
.btn.btn-info:focus,
.btn.btn-info:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.btn.btn-info.btn-link {
    background-color: transparent;
    color: #00bcd4;
    box-shadow: none;
}
.btn.btn-info.btn-link:active,
.btn.btn-info.btn-link:focus,
.btn.btn-info.btn-link:hover {
    background-color: transparent;
    color: #00bcd4;
}
.btn.btn-success {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success:hover {
    color: #fff;
    background-color: #47a44b;
    border-color: #39843c;
}
.btn.btn-success.focus,
.btn.btn-success:focus,
.btn.btn-success:hover {
    color: #fff;
    background-color: #47a44b;
    border-color: #39843c;
}
.btn.btn-success.active,
.btn.btn-success:active,
.open > .btn.btn-success.dropdown-toggle,
.show > .btn.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #47a44b;
    border-color: #39843c;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success.active.focus,
.btn.btn-success.active:focus,
.btn.btn-success.active:hover,
.btn.btn-success:active.focus,
.btn.btn-success:active:focus,
.btn.btn-success:active:hover,
.open > .btn.btn-success.dropdown-toggle.focus,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle:hover,
.show > .btn.btn-success.dropdown-toggle.focus,
.show > .btn.btn-success.dropdown-toggle:focus,
.show > .btn.btn-success.dropdown-toggle:hover {
    color: #fff;
    background-color: #47a44b;
    border-color: #255627;
}
.open > .btn.btn-success.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #4caf50;
}
.open > .btn.btn-success.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #47a44b;
}
.btn.btn-success.disabled.focus,
.btn.btn-success.disabled:focus,
.btn.btn-success:disabled.focus,
.btn.btn-success:disabled:focus {
    background-color: #4caf50;
    border-color: #4caf50;
}
.btn.btn-success.disabled:hover,
.btn.btn-success:disabled:hover {
    background-color: #4caf50;
    border-color: #4caf50;
}
.btn.btn-success:active,
.btn.btn-success:focus,
.btn.btn-success:hover {
    box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}
.btn.btn-success.btn-link {
    background-color: transparent;
    color: #4caf50;
    box-shadow: none;
}
.btn.btn-success.btn-link:active,
.btn.btn-success.btn-link:focus,
.btn.btn-success.btn-link:hover {
    background-color: transparent;
    color: #4caf50;
}
.btn.btn-warning {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800;
    box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning:hover {
    color: #fff;
    background-color: #f08f00;
    border-color: #c27400;
}
.btn.btn-warning.focus,
.btn.btn-warning:focus,
.btn.btn-warning:hover {
    color: #fff;
    background-color: #f08f00;
    border-color: #c27400;
}
.btn.btn-warning.active,
.btn.btn-warning:active,
.open > .btn.btn-warning.dropdown-toggle,
.show > .btn.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f08f00;
    border-color: #c27400;
    box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning.active.focus,
.btn.btn-warning.active:focus,
.btn.btn-warning.active:hover,
.btn.btn-warning:active.focus,
.btn.btn-warning:active:focus,
.btn.btn-warning:active:hover,
.open > .btn.btn-warning.dropdown-toggle.focus,
.open > .btn.btn-warning.dropdown-toggle:focus,
.open > .btn.btn-warning.dropdown-toggle:hover,
.show > .btn.btn-warning.dropdown-toggle.focus,
.show > .btn.btn-warning.dropdown-toggle:focus,
.show > .btn.btn-warning.dropdown-toggle:hover {
    color: #fff;
    background-color: #f08f00;
    border-color: #804c00;
}
.open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #ff9800;
}
.open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #f08f00;
}
.btn.btn-warning.disabled.focus,
.btn.btn-warning.disabled:focus,
.btn.btn-warning:disabled.focus,
.btn.btn-warning:disabled:focus {
    background-color: #ff9800;
    border-color: #ff9800;
}
.btn.btn-warning.disabled:hover,
.btn.btn-warning:disabled:hover {
    background-color: #ff9800;
    border-color: #ff9800;
}
.btn.btn-warning:active,
.btn.btn-warning:focus,
.btn.btn-warning:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}
.btn.btn-warning.btn-link {
    background-color: transparent;
    color: #ff9800;
    box-shadow: none;
}
.btn.btn-warning.btn-link:active,
.btn.btn-warning.btn-link:focus,
.btn.btn-warning.btn-link:hover {
    background-color: transparent;
    color: #ff9800;
}
.btn.btn-danger {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger:hover {
    color: #fff;
    background-color: #f33527;
    border-color: #e11b0c;
}
.btn.btn-danger.focus,
.btn.btn-danger:focus,
.btn.btn-danger:hover {
    color: #fff;
    background-color: #f33527;
    border-color: #e11b0c;
}
.btn.btn-danger.active,
.btn.btn-danger:active,
.open > .btn.btn-danger.dropdown-toggle,
.show > .btn.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f33527;
    border-color: #e11b0c;
    box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger.active.focus,
.btn.btn-danger.active:focus,
.btn.btn-danger.active:hover,
.btn.btn-danger:active.focus,
.btn.btn-danger:active:focus,
.btn.btn-danger:active:hover,
.open > .btn.btn-danger.dropdown-toggle.focus,
.open > .btn.btn-danger.dropdown-toggle:focus,
.open > .btn.btn-danger.dropdown-toggle:hover,
.show > .btn.btn-danger.dropdown-toggle.focus,
.show > .btn.btn-danger.dropdown-toggle:focus,
.show > .btn.btn-danger.dropdown-toggle:hover {
    color: #fff;
    background-color: #f33527;
    border-color: #a21309;
}
.open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #f44336;
}
.open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #f33527;
}
.btn.btn-danger.disabled.focus,
.btn.btn-danger.disabled:focus,
.btn.btn-danger:disabled.focus,
.btn.btn-danger:disabled:focus {
    background-color: #f44336;
    border-color: #f44336;
}
.btn.btn-danger.disabled:hover,
.btn.btn-danger:disabled:hover {
    background-color: #f44336;
    border-color: #f44336;
}
.btn.btn-danger:active,
.btn.btn-danger:focus,
.btn.btn-danger:hover {
    box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}
.btn.btn-danger.btn-link {
    background-color: transparent;
    color: #f44336;
    box-shadow: none;
}
.btn.btn-danger.btn-link:active,
.btn.btn-danger.btn-link:focus,
.btn.btn-danger.btn-link:hover {
    background-color: transparent;
    color: #f44336;
}
.btn.btn-rose {
    color: #fff;
    background-color: #e91e63;
    border-color: #e91e63;
    box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose:hover {
    color: #fff;
    background-color: #ea2c6d;
    border-color: #b8124a;
}
.btn.btn-rose.focus,
.btn.btn-rose:focus,
.btn.btn-rose:hover {
    color: #fff;
    background-color: #ea2c6d;
    border-color: #b8124a;
}
.btn.btn-rose.active,
.btn.btn-rose:active,
.open > .btn.btn-rose.dropdown-toggle,
.show > .btn.btn-rose.dropdown-toggle {
    color: #fff;
    background-color: #ea2c6d;
    border-color: #b8124a;
    box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose.active.focus,
.btn.btn-rose.active:focus,
.btn.btn-rose.active:hover,
.btn.btn-rose:active.focus,
.btn.btn-rose:active:focus,
.btn.btn-rose:active:hover,
.open > .btn.btn-rose.dropdown-toggle.focus,
.open > .btn.btn-rose.dropdown-toggle:focus,
.open > .btn.btn-rose.dropdown-toggle:hover,
.show > .btn.btn-rose.dropdown-toggle.focus,
.show > .btn.btn-rose.dropdown-toggle:focus,
.show > .btn.btn-rose.dropdown-toggle:hover {
    color: #fff;
    background-color: #ea2c6d;
    border-color: #7b0c32;
}
.open > .btn.btn-rose.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #e91e63;
}
.open > .btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #ea2c6d;
}
.btn.btn-rose.disabled.focus,
.btn.btn-rose.disabled:focus,
.btn.btn-rose:disabled.focus,
.btn.btn-rose:disabled:focus {
    background-color: #e91e63;
    border-color: #e91e63;
}
.btn.btn-rose.disabled:hover,
.btn.btn-rose:disabled:hover {
    background-color: #e91e63;
    border-color: #e91e63;
}
.btn.btn-rose:active,
.btn.btn-rose:focus,
.btn.btn-rose:hover {
    box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2);
}
.btn.btn-rose.btn-link {
    background-color: transparent;
    color: #e91e63;
    box-shadow: none;
}
.btn.btn-rose.btn-link:active,
.btn.btn-rose.btn-link:focus,
.btn.btn-rose.btn-link:hover {
    background-color: transparent;
    color: #e91e63;
}
.btn,
.btn.btn-default {
    color: #fff;
    background-color: #999;
    border-color: #999;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn.btn-default:hover,
.btn:hover {
    color: #fff;
    background-color: #919191;
    border-color: #7a7a7a;
}
.btn.btn-default.focus,
.btn.btn-default:focus,
.btn.btn-default:hover,
.btn.focus,
.btn:focus,
.btn:hover {
    color: #fff;
    background-color: #919191;
    border-color: #7a7a7a;
}
.btn.active,
.btn.btn-default.active,
.btn.btn-default:active,
.btn:active,
.open > .btn.btn-default.dropdown-toggle,
.open > .btn.dropdown-toggle,
.show > .btn.btn-default.dropdown-toggle,
.show > .btn.dropdown-toggle {
    color: #fff;
    background-color: #919191;
    border-color: #7a7a7a;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn.btn-default.active.focus,
.btn.btn-default.active:focus,
.btn.btn-default.active:hover,
.btn.btn-default:active.focus,
.btn.btn-default:active:focus,
.btn.btn-default:active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover,
.open > .btn.btn-default.dropdown-toggle.focus,
.open > .btn.btn-default.dropdown-toggle:focus,
.open > .btn.btn-default.dropdown-toggle:hover,
.open > .btn.dropdown-toggle.focus,
.open > .btn.dropdown-toggle:focus,
.open > .btn.dropdown-toggle:hover,
.show > .btn.btn-default.dropdown-toggle.focus,
.show > .btn.btn-default.dropdown-toggle:focus,
.show > .btn.btn-default.dropdown-toggle:hover,
.show > .btn.dropdown-toggle.focus,
.show > .btn.dropdown-toggle:focus,
.show > .btn.dropdown-toggle:hover {
    color: #fff;
    background-color: #919191;
    border-color: #595959;
}
.open > .btn.btn-default.dropdown-toggle.bmd-btn-icon,
.open > .btn.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #999;
}
.open > .btn.btn-default.dropdown-toggle.bmd-btn-icon:hover,
.open > .btn.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #919191;
}
.btn.btn-default.disabled.focus,
.btn.btn-default.disabled:focus,
.btn.btn-default:disabled.focus,
.btn.btn-default:disabled:focus,
.btn.disabled.focus,
.btn.disabled:focus,
.btn:disabled.focus,
.btn:disabled:focus {
    background-color: #999;
    border-color: #999;
}
.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover,
.btn.disabled:hover,
.btn:disabled:hover {
    background-color: #999;
    border-color: #999;
}
.btn.btn-default:active,
.btn.btn-default:focus,
.btn.btn-default:hover,
.btn:active,
.btn:focus,
.btn:hover {
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.btn.btn-default.btn-link,
.btn.btn-link {
    background-color: transparent;
    color: #999;
    box-shadow: none;
}
.btn.btn-default.btn-link:active,
.btn.btn-default.btn-link:focus,
.btn.btn-default.btn-link:hover,
.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover {
    background-color: transparent;
    color: #999;
}
.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover {
    background-color: #fff;
    color: #999;
}
.btn.btn-white.btn-link {
    color: #fff;
    background: 0 0;
    box-shadow: none;
}
.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover {
    text-decoration: none !important;
}
.btn.btn-disabled {
    cursor: not-allowed;
    pointer-events: none;
}
.btn.btn-disabled:hover {
    box-shadow: none;
}
.btn-group-raised .btn.btn-link,
.btn.btn-raised.btn-link {
    box-shadow: none;
}
.btn-group-raised .btn.btn-link.active,
.btn.btn-raised.btn-link.active {
    box-shadow: none;
}
.btn-group-raised .btn.btn-link:active,
.btn-group-raised .btn.btn-link:focus,
.btn-group-raised .btn.btn-link:hover,
.btn.btn-raised.btn-link:active,
.btn.btn-raised.btn-link:focus,
.btn.btn-raised.btn-link:hover {
    box-shadow: none;
}
.btn-group-raised .btn.disabled,
.btn-group-raised .btn:disabled,
.btn-group-raised .btn[disabled],
.btn.btn-raised.disabled,
.btn.btn-raised:disabled,
.btn.btn-raised[disabled],
fieldset[disabled][disabled] .btn-group-raised .btn,
fieldset[disabled][disabled] .btn.btn-raised {
    box-shadow: none;
}
.btn.btn-outline,
.btn.btn-outline-danger,
.btn.btn-outline-info,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-outline-success,
.btn.btn-outline-warning {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
}
.btn.btn-outline {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.focus,
.btn.btn-outline:focus,
.btn.btn-outline:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.active,
.btn.btn-outline:active,
.open > .btn.btn-outline.dropdown-toggle,
.show > .btn.btn-outline.dropdown-toggle {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline.active.focus,
.btn.btn-outline.active:focus,
.btn.btn-outline.active:hover,
.btn.btn-outline:active.focus,
.btn.btn-outline:active:focus,
.btn.btn-outline:active:hover,
.open > .btn.btn-outline.dropdown-toggle.focus,
.open > .btn.btn-outline.dropdown-toggle:focus,
.open > .btn.btn-outline.dropdown-toggle:hover,
.show > .btn.btn-outline.dropdown-toggle.focus,
.show > .btn.btn-outline.dropdown-toggle:focus,
.show > .btn.btn-outline.dropdown-toggle:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.4);
    border-color: rgba(0, 0, 0, 0.87);
}
.open > .btn.btn-outline.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline.disabled.focus,
.btn.btn-outline.disabled:focus,
.btn.btn-outline:disabled.focus,
.btn.btn-outline:disabled:focus {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.disabled:hover,
.btn.btn-outline:disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline.focus,
.bg-inverse .btn.btn-outline:focus,
.bg-inverse .btn.btn-outline:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline.active,
.bg-inverse .btn.btn-outline:active,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline.active.focus,
.bg-inverse .btn.btn-outline.active:focus,
.bg-inverse .btn.btn-outline.active:hover,
.bg-inverse .btn.btn-outline:active.focus,
.bg-inverse .btn.btn-outline:active:focus,
.bg-inverse .btn.btn-outline:active:hover,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.25);
    border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline.disabled.focus,
.bg-inverse .btn.btn-outline.disabled:focus,
.bg-inverse .btn.btn-outline:disabled.focus,
.bg-inverse .btn.btn-outline:disabled:focus {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline.disabled:hover,
.bg-inverse .btn.btn-outline:disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.btn-link {
    background-color: transparent;
}
.btn.btn-outline-primary {
    color: #eec666;
    background-color: transparent;
    border-color: #eec666;
}
.btn.btn-outline-primary:hover {
    color: #eec666;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #eec666;
}
.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:hover {
    color: #eec666;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #eec666;
}
.btn.btn-outline-primary.active,
.btn.btn-outline-primary:active,
.open > .btn.btn-outline-primary.dropdown-toggle,
.show > .btn.btn-outline-primary.dropdown-toggle {
    color: #eec666;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #eec666;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-primary.active.focus,
.btn.btn-outline-primary.active:focus,
.btn.btn-outline-primary.active:hover,
.btn.btn-outline-primary:active.focus,
.btn.btn-outline-primary:active:focus,
.btn.btn-outline-primary:active:hover,
.open > .btn.btn-outline-primary.dropdown-toggle.focus,
.open > .btn.btn-outline-primary.dropdown-toggle:focus,
.open > .btn.btn-outline-primary.dropdown-toggle:hover,
.show > .btn.btn-outline-primary.dropdown-toggle.focus,
.show > .btn.btn-outline-primary.dropdown-toggle:focus,
.show > .btn.btn-outline-primary.dropdown-toggle:hover {
    color: #eec666;
    background-color: rgba(153, 153, 153, 0.4);
    border-color: #eec666;
}
.open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-primary.disabled.focus,
.btn.btn-outline-primary.disabled:focus,
.btn.btn-outline-primary:disabled.focus,
.btn.btn-outline-primary:disabled:focus {
    background-color: transparent;
    border-color: #eec666;
}
.btn.btn-outline-primary.disabled:hover,
.btn.btn-outline-primary:disabled:hover {
    background-color: transparent;
    border-color: #eec666;
}
.bg-inverse .btn.btn-outline-primary {
    color: #eec666;
    background-color: transparent;
    border-color: #eec666;
}
.bg-inverse .btn.btn-outline-primary:hover {
    color: #eec666;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary.focus,
.bg-inverse .btn.btn-outline-primary:focus,
.bg-inverse .btn.btn-outline-primary:hover {
    color: #eec666;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary.active,
.bg-inverse .btn.btn-outline-primary:active,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle {
    color: #eec666;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-primary.active.focus,
.bg-inverse .btn.btn-outline-primary.active:focus,
.bg-inverse .btn.btn-outline-primary.active:hover,
.bg-inverse .btn.btn-outline-primary:active.focus,
.bg-inverse .btn.btn-outline-primary:active:focus,
.bg-inverse .btn.btn-outline-primary:active:hover,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover {
    color: #eec666;
    background-color: rgba(204, 204, 204, 0.25);
    border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary.disabled.focus,
.bg-inverse .btn.btn-outline-primary.disabled:focus,
.bg-inverse .btn.btn-outline-primary:disabled.focus,
.bg-inverse .btn.btn-outline-primary:disabled:focus {
    background-color: transparent;
    border-color: #eec666;
}
.bg-inverse .btn.btn-outline-primary.disabled:hover,
.bg-inverse .btn.btn-outline-primary:disabled:hover {
    background-color: transparent;
    border-color: #eec666;
}
.btn.btn-outline-primary.btn-link {
    background-color: transparent;
}
.btn.btn-outline-secondary {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.active,
.btn.btn-outline-secondary:active,
.open > .btn.btn-outline-secondary.dropdown-toggle,
.show > .btn.btn-outline-secondary.dropdown-toggle {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-secondary.active.focus,
.btn.btn-outline-secondary.active:focus,
.btn.btn-outline-secondary.active:hover,
.btn.btn-outline-secondary:active.focus,
.btn.btn-outline-secondary:active:focus,
.btn.btn-outline-secondary:active:hover,
.open > .btn.btn-outline-secondary.dropdown-toggle.focus,
.open > .btn.btn-outline-secondary.dropdown-toggle:focus,
.open > .btn.btn-outline-secondary.dropdown-toggle:hover,
.show > .btn.btn-outline-secondary.dropdown-toggle.focus,
.show > .btn.btn-outline-secondary.dropdown-toggle:focus,
.show > .btn.btn-outline-secondary.dropdown-toggle:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.4);
    border-color: rgba(0, 0, 0, 0.87);
}
.open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-secondary.disabled.focus,
.btn.btn-outline-secondary.disabled:focus,
.btn.btn-outline-secondary:disabled.focus,
.btn.btn-outline-secondary:disabled:focus {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.disabled:hover,
.btn.btn-outline-secondary:disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary.focus,
.bg-inverse .btn.btn-outline-secondary:focus,
.bg-inverse .btn.btn-outline-secondary:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary.active,
.bg-inverse .btn.btn-outline-secondary:active,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-secondary.active.focus,
.bg-inverse .btn.btn-outline-secondary.active:focus,
.bg-inverse .btn.btn-outline-secondary.active:hover,
.bg-inverse .btn.btn-outline-secondary:active.focus,
.bg-inverse .btn.btn-outline-secondary:active:focus,
.bg-inverse .btn.btn-outline-secondary:active:hover,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(204, 204, 204, 0.25);
    border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary.disabled.focus,
.bg-inverse .btn.btn-outline-secondary.disabled:focus,
.bg-inverse .btn.btn-outline-secondary:disabled.focus,
.bg-inverse .btn.btn-outline-secondary:disabled:focus {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary.disabled:hover,
.bg-inverse .btn.btn-outline-secondary:disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.btn-link {
    background-color: transparent;
}
.btn.btn-outline-info {
    color: #00bcd4;
    background-color: transparent;
    border-color: #00bcd4;
}
.btn.btn-outline-info:hover {
    color: #00bcd4;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #00bcd4;
}
.btn.btn-outline-info.focus,
.btn.btn-outline-info:focus,
.btn.btn-outline-info:hover {
    color: #00bcd4;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #00bcd4;
}
.btn.btn-outline-info.active,
.btn.btn-outline-info:active,
.open > .btn.btn-outline-info.dropdown-toggle,
.show > .btn.btn-outline-info.dropdown-toggle {
    color: #00bcd4;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #00bcd4;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-info.active.focus,
.btn.btn-outline-info.active:focus,
.btn.btn-outline-info.active:hover,
.btn.btn-outline-info:active.focus,
.btn.btn-outline-info:active:focus,
.btn.btn-outline-info:active:hover,
.open > .btn.btn-outline-info.dropdown-toggle.focus,
.open > .btn.btn-outline-info.dropdown-toggle:focus,
.open > .btn.btn-outline-info.dropdown-toggle:hover,
.show > .btn.btn-outline-info.dropdown-toggle.focus,
.show > .btn.btn-outline-info.dropdown-toggle:focus,
.show > .btn.btn-outline-info.dropdown-toggle:hover {
    color: #00bcd4;
    background-color: rgba(153, 153, 153, 0.4);
    border-color: #00bcd4;
}
.open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-info.disabled.focus,
.btn.btn-outline-info.disabled:focus,
.btn.btn-outline-info:disabled.focus,
.btn.btn-outline-info:disabled:focus {
    background-color: transparent;
    border-color: #00bcd4;
}
.btn.btn-outline-info.disabled:hover,
.btn.btn-outline-info:disabled:hover {
    background-color: transparent;
    border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info {
    color: #00bcd4;
    background-color: transparent;
    border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info:hover {
    color: #00bcd4;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.focus,
.bg-inverse .btn.btn-outline-info:focus,
.bg-inverse .btn.btn-outline-info:hover {
    color: #00bcd4;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.active,
.bg-inverse .btn.btn-outline-info:active,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle {
    color: #00bcd4;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-info.active.focus,
.bg-inverse .btn.btn-outline-info.active:focus,
.bg-inverse .btn.btn-outline-info.active:hover,
.bg-inverse .btn.btn-outline-info:active.focus,
.bg-inverse .btn.btn-outline-info:active:focus,
.bg-inverse .btn.btn-outline-info:active:hover,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle:hover {
    color: #00bcd4;
    background-color: rgba(204, 204, 204, 0.25);
    border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.disabled.focus,
.bg-inverse .btn.btn-outline-info.disabled:focus,
.bg-inverse .btn.btn-outline-info:disabled.focus,
.bg-inverse .btn.btn-outline-info:disabled:focus {
    background-color: transparent;
    border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info.disabled:hover,
.bg-inverse .btn.btn-outline-info:disabled:hover {
    background-color: transparent;
    border-color: #00bcd4;
}
.btn.btn-outline-info.btn-link {
    background-color: transparent;
}
.btn.btn-outline-success {
    color: #4caf50;
    background-color: transparent;
    border-color: #4caf50;
}
.btn.btn-outline-success:hover {
    color: #4caf50;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #4caf50;
}
.btn.btn-outline-success.focus,
.btn.btn-outline-success:focus,
.btn.btn-outline-success:hover {
    color: #4caf50;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #4caf50;
}
.btn.btn-outline-success.active,
.btn.btn-outline-success:active,
.open > .btn.btn-outline-success.dropdown-toggle,
.show > .btn.btn-outline-success.dropdown-toggle {
    color: #4caf50;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #4caf50;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-success.active.focus,
.btn.btn-outline-success.active:focus,
.btn.btn-outline-success.active:hover,
.btn.btn-outline-success:active.focus,
.btn.btn-outline-success:active:focus,
.btn.btn-outline-success:active:hover,
.open > .btn.btn-outline-success.dropdown-toggle.focus,
.open > .btn.btn-outline-success.dropdown-toggle:focus,
.open > .btn.btn-outline-success.dropdown-toggle:hover,
.show > .btn.btn-outline-success.dropdown-toggle.focus,
.show > .btn.btn-outline-success.dropdown-toggle:focus,
.show > .btn.btn-outline-success.dropdown-toggle:hover {
    color: #4caf50;
    background-color: rgba(153, 153, 153, 0.4);
    border-color: #4caf50;
}
.open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-success.disabled.focus,
.btn.btn-outline-success.disabled:focus,
.btn.btn-outline-success:disabled.focus,
.btn.btn-outline-success:disabled:focus {
    background-color: transparent;
    border-color: #4caf50;
}
.btn.btn-outline-success.disabled:hover,
.btn.btn-outline-success:disabled:hover {
    background-color: transparent;
    border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success {
    color: #4caf50;
    background-color: transparent;
    border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success:hover {
    color: #4caf50;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success.focus,
.bg-inverse .btn.btn-outline-success:focus,
.bg-inverse .btn.btn-outline-success:hover {
    color: #4caf50;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success.active,
.bg-inverse .btn.btn-outline-success:active,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle {
    color: #4caf50;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-success.active.focus,
.bg-inverse .btn.btn-outline-success.active:focus,
.bg-inverse .btn.btn-outline-success.active:hover,
.bg-inverse .btn.btn-outline-success:active.focus,
.bg-inverse .btn.btn-outline-success:active:focus,
.bg-inverse .btn.btn-outline-success:active:hover,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle:hover {
    color: #4caf50;
    background-color: rgba(204, 204, 204, 0.25);
    border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success.disabled.focus,
.bg-inverse .btn.btn-outline-success.disabled:focus,
.bg-inverse .btn.btn-outline-success:disabled.focus,
.bg-inverse .btn.btn-outline-success:disabled:focus {
    background-color: transparent;
    border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success.disabled:hover,
.bg-inverse .btn.btn-outline-success:disabled:hover {
    background-color: transparent;
    border-color: #4caf50;
}
.btn.btn-outline-success.btn-link {
    background-color: transparent;
}
.btn.btn-outline-warning {
    color: #ff9800;
    background-color: transparent;
    border-color: #ff9800;
}
.btn.btn-outline-warning:hover {
    color: #ff9800;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #ff9800;
}
.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning:hover {
    color: #ff9800;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #ff9800;
}
.btn.btn-outline-warning.active,
.btn.btn-outline-warning:active,
.open > .btn.btn-outline-warning.dropdown-toggle,
.show > .btn.btn-outline-warning.dropdown-toggle {
    color: #ff9800;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #ff9800;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-warning.active.focus,
.btn.btn-outline-warning.active:focus,
.btn.btn-outline-warning.active:hover,
.btn.btn-outline-warning:active.focus,
.btn.btn-outline-warning:active:focus,
.btn.btn-outline-warning:active:hover,
.open > .btn.btn-outline-warning.dropdown-toggle.focus,
.open > .btn.btn-outline-warning.dropdown-toggle:focus,
.open > .btn.btn-outline-warning.dropdown-toggle:hover,
.show > .btn.btn-outline-warning.dropdown-toggle.focus,
.show > .btn.btn-outline-warning.dropdown-toggle:focus,
.show > .btn.btn-outline-warning.dropdown-toggle:hover {
    color: #ff9800;
    background-color: rgba(153, 153, 153, 0.4);
    border-color: #ff9800;
}
.open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-warning.disabled.focus,
.btn.btn-outline-warning.disabled:focus,
.btn.btn-outline-warning:disabled.focus,
.btn.btn-outline-warning:disabled:focus {
    background-color: transparent;
    border-color: #ff9800;
}
.btn.btn-outline-warning.disabled:hover,
.btn.btn-outline-warning:disabled:hover {
    background-color: transparent;
    border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning {
    color: #ff9800;
    background-color: transparent;
    border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning:hover {
    color: #ff9800;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning.focus,
.bg-inverse .btn.btn-outline-warning:focus,
.bg-inverse .btn.btn-outline-warning:hover {
    color: #ff9800;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning.active,
.bg-inverse .btn.btn-outline-warning:active,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle {
    color: #ff9800;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-warning.active.focus,
.bg-inverse .btn.btn-outline-warning.active:focus,
.bg-inverse .btn.btn-outline-warning.active:hover,
.bg-inverse .btn.btn-outline-warning:active.focus,
.bg-inverse .btn.btn-outline-warning:active:focus,
.bg-inverse .btn.btn-outline-warning:active:hover,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover {
    color: #ff9800;
    background-color: rgba(204, 204, 204, 0.25);
    border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning.disabled.focus,
.bg-inverse .btn.btn-outline-warning.disabled:focus,
.bg-inverse .btn.btn-outline-warning:disabled.focus,
.bg-inverse .btn.btn-outline-warning:disabled:focus {
    background-color: transparent;
    border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning.disabled:hover,
.bg-inverse .btn.btn-outline-warning:disabled:hover {
    background-color: transparent;
    border-color: #ff9800;
}
.btn.btn-outline-warning.btn-link {
    background-color: transparent;
}
.btn.btn-outline-danger {
    color: #f44336;
    background-color: transparent;
    border-color: #f44336;
}
.btn.btn-outline-danger:hover {
    color: #f44336;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #f44336;
}
.btn.btn-outline-danger.focus,
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger:hover {
    color: #f44336;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #f44336;
}
.btn.btn-outline-danger.active,
.btn.btn-outline-danger:active,
.open > .btn.btn-outline-danger.dropdown-toggle,
.show > .btn.btn-outline-danger.dropdown-toggle {
    color: #f44336;
    background-color: rgba(153, 153, 153, 0.2);
    border-color: #f44336;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-danger.active.focus,
.btn.btn-outline-danger.active:focus,
.btn.btn-outline-danger.active:hover,
.btn.btn-outline-danger:active.focus,
.btn.btn-outline-danger:active:focus,
.btn.btn-outline-danger:active:hover,
.open > .btn.btn-outline-danger.dropdown-toggle.focus,
.open > .btn.btn-outline-danger.dropdown-toggle:focus,
.open > .btn.btn-outline-danger.dropdown-toggle:hover,
.show > .btn.btn-outline-danger.dropdown-toggle.focus,
.show > .btn.btn-outline-danger.dropdown-toggle:focus,
.show > .btn.btn-outline-danger.dropdown-toggle:hover {
    color: #f44336;
    background-color: rgba(153, 153, 153, 0.4);
    border-color: #f44336;
}
.open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-danger.disabled.focus,
.btn.btn-outline-danger.disabled:focus,
.btn.btn-outline-danger:disabled.focus,
.btn.btn-outline-danger:disabled:focus {
    background-color: transparent;
    border-color: #f44336;
}
.btn.btn-outline-danger.disabled:hover,
.btn.btn-outline-danger:disabled:hover {
    background-color: transparent;
    border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger {
    color: #f44336;
    background-color: transparent;
    border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger:hover {
    color: #f44336;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.focus,
.bg-inverse .btn.btn-outline-danger:focus,
.bg-inverse .btn.btn-outline-danger:hover {
    color: #f44336;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.active,
.bg-inverse .btn.btn-outline-danger:active,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle {
    color: #f44336;
    background-color: rgba(204, 204, 204, 0.15);
    border-color: rgba(204, 204, 204, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-danger.active.focus,
.bg-inverse .btn.btn-outline-danger.active:focus,
.bg-inverse .btn.btn-outline-danger.active:hover,
.bg-inverse .btn.btn-outline-danger:active.focus,
.bg-inverse .btn.btn-outline-danger:active:focus,
.bg-inverse .btn.btn-outline-danger:active:hover,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover {
    color: #f44336;
    background-color: rgba(204, 204, 204, 0.25);
    border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent;
}
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.disabled.focus,
.bg-inverse .btn.btn-outline-danger.disabled:focus,
.bg-inverse .btn.btn-outline-danger:disabled.focus,
.bg-inverse .btn.btn-outline-danger:disabled:focus {
    background-color: transparent;
    border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger.disabled:hover,
.bg-inverse .btn.btn-outline-danger:disabled:hover {
    background-color: transparent;
    border-color: #f44336;
}
.btn.btn-outline-danger.btn-link {
    background-color: transparent;
}
.btn-group-lg .btn,
.btn-group-lg > .btn,
.btn.btn-lg {
    padding: 1.125rem 2.25rem;
    font-size: 0.875rem;
    line-height: 1.333333;
    border-radius: 0.2rem;
}
.btn-group-sm .btn,
.btn-group-sm > .btn,
.btn.btn-sm {
    padding: 0.40625rem 1.25rem;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.btn.btn-round {
    border-radius: 30px;
}
.btn.btn-fab,
.btn.btn-just-icon {
    font-size: 24px;
    height: 41px;
    min-width: 41px;
    width: 41px;
    padding: 0;
    overflow: hidden;
    position: relative;
    line-height: 41px;
}
.btn.btn-fab.btn-round,
.btn.btn-just-icon.btn-round {
    border-radius: 50%;
}
.btn-group-sm .btn.btn-fab,
.btn-group-sm .btn.btn-just-icon,
.btn-group-sm > .btn.btn-fab,
.btn-group-sm > .btn.btn-just-icon,
.btn.btn-fab.btn-fab-mini,
.btn.btn-fab.btn-sm,
.btn.btn-just-icon.btn-fab-mini,
.btn.btn-just-icon.btn-sm {
    height: 30px;
    min-width: 30px;
    width: 30px;
}
.btn-group-sm .btn.btn-fab .fa,
.btn-group-sm .btn.btn-fab .material-icons,
.btn-group-sm .btn.btn-just-icon .fa,
.btn-group-sm .btn.btn-just-icon .material-icons,
.btn-group-sm > .btn.btn-fab .fa,
.btn-group-sm > .btn.btn-fab .material-icons,
.btn-group-sm > .btn.btn-just-icon .fa,
.btn-group-sm > .btn.btn-just-icon .material-icons,
.btn.btn-fab.btn-fab-mini .fa,
.btn.btn-fab.btn-fab-mini .material-icons,
.btn.btn-fab.btn-sm .fa,
.btn.btn-fab.btn-sm .material-icons,
.btn.btn-just-icon.btn-fab-mini .fa,
.btn.btn-just-icon.btn-fab-mini .material-icons,
.btn.btn-just-icon.btn-sm .fa,
.btn.btn-just-icon.btn-sm .material-icons {
    font-size: 17px;
    line-height: 29px;
}
.btn-group-lg .btn.btn-fab,
.btn-group-lg .btn.btn-just-icon,
.btn-group-lg > .btn.btn-fab,
.btn-group-lg > .btn.btn-just-icon,
.btn.btn-fab.btn-lg,
.btn.btn-just-icon.btn-lg {
    height: 57px;
    min-width: 57px;
    width: 57px;
    line-height: 56px;
}
.btn-group-lg .btn.btn-fab .fa,
.btn-group-lg .btn.btn-fab .material-icons,
.btn-group-lg .btn.btn-just-icon .fa,
.btn-group-lg .btn.btn-just-icon .material-icons,
.btn-group-lg > .btn.btn-fab .fa,
.btn-group-lg > .btn.btn-fab .material-icons,
.btn-group-lg > .btn.btn-just-icon .fa,
.btn-group-lg > .btn.btn-just-icon .material-icons,
.btn.btn-fab.btn-lg .fa,
.btn.btn-fab.btn-lg .material-icons,
.btn.btn-just-icon.btn-lg .fa,
.btn.btn-just-icon.btn-lg .material-icons {
    font-size: 32px;
    line-height: 56px;
}
.btn.btn-fab .fa,
.btn.btn-fab .material-icons,
.btn.btn-just-icon .fa,
.btn.btn-just-icon .material-icons {
    margin-top: 0;
    position: absolute;
    width: 100%;
    transform: none;
    left: 0;
    top: 0;
    height: 100%;
    line-height: 41px;
    font-size: 20px;
}
.btn-group-lg > .btn-just-icon.btn,
.btn-just-icon.btn-lg {
    font-size: 24px;
    height: 41px;
    min-width: 41px;
    width: 41px;
}
.input-group-btn > .btn {
    border: 0;
}
.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
    position: relative;
    display: inline-block;
    top: 0;
    margin-top: -1em;
    margin-bottom: -1em;
    font-size: 1.1rem;
    vertical-align: middle;
}
.bg-inverse .btn-group-vertical.disabled,
.bg-inverse .btn-group-vertical:disabled,
.bg-inverse .btn-group-vertical[disabled],
.bg-inverse .btn-group.disabled,
.bg-inverse .btn-group:disabled,
.bg-inverse .btn-group[disabled],
.bg-inverse .btn.disabled,
.bg-inverse .btn:disabled,
.bg-inverse .btn[disabled],
.bg-inverse .input-group-btn .btn.disabled,
.bg-inverse .input-group-btn .btn:disabled,
.bg-inverse .input-group-btn .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .btn,
.bg-inverse fieldset[disabled][disabled] .btn-group,
.bg-inverse fieldset[disabled][disabled] .btn-group-vertical,
.bg-inverse fieldset[disabled][disabled] .input-group-btn .btn {
    color: rgba(255, 255, 255, 0.3);
}
.btn-group,
.btn-group-vertical {
    position: relative;
    margin: 10px 1px;
}
.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
    border-radius: 0 0 0.25rem 0.25rem;
}
.btn-group-vertical.btn-group-raised,
.btn-group.btn-group-raised {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-group .btn,
.btn-group .btn + .btn,
.btn-group .btn-group,
.btn-group .btn:active,
.btn-group-vertical .btn,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn-group,
.btn-group-vertical .btn:active {
    margin: 0;
}
.btn-group-vertical > .btn-group,
.btn-group > .btn-group {
    margin: 0;
}
legend {
    border-bottom: 0;
}
.serif-font {
    font-family: 'Oxanium', cursive;
}
* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}
:focus {
    outline: 0;
}
a {
    color: #eec666;
}
a:focus,
a:hover {
    color: #eec666;
    text-decoration: underline;
}
a.text-info:focus,
a.text-info:hover {
    color: #00a5bb;
}
a .material-icons {
    vertical-align: middle;
}
.main {
    position: relative;
    z-index: 3;
}
.form-check,
label {
    font-size: 14px;
    line-height: 1.42857;
    color: #aaa;
    font-weight: 400;
}
.main-raised {
    margin: -60px 30px 0;
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.animation-transition-general,
.carousel .carousel-indicators li {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}
.animation-transition-slow {
    -webkit-transition: all 370ms linear;
    -moz-transition: all 370ms linear;
    -o-transition: all 370ms linear;
    -ms-transition: all 370ms linear;
    transition: all 370ms linear;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td span,
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table th > div {
    -webkit-transition: all 150ms ease 0s;
    -moz-transition: all 150ms ease 0s;
    -o-transition: all 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    transition: all 150ms ease 0s;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td span,
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table th > div,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.card-collapse .card-header a i,
.navbar {
    -webkit-transition: all 150ms ease 0s;
    -moz-transition: all 150ms ease 0s;
    -o-transition: all 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    transition: all 150ms ease 0s;
}
.signup-page .card-signup form .form-check {
    padding-left: 20px;
}
.signup-page .card-signup form .form-check .form-check-label {
    padding-left: 35px;
}
.section-signup .card-signup .card-header {
    width: auto;
}
.section-signup .card-signup .card-body .input-group {
    padding-bottom: 7px;
    margin: 27px 0 0 0;
}
.offline-doc .page-header,
.offline-free-doc .page-header {
    height: 100vh !important;
}
.offline-doc .page-header:after,
.offline-free-doc .page-header:after {
    background: rgba(0, 0, 0, 0.5) !important;
}
.offline-doc .footer,
.offline-free-doc .footer {
    position: absolute;
    width: 100%;
    background: 0 0;
    bottom: 0;
    color: #fff;
    z-index: 1;
}
.offline-doc .footer .copyright a,
.offline-free-doc .footer .copyright a {
    color: #fff;
}
.offline-doc .navbar .navbar-brand,
.offline-doc .navbar .navbar-collapse .nav-link,
.offline-free-doc .navbar .navbar-brand,
.offline-free-doc .navbar .navbar-collapse .nav-link {
    color: #fff;
}
@-webkit-keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}
@keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}
.hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge;
}
.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.tim-row {
    margin-bottom: 20px;
}
.tim-white-buttons {
    background-color: #777;
}
.title {
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
}
#map {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(100vh - 70px);
    margin-top: 70px;
}
.tim-typo {
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
    width: 100%;
}
.tim-typo .tim-note {
    bottom: 5px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
}
.tim-row {
    padding-top: 50px;
}
.tim-row h3 {
    margin-top: 0;
}
#typography h1,
#typography h2,
#typography h3,
#typography h4,
#typography h5,
#typography h6 {
    margin-bottom: 0;
}
.switch {
    margin-right: 20px;
}
#navbar-full .navbar {
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2;
}
.space {
    height: 130px;
    display: block;
}
.space-110 {
    height: 110px;
    display: block;
}
.space-50 {
    height: 50px;
    display: block;
}
.space-70 {
    height: 70px;
    display: block;
}
.navigation-example .img-src {
    background-attachment: scroll;
}
.navigation-example {
    background-position: center center;
    background-size: cover;
    margin-top: 0;
    min-height: 740px;
}
#notifications {
    background-color: #fff;
    display: block;
    width: 100%;
    position: relative;
}
#notifications .alert-danger {
    margin-bottom: 0;
}
.tim-note {
    text-transform: capitalize;
}
#buttons .btn {
    margin: 0 0 15px;
}
.space-100 {
    height: 100px;
    display: block;
    width: 100%;
}
.parallax {
    width: 100%;
    height: 570px;
    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;
}
.parallax .parallax-image img {
    width: 100%;
}
@media (max-width: 768px) {
    .parallax .parallax-image {
        width: 100%;
        height: 640px;
        overflow: hidden;
    }
    .parallax .parallax-image img {
        height: 100%;
        width: auto;
    }
}
.separator {
    content: "Separator";
    color: #fff;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line {
    background-color: #eee;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray {
    background-color: #eee;
}
.social-buttons-demo .btn {
    margin: 10px 5px 7px 1px;
}
.img-container {
    width: 100%;
    overflow: hidden;
}
.img-container img {
    width: 100%;
}
.section-black {
    background-color: #333;
}
.animate {
    transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -webkit-transition: 1.5s ease-in-out;
}
.sharing-area {
    margin-top: 80px;
}
.sharing-area .btn {
    margin: 15px 4px 0;
    color: #fff;
}
.sharing-area .btn i {
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 5px;
}
#navbar {
    margin-bottom: -20px;
}
.sharing-area .btn-twitter {
    background-color: #55acee;
}
.sharing-area .btn-facebook {
    background-color: #3b5998;
}
.sharing-area .btn-instagram {
    background-color: #e4405f;
}
.sharing-area .btn-github {
    background-color: #333;
}
#navbar .navbar {
    border-radius: 0;
}
@media (max-width: 830px) {
    .main-raised {
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media (max-width: 767px) {
    .tab-pane#schedule-2 {
        padding-top: 20px;
    }
}
.bd-docs .bd-example.bd-example-popover-static .popover,
.bd-docs .bd-example.bd-example-tooltip-static .tooltip {
    z-index: 3 !important;
}
@media all and (max-width: 375px) {
    .index-page {
        overflow-x: hidden;
    }
    .pagination.pagination-primary .page-item:nth-of-type(3),
    .pagination.pagination-primary .page-item:nth-of-type(7) {
        display: none;
    }
    .pagination.pagination-info .page-item:nth-of-type(2) {
        display: none;
    }
    .main.main-raised {
        margin-top: -30px;
    }
    .form-group.is-focused .bootstrap-datetimepicker-widget {
        left: -32px !important;
    }
}
.show.modal .dropdown-toggle:after {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
.bootstrap-datetimepicker-widget .timepicker .table-condesed .btn .ripple-container {
    width: 40px;
    height: 40px;
    margin: -11px 3px;
}
.bd-docs .bd-toc-item .bd-sidenav a span {
    float: right;
    margin-top: 5px;
    padding: 3px 7px;
    font-size: 8px;
    line-height: 9px;
    background-color: #eec666;
}
.bd-docs .bd-content .bd-title .btn {
    padding: 7px 20px;
    margin-bottom: 10px;
}
.form-check {
    margin-bottom: 0.5rem;
    padding-left: 0;
}
.form-check .form-check-label {
    cursor: pointer;
    padding-left: 0;
}
.form-group.is-focused .form-check .form-check-label {
    color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:focus,
.form-group.is-focused .form-check .form-check-label:hover {
    color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
    color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-input {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none;
}
.form-check .form-check-sign {
    vertical-align: middle;
    position: relative;
    top: -2px;
    float: left;
    padding-right: 10px;
    display: inline-block;
}
.form-check .form-check-sign:before {
    display: block;
    position: absolute;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.84);
    height: 20px;
    width: 20px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    top: 0;
    -webkit-transform: scale3d(2.3, 2.3, 1);
    -moz-transform: scale3d(2.3, 2.3, 1);
    -o-transform: scale3d(2.3, 2.3, 1);
    -ms-transform: scale3d(2.3, 2.3, 1);
    transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-sign .check {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.54);
    overflow: hidden;
    z-index: 1;
    border-radius: 3px;
}
.form-check .form-check-sign .check:before {
    position: absolute;
    content: "";
    transform: rotate(45deg);
    display: block;
    margin-top: -3px;
    margin-left: 7px;
    width: 0;
    color: #fff;
    height: 0;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    -webkit-animation: checkbox-off 0.3s forwards;
    -moz-animation: checkbox-off 0.3s forwards;
    -o-animation: checkbox-off 0.3s forwards;
    -ms-animation: checkbox-off 0.3s forwards;
    animation: checkbox-off 0.3s forwards;
}
.form-check .form-check-input:focus + .form-check-sign .check:after {
    opacity: 0.2;
}
.form-check .form-check-input:checked ~ .form-check-sign .check {
    background: #eec666;
}
.form-check .form-check-input:checked ~ .form-check-sign .check:before {
    color: #fff;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    -webkit-animation: checkbox-on 0.3s forwards;
    -moz-animation: checkbox-on 0.3s forwards;
    -o-animation: checkbox-on 0.3s forwards;
    -ms-animation: checkbox-on 0.3s forwards;
    animation: checkbox-on 0.3s forwards;
}
.form-check .form-check-input:checked ~ .form-check-sign:before {
    -webkit-animation: rippleOn 0.5s;
    -moz-animation: rippleOn 0.5s;
    -o-animation: rippleOn 0.5s;
    -ms-animation: rippleOn 0.5s;
    animation: rippleOn 0.5s;
}
.form-check .form-check-input:checked ~ .form-check-sign .check:after {
    -webkit-animation: rippleOn 0.5s forwards;
    -moz-animation: rippleOn 0.5s forwards;
    -o-animation: rippleOn 0.5s forwards;
    -ms-animation: rippleOn 0.5s forwards;
    animation: rippleOn 0.5s forwards;
}
.form-check .form-check-input:not(:checked) + .form-check-sign:before {
    -webkit-animation: rippleOff 0.5s;
    -moz-animation: rippleOff 0.5s;
    -o-animation: rippleOff 0.5s;
    -ms-animation: rippleOff 0.5s;
    animation: rippleOff 0.5s;
}
.form-check .form-check-input:not(:checked) + .form-check-sign .check:after {
    -webkit-animation: rippleOff 0.5s;
    -moz-animation: rippleOff 0.5s;
    -o-animation: rippleOff 0.5s;
    -ms-animation: rippleOff 0.5s;
    animation: rippleOff 0.5s;
}
.form-check .form-check-input[disabled] + .circle,
.form-check .form-check-input[disabled] ~ .form-check-sign .check,
fieldset[disabled] .form-check,
fieldset[disabled] .form-check .form-check-input {
    opacity: 0.5;
}
.form-check .form-check-input[disabled] ~ .form-check-sign .check {
    border-color: #000;
    opacity: 0.26;
}
.form-check .form-check-input[disabled] + .form-check-sign .check:after {
    background-color: rgba(0, 0, 0, 0.87);
    transform: rotate(-45deg);
}
.form-check .form-check-input[disabled][checked] + .form-check-sign .check {
    background-color: #000;
}
@keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    }
    50% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    }
    100% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    }
}
@keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@keyframes rippleOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
.switch label {
    position: relative;
    padding-left: 2.4375rem;
}
.switch label .bmd-switch-track {
    position: absolute;
    top: 0.3125rem;
    left: 0;
    display: inline-block;
    width: 2.125rem;
    height: 0.875rem;
    cursor: pointer;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.12) 0, rgba(0, 0, 0, 0.12) 50%, #c49bcb 50%, #c49bcb 100%);
    background-position: 0;
    background-size: 4.25rem 0.875rem;
    border-radius: 2.125rem;
    transition: background-position 0.2s ease-in;
}
.switch label .bmd-switch-track::after {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    align-self: center;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: #f1f1f1;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s ease;
    transform: translateY(-50%);
}
.switch label .bmd-switch-track:active::after {
    transform: translateY(-50%) scale3d(1.15, 0.85, 1);
}
.switch label input {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
}
.switch label input:checked + .bmd-switch-track {
    background-position: -100%;
}
.switch label input:checked + .bmd-switch-track::after {
    left: calc(100% - 1.25rem);
    background-color: #eec666;
}
.switch label input:disabled + .bmd-switch-track:active::after {
    transform: translateY(-50%);
}
.switch label input:disabled + .bmd-switch-track {
    cursor: default;
    background: rgba(0, 0, 0, 0.12);
}
.switch label input:disabled + .bmd-switch-track::after {
    background: #bdbdbd;
}
.form-check .form-check-label {
    cursor: pointer;
    padding-left: 25px;
    position: relative;
}
.form-group.is-focused .form-check .form-check-label {
    color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:focus,
.form-group.is-focused .form-check .form-check-label:hover {
    color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
    color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-label span {
    display: block;
    position: absolute;
    left: -1px;
    top: -1px;
    transition-duration: 0.2s;
}
.form-check .form-check-label .circle {
    border: 1px solid rgba(0, 0, 0, 0.54);
    height: 15px;
    width: 15px;
    border-radius: 100%;
    top: 1px;
}
.form-check .form-check-label .circle .check {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: #eec666;
    -webkit-transform: scale3d(0, 0, 0);
    -moz-transform: scale3d(0, 0, 0);
    -o-transform: scale3d(0, 0, 0);
    -ms-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
}
.form-check .form-check-input {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
}
.form-check .form-check-input:checked ~ .check,
.form-check .form-check-input:checked ~ .circle {
    opacity: 1;
}
.form-check .form-check-input:checked ~ .check {
    background-color: #eec666;
}
.form-check .form-check-input:checked ~ .circle {
    border-color: #eec666;
}
.form-check .form-check-input:checked .check:before {
    animation: checkboxOn 0.5s forwards;
}
.form-check .form-check-input:checked ~ .circle .check {
    -webkit-transform: scale3d(0.65, 0.65, 1);
    -moz-transform: scale3d(0.65, 0.65, 1);
    -o-transform: scale3d(0.65, 0.65, 1);
    -ms-transform: scale3d(0.65, 0.65, 1);
    transform: scale3d(0.65, 0.65, 1);
}
.form-check .form-check-input[disabled] ~ .check,
.form-check .form-check-input[disabled] ~ .circle {
    opacity: 0.26;
}
.form-check .form-check-input[disabled] ~ .check {
    background-color: #000;
}
.form-check .form-check-input[disabled] ~ .circle {
    border-color: #000;
}
.form-check .form-check-input[disabled] + .circle .check {
    background-color: #000;
}
.form-check .form-check-sign {
    vertical-align: middle;
    position: relative;
    top: -2px;
    float: left;
    padding-right: 10px;
    display: inline-block;
}
.form-check .form-check-label .circle:before {
    display: block;
    position: absolute;
    left: -1px;
    content: "";
    background-color: rgba(0, 0, 0, 0.84);
    height: 15px;
    width: 15px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    top: -1px;
    -webkit-transform: scale3d(2.3, 2.3, 1);
    -moz-transform: scale3d(2.3, 2.3, 1);
    -o-transform: scale3d(2.3, 2.3, 1);
    -ms-transform: scale3d(2.3, 2.3, 1);
    transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-label .form-check-input:checked + .circle:before {
    animation: rippleOn 0.5s;
}
.form-check .form-check-label .form-check-input:checked + .circle .check:before {
    color: #fff;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    animation: checkboxOn 0.3s forwards;
}
.form-check + .form-check {
    margin-top: 0;
}
@keyframes checkboxOn {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    }
    50% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    }
    100% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    }
}
@keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@keyframes rippleOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
.carousel .carousel-control.left,
.carousel .carousel-control.right {
    background-image: none;
}
.carousel .carousel-control.right i {
    right: 0;
}
.carousel .card {
    margin-top: 0;
}
.carousel .item {
    text-align: center;
}
.carousel.carousel-full-navigation .carousel-control {
    width: 50%;
}
.carousel.carousel-full-navigation .carousel-control.left,
.carousel.carousel-full-navigation .carousel-control.right {
    background-image: none;
}
.carousel.carousel-full-navigation .carousel-control .fa,
.carousel.carousel-full-navigation .carousel-control .material-icons {
    display: none;
}
.carousel .carousel-control {
    height: 15%;
    margin: auto;
}
.carousel .carousel-control-next .material-icons,
.carousel .carousel-control-prev .material-icons {
    z-index: 5;
    display: inline-block;
    font-size: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    margin: auto;
}
.carousel .carousel-indicators {
    bottom: 5px;
}
.carousel .carousel-caption {
    padding-bottom: 45px;
}
.carousel .carousel-caption .material-icons {
    position: relative;
    top: 5px;
}
form {
    margin-bottom: 1.125rem;
}
.card form {
    margin: 0;
}
.navbar form {
    margin-bottom: 0;
}
.navbar form .bmd-form-group {
    display: inline-block;
    padding-top: 0;
}
.navbar form .btn {
    margin-bottom: 0;
}
.form-control {
    background: no-repeat center bottom, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    border: 0;
    height: 36px;
    transition: background 0s ease-out;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    font-size: 14px;
}
.bmd-form-group.is-focused .form-control,
.form-control:focus {
    background-size: 100% 100%, 100% 100%;
    transition-duration: 0.3s;
    box-shadow: none;
}
.form-control::-moz-placeholder {
    color: #aaa;
    font-weight: 400;
    font-size: 14px;
}
.form-control:-ms-input-placeholder {
    color: #aaa;
    font-weight: 400;
    font-size: 14px;
}
.form-control::-webkit-input-placeholder {
    color: #aaa;
    font-weight: 400;
    font-size: 14px;
}
.has-white .form-control::-moz-placeholder {
    color: #fff;
}
.has-white .form-control:-ms-input-placeholder {
    color: #fff;
}
.has-white .form-control::-webkit-input-placeholder {
    color: #fff;
}
.bmd-help {
    position: absolute;
    display: none;
    font-size: 0.8rem;
    font-weight: 400;
}
.bmd-form-group.is-focused .bmd-help {
    display: block;
}
.bmd-help:nth-of-type(2) {
    padding-top: 1rem;
}
.bmd-help + .bmd-help {
    position: relative;
    margin-bottom: 0;
}
.checkbox label,
.checkbox-inline,
.is-focused .checkbox label,
.is-focused .checkbox-inline,
.is-focused .radio label,
.is-focused .radio-inline,
.is-focused .switch label,
.radio label,
.radio-inline,
.switch label {
    color: #999;
}
.checkbox label label:has(input[type="checkbox"][disabled]),
.checkbox label label:has(input[type="checkbox"][disabled]):focus,
.checkbox label label:has(input[type="checkbox"][disabled]):hover,
.checkbox label label:has(input[type="radio"][disabled]),
.checkbox label label:has(input[type="radio"][disabled]):focus,
.checkbox label label:has(input[type="radio"][disabled]):hover,
.checkbox-inline label:has(input[type="checkbox"][disabled]),
.checkbox-inline label:has(input[type="checkbox"][disabled]):focus,
.checkbox-inline label:has(input[type="checkbox"][disabled]):hover,
.checkbox-inline label:has(input[type="radio"][disabled]),
.checkbox-inline label:has(input[type="radio"][disabled]):focus,
.checkbox-inline label:has(input[type="radio"][disabled]):hover,
.is-focused .checkbox label label:has(input[type="checkbox"][disabled]),
.is-focused .checkbox label label:has(input[type="checkbox"][disabled]):focus,
.is-focused .checkbox label label:has(input[type="checkbox"][disabled]):hover,
.is-focused .checkbox label label:has(input[type="radio"][disabled]),
.is-focused .checkbox label label:has(input[type="radio"][disabled]):focus,
.is-focused .checkbox label label:has(input[type="radio"][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type="checkbox"][disabled]),
.is-focused .checkbox-inline label:has(input[type="checkbox"][disabled]):focus,
.is-focused .checkbox-inline label:has(input[type="checkbox"][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type="radio"][disabled]),
.is-focused .checkbox-inline label:has(input[type="radio"][disabled]):focus,
.is-focused .checkbox-inline label:has(input[type="radio"][disabled]):hover,
.is-focused .radio label label:has(input[type="checkbox"][disabled]),
.is-focused .radio label label:has(input[type="checkbox"][disabled]):focus,
.is-focused .radio label label:has(input[type="checkbox"][disabled]):hover,
.is-focused .radio label label:has(input[type="radio"][disabled]),
.is-focused .radio label label:has(input[type="radio"][disabled]):focus,
.is-focused .radio label label:has(input[type="radio"][disabled]):hover,
.is-focused .radio-inline label:has(input[type="checkbox"][disabled]),
.is-focused .radio-inline label:has(input[type="checkbox"][disabled]):focus,
.is-focused .radio-inline label:has(input[type="checkbox"][disabled]):hover,
.is-focused .radio-inline label:has(input[type="radio"][disabled]),
.is-focused .radio-inline label:has(input[type="radio"][disabled]):focus,
.is-focused .radio-inline label:has(input[type="radio"][disabled]):hover,
.is-focused .switch label label:has(input[type="checkbox"][disabled]),
.is-focused .switch label label:has(input[type="checkbox"][disabled]):focus,
.is-focused .switch label label:has(input[type="checkbox"][disabled]):hover,
.is-focused .switch label label:has(input[type="radio"][disabled]),
.is-focused .switch label label:has(input[type="radio"][disabled]):focus,
.is-focused .switch label label:has(input[type="radio"][disabled]):hover,
.radio label label:has(input[type="checkbox"][disabled]),
.radio label label:has(input[type="checkbox"][disabled]):focus,
.radio label label:has(input[type="checkbox"][disabled]):hover,
.radio label label:has(input[type="radio"][disabled]),
.radio label label:has(input[type="radio"][disabled]):focus,
.radio label label:has(input[type="radio"][disabled]):hover,
.radio-inline label:has(input[type="checkbox"][disabled]),
.radio-inline label:has(input[type="checkbox"][disabled]):focus,
.radio-inline label:has(input[type="checkbox"][disabled]):hover,
.radio-inline label:has(input[type="radio"][disabled]),
.radio-inline label:has(input[type="radio"][disabled]):focus,
.radio-inline label:has(input[type="radio"][disabled]):hover,
.switch label label:has(input[type="checkbox"][disabled]),
.switch label label:has(input[type="checkbox"][disabled]):focus,
.switch label label:has(input[type="checkbox"][disabled]):hover,
.switch label label:has(input[type="radio"][disabled]),
.switch label label:has(input[type="radio"][disabled]):focus,
.switch label label:has(input[type="radio"][disabled]):hover,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox label:focus,
fieldset[disabled] .checkbox label:hover,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .checkbox-inline:focus,
fieldset[disabled] .checkbox-inline:hover,
fieldset[disabled] .is-focused .checkbox label,
fieldset[disabled] .is-focused .checkbox label:focus,
fieldset[disabled] .is-focused .checkbox label:hover,
fieldset[disabled] .is-focused .checkbox-inline,
fieldset[disabled] .is-focused .checkbox-inline:focus,
fieldset[disabled] .is-focused .checkbox-inline:hover,
fieldset[disabled] .is-focused .radio label,
fieldset[disabled] .is-focused .radio label:focus,
fieldset[disabled] .is-focused .radio label:hover,
fieldset[disabled] .is-focused .radio-inline,
fieldset[disabled] .is-focused .radio-inline:focus,
fieldset[disabled] .is-focused .radio-inline:hover,
fieldset[disabled] .is-focused .switch label,
fieldset[disabled] .is-focused .switch label:focus,
fieldset[disabled] .is-focused .switch label:hover,
fieldset[disabled] .radio label,
fieldset[disabled] .radio label:focus,
fieldset[disabled] .radio label:hover,
fieldset[disabled] .radio-inline,
fieldset[disabled] .radio-inline:focus,
fieldset[disabled] .radio-inline:hover,
fieldset[disabled] .switch label,
fieldset[disabled] .switch label:focus,
fieldset[disabled] .switch label:hover {
    color: #999;
}
[class*=" bmd-label"],
[class^="bmd-label"] {
    color: #999;
}
.form-control,
.is-focused .form-control {
    background-image: linear-gradient(to top, #eec666 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control.disabled,
.form-control:disabled,
.form-control[disabled],
fieldset[disabled][disabled] .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
}
.form-control.form-control-success,
.is-focused .form-control.form-control-success {
    background-image: linear-gradient(to top, #eec666 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.form-control.form-control-warning,
.is-focused .form-control.form-control-warning {
    background-image: linear-gradient(to top, #eec666 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.form-control.form-control-danger,
.is-focused .form-control.form-control-danger {
    background-image: linear-gradient(to top, #eec666 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #999;
}
.is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(153, 153, 153, 0.8);
    border-radius: 0.2rem;
}
.is-focused .custom-select.is-valid,
.is-focused .form-control.is-valid,
.was-validated .is-focused .custom-select:valid,
.was-validated .is-focused .form-control:valid {
    border-color: #999;
}
.is-focused .custom-select.is-valid:focus,
.is-focused .form-control.is-valid:focus,
.was-validated .is-focused .custom-select:valid:focus,
.was-validated .is-focused .form-control:valid:focus {
    border-color: #999;
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.is-focused .custom-select.is-valid ~ .valid-feedback,
.is-focused .custom-select.is-valid ~ .valid-tooltip,
.is-focused .form-control.is-valid ~ .valid-feedback,
.is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .is-focused .custom-select:valid ~ .valid-tooltip,
.was-validated .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .is-focused .form-control:valid ~ .valid-tooltip {
    display: block;
}
.is-focused .form-check-input.is-valid ~ .form-check-label,
.was-validated .is-focused .form-check-input:valid ~ .form-check-label {
    color: #999;
}
.is-focused .form-check-input.is-valid ~ .valid-feedback,
.is-focused .form-check-input.is-valid ~ .valid-tooltip,
.was-validated .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .is-focused .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.is-focused .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .is-focused .custom-control-input:valid ~ .custom-control-label {
    color: #999;
}
.is-focused .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .is-focused .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #d9d9d9;
}
.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.is-focused .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .is-focused .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #b3b3b3;
}
.is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.is-focused .custom-file-input.is-valid ~ .custom-file-label,
.was-validated .is-focused .custom-file-input:valid ~ .custom-file-label {
    border-color: #999;
}
.is-focused .custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .is-focused .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.is-focused .custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .is-focused .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .is-focused .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.is-focused [class*=" bmd-label"],
.is-focused [class^="bmd-label"] {
    color: #eec666;
}
.is-focused .bmd-label-placeholder {
    color: #999;
}
.is-focused .form-control {
    border-color: #d2d2d2;
}
.is-focused .bmd-help {
    color: #555;
}
.has-success [class*=" bmd-label"],
.has-success [class^="bmd-label"] {
    color: #4caf50;
}
.has-success .form-control,
.is-focused .has-success .form-control {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control.disabled,
.has-success .form-control:disabled,
.has-success .form-control[disabled],
fieldset[disabled][disabled] .has-success .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
}
.has-success .form-control.form-control-success,
.is-focused .has-success .form-control.form-control-success {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-success .form-control.form-control-warning,
.is-focused .has-success .form-control.form-control-warning {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-success .form-control.form-control-danger,
.is-focused .has-success .form-control.form-control-danger {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-success .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #4caf50;
}
.has-success .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(76, 175, 80, 0.8);
    border-radius: 0.2rem;
}
.has-success .is-focused .custom-select.is-valid,
.has-success .is-focused .form-control.is-valid,
.was-validated .has-success .is-focused .custom-select:valid,
.was-validated .has-success .is-focused .form-control:valid {
    border-color: #4caf50;
}
.has-success .is-focused .custom-select.is-valid:focus,
.has-success .is-focused .form-control.is-valid:focus,
.was-validated .has-success .is-focused .custom-select:valid:focus,
.was-validated .has-success .is-focused .form-control:valid:focus {
    border-color: #4caf50;
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.has-success .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-select.is-valid ~ .valid-tooltip,
.has-success .is-focused .form-control.is-valid ~ .valid-feedback,
.has-success .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-success .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .custom-select:valid ~ .valid-tooltip,
.was-validated .has-success .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .form-control:valid ~ .valid-tooltip {
    display: block;
}
.has-success .is-focused .form-check-input.is-valid ~ .form-check-label,
.was-validated .has-success .is-focused .form-check-input:valid ~ .form-check-label {
    color: #4caf50;
}
.has-success .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-success .is-focused .form-check-input.is-valid ~ .valid-tooltip,
.was-validated .has-success .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.has-success .is-focused .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .has-success .is-focused .custom-control-input:valid ~ .custom-control-label {
    color: #4caf50;
}
.has-success .is-focused .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .has-success .is-focused .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #a3d7a5;
}
.has-success .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .has-success .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.has-success .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .has-success .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #6ec071;
}
.has-success .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .has-success .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.has-success .is-focused .custom-file-input.is-valid ~ .custom-file-label,
.was-validated .has-success .is-focused .custom-file-input:valid ~ .custom-file-label {
    border-color: #4caf50;
}
.has-success .is-focused .custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .has-success .is-focused .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.has-success .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .has-success .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.has-success .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .has-success .is-focused .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.has-success .is-focused [class*=" bmd-label"],
.has-success .is-focused [class^="bmd-label"] {
    color: #4caf50;
}
.has-success .is-focused .bmd-label-placeholder {
    color: #4caf50;
}
.has-success .is-focused .form-control {
    border-color: #4caf50;
}
.has-success .is-focused .bmd-help {
    color: #555;
}
.has-info [class*=" bmd-label"],
.has-info [class^="bmd-label"] {
    color: #00bcd4;
}
.has-info .form-control,
.is-focused .has-info .form-control {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control.disabled,
.has-info .form-control:disabled,
.has-info .form-control[disabled],
fieldset[disabled][disabled] .has-info .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
}
.has-info .form-control.form-control-success,
.is-focused .has-info .form-control.form-control-success {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-info .form-control.form-control-warning,
.is-focused .has-info .form-control.form-control-warning {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-info .form-control.form-control-danger,
.is-focused .has-info .form-control.form-control-danger {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-info .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #00bcd4;
}
.has-info .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(0, 188, 212, 0.8);
    border-radius: 0.2rem;
}
.has-info .is-focused .custom-select.is-valid,
.has-info .is-focused .form-control.is-valid,
.was-validated .has-info .is-focused .custom-select:valid,
.was-validated .has-info .is-focused .form-control:valid {
    border-color: #00bcd4;
}
.has-info .is-focused .custom-select.is-valid:focus,
.has-info .is-focused .form-control.is-valid:focus,
.was-validated .has-info .is-focused .custom-select:valid:focus,
.was-validated .has-info .is-focused .form-control:valid:focus {
    border-color: #00bcd4;
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.has-info .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-select.is-valid ~ .valid-tooltip,
.has-info .is-focused .form-control.is-valid ~ .valid-feedback,
.has-info .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-info .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .custom-select:valid ~ .valid-tooltip,
.was-validated .has-info .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .form-control:valid ~ .valid-tooltip {
    display: block;
}
.has-info .is-focused .form-check-input.is-valid ~ .form-check-label,
.was-validated .has-info .is-focused .form-check-input:valid ~ .form-check-label {
    color: #00bcd4;
}
.has-info .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-info .is-focused .form-check-input.is-valid ~ .valid-tooltip,
.was-validated .has-info .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.has-info .is-focused .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .has-info .is-focused .custom-control-input:valid ~ .custom-control-label {
    color: #00bcd4;
}
.has-info .is-focused .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .has-info .is-focused .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #55ecff;
}
.has-info .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .has-info .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.has-info .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .has-info .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #08e3ff;
}
.has-info .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .has-info .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.has-info .is-focused .custom-file-input.is-valid ~ .custom-file-label,
.was-validated .has-info .is-focused .custom-file-input:valid ~ .custom-file-label {
    border-color: #00bcd4;
}
.has-info .is-focused .custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .has-info .is-focused .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.has-info .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .has-info .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.has-info .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .has-info .is-focused .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.has-info .is-focused [class*=" bmd-label"],
.has-info .is-focused [class^="bmd-label"] {
    color: #00bcd4;
}
.has-info .is-focused .bmd-label-placeholder {
    color: #00bcd4;
}
.has-info .is-focused .form-control {
    border-color: #00bcd4;
}
.has-info .is-focused .bmd-help {
    color: #555;
}
.has-white [class*=" bmd-label"],
.has-white [class^="bmd-label"] {
    color: #fff;
}
.has-white .form-control,
.is-focused .has-white .form-control {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control.disabled,
.has-white .form-control:disabled,
.has-white .form-control[disabled],
fieldset[disabled][disabled] .has-white .form-control {
    background-image: linear-gradient(to right, #fff 0, #fff 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
}
.has-white .form-control.form-control-success,
.is-focused .has-white .form-control.form-control-success {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-white .form-control.form-control-warning,
.is-focused .has-white .form-control.form-control-warning {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-white .form-control.form-control-danger,
.is-focused .has-white .form-control.form-control-danger {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-white .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fff;
}
.has-white .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.2rem;
}
.has-white .is-focused .custom-select.is-valid,
.has-white .is-focused .form-control.is-valid,
.was-validated .has-white .is-focused .custom-select:valid,
.was-validated .has-white .is-focused .form-control:valid {
    border-color: #fff;
}
.has-white .is-focused .custom-select.is-valid:focus,
.has-white .is-focused .form-control.is-valid:focus,
.was-validated .has-white .is-focused .custom-select:valid:focus,
.was-validated .has-white .is-focused .form-control:valid:focus {
    border-color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.has-white .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-white .is-focused .custom-select.is-valid ~ .valid-tooltip,
.has-white .is-focused .form-control.is-valid ~ .valid-feedback,
.has-white .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-white .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .custom-select:valid ~ .valid-tooltip,
.was-validated .has-white .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .form-control:valid ~ .valid-tooltip {
    display: block;
}
.has-white .is-focused .form-check-input.is-valid ~ .form-check-label,
.was-validated .has-white .is-focused .form-check-input:valid ~ .form-check-label {
    color: #fff;
}
.has-white .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-white .is-focused .form-check-input.is-valid ~ .valid-tooltip,
.was-validated .has-white .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.has-white .is-focused .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .has-white .is-focused .custom-control-input:valid ~ .custom-control-label {
    color: #fff;
}
.has-white .is-focused .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .has-white .is-focused .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #fff;
}
.has-white .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-white .is-focused .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .has-white .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.has-white .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .has-white .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #fff;
}
.has-white .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .has-white .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.has-white .is-focused .custom-file-input.is-valid ~ .custom-file-label,
.was-validated .has-white .is-focused .custom-file-input:valid ~ .custom-file-label {
    border-color: #fff;
}
.has-white .is-focused .custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .has-white .is-focused .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.has-white .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-white .is-focused .custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .has-white .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-white .is-focused .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.has-white .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .has-white .is-focused .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.has-white .is-focused [class*=" bmd-label"],
.has-white .is-focused [class^="bmd-label"] {
    color: #fff;
}
.has-white .is-focused .bmd-label-placeholder {
    color: #fff;
}
.has-white .is-focused .form-control {
    border-color: #fff;
}
.has-white .is-focused .bmd-help {
    color: #555;
}
.has-white .form-control:focus {
    color: #fff;
}
.has-warning [class*=" bmd-label"],
.has-warning [class^="bmd-label"] {
    color: #ff9800;
}
.has-warning .form-control,
.is-focused .has-warning .form-control {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control.disabled,
.has-warning .form-control:disabled,
.has-warning .form-control[disabled],
fieldset[disabled][disabled] .has-warning .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
}
.has-warning .form-control.form-control-success,
.is-focused .has-warning .form-control.form-control-success {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-warning .form-control.form-control-warning,
.is-focused .has-warning .form-control.form-control-warning {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-warning .form-control.form-control-danger,
.is-focused .has-warning .form-control.form-control-danger {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-warning .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ff9800;
}
.has-warning .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(255, 152, 0, 0.8);
    border-radius: 0.2rem;
}
.has-warning .is-focused .custom-select.is-valid,
.has-warning .is-focused .form-control.is-valid,
.was-validated .has-warning .is-focused .custom-select:valid,
.was-validated .has-warning .is-focused .form-control:valid {
    border-color: #ff9800;
}
.has-warning .is-focused .custom-select.is-valid:focus,
.has-warning .is-focused .form-control.is-valid:focus,
.was-validated .has-warning .is-focused .custom-select:valid:focus,
.was-validated .has-warning .is-focused .form-control:valid:focus {
    border-color: #ff9800;
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.has-warning .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-select.is-valid ~ .valid-tooltip,
.has-warning .is-focused .form-control.is-valid ~ .valid-feedback,
.has-warning .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-warning .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .custom-select:valid ~ .valid-tooltip,
.was-validated .has-warning .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .form-control:valid ~ .valid-tooltip {
    display: block;
}
.has-warning .is-focused .form-check-input.is-valid ~ .form-check-label,
.was-validated .has-warning .is-focused .form-check-input:valid ~ .form-check-label {
    color: #ff9800;
}
.has-warning .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .form-check-input.is-valid ~ .valid-tooltip,
.was-validated .has-warning .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.has-warning .is-focused .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .has-warning .is-focused .custom-control-input:valid ~ .custom-control-label {
    color: #ff9800;
}
.has-warning .is-focused .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .has-warning .is-focused .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #ffcc80;
}
.has-warning .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .has-warning .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.has-warning .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .has-warning .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #ffad33;
}
.has-warning .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .has-warning .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.has-warning .is-focused .custom-file-input.is-valid ~ .custom-file-label,
.was-validated .has-warning .is-focused .custom-file-input:valid ~ .custom-file-label {
    border-color: #ff9800;
}
.has-warning .is-focused .custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .has-warning .is-focused .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.has-warning .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .has-warning .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.has-warning .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .has-warning .is-focused .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.has-warning .is-focused [class*=" bmd-label"],
.has-warning .is-focused [class^="bmd-label"] {
    color: #ff9800;
}
.has-warning .is-focused .bmd-label-placeholder {
    color: #ff9800;
}
.has-warning .is-focused .form-control {
    border-color: #ff9800;
}
.has-warning .is-focused .bmd-help {
    color: #555;
}
.has-danger [class*=" bmd-label"],
.has-danger [class^="bmd-label"] {
    color: #f44336;
}
.has-danger .form-control,
.is-focused .has-danger .form-control {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control.disabled,
.has-danger .form-control:disabled,
.has-danger .form-control[disabled],
fieldset[disabled][disabled] .has-danger .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
}
.has-danger .form-control.form-control-success,
.is-focused .has-danger .form-control.form-control-success {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-danger .form-control.form-control-warning,
.is-focused .has-danger .form-control.form-control-warning {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-danger .form-control.form-control-danger,
.is-focused .has-danger .form-control.form-control-danger {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-danger .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f44336;
}
.has-danger .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(244, 67, 54, 0.8);
    border-radius: 0.2rem;
}
.has-danger .is-focused .custom-select.is-valid,
.has-danger .is-focused .form-control.is-valid,
.was-validated .has-danger .is-focused .custom-select:valid,
.was-validated .has-danger .is-focused .form-control:valid {
    border-color: #f44336;
}
.has-danger .is-focused .custom-select.is-valid:focus,
.has-danger .is-focused .form-control.is-valid:focus,
.was-validated .has-danger .is-focused .custom-select:valid:focus,
.was-validated .has-danger .is-focused .form-control:valid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.has-danger .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-select.is-valid ~ .valid-tooltip,
.has-danger .is-focused .form-control.is-valid ~ .valid-feedback,
.has-danger .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-danger .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .custom-select:valid ~ .valid-tooltip,
.was-validated .has-danger .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .form-control:valid ~ .valid-tooltip {
    display: block;
}
.has-danger .is-focused .form-check-input.is-valid ~ .form-check-label,
.was-validated .has-danger .is-focused .form-check-input:valid ~ .form-check-label {
    color: #f44336;
}
.has-danger .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .form-check-input.is-valid ~ .valid-tooltip,
.was-validated .has-danger .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.has-danger .is-focused .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .has-danger .is-focused .custom-control-input:valid ~ .custom-control-label {
    color: #f44336;
}
.has-danger .is-focused .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .has-danger .is-focused .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #fbb4af;
}
.has-danger .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .has-danger .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.has-danger .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .has-danger .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #f77066;
}
.has-danger .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .has-danger .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.has-danger .is-focused .custom-file-input.is-valid ~ .custom-file-label,
.was-validated .has-danger .is-focused .custom-file-input:valid ~ .custom-file-label {
    border-color: #f44336;
}
.has-danger .is-focused .custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .has-danger .is-focused .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.has-danger .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .has-danger .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.has-danger .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .has-danger .is-focused .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.has-danger .is-focused [class*=" bmd-label"],
.has-danger .is-focused [class^="bmd-label"] {
    color: #f44336;
}
.has-danger .is-focused .bmd-label-placeholder {
    color: #f44336;
}
.has-danger .is-focused .form-control {
    border-color: #f44336;
}
.has-danger .is-focused .bmd-help {
    color: #555;
}
.has-rose [class*=" bmd-label"],
.has-rose [class^="bmd-label"] {
    color: #e91e63;
}
.has-rose .form-control,
.is-focused .has-rose .form-control {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control.disabled,
.has-rose .form-control:disabled,
.has-rose .form-control[disabled],
fieldset[disabled][disabled] .has-rose .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
}
.has-rose .form-control.form-control-success,
.is-focused .has-rose .form-control.form-control-success {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-rose .form-control.form-control-warning,
.is-focused .has-rose .form-control.form-control-warning {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-rose .form-control.form-control-danger,
.is-focused .has-rose .form-control.form-control-danger {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px),
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-rose .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e91e63;
}
.has-rose .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(233, 30, 99, 0.8);
    border-radius: 0.2rem;
}
.has-rose .is-focused .custom-select.is-valid,
.has-rose .is-focused .form-control.is-valid,
.was-validated .has-rose .is-focused .custom-select:valid,
.was-validated .has-rose .is-focused .form-control:valid {
    border-color: #e91e63;
}
.has-rose .is-focused .custom-select.is-valid:focus,
.has-rose .is-focused .form-control.is-valid:focus,
.was-validated .has-rose .is-focused .custom-select:valid:focus,
.was-validated .has-rose .is-focused .form-control:valid:focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.has-rose .is-focused .custom-select.is-valid ~ .valid-feedback,
.has-rose .is-focused .custom-select.is-valid ~ .valid-tooltip,
.has-rose .is-focused .form-control.is-valid ~ .valid-feedback,
.has-rose .is-focused .form-control.is-valid ~ .valid-tooltip,
.was-validated .has-rose .is-focused .custom-select:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .custom-select:valid ~ .valid-tooltip,
.was-validated .has-rose .is-focused .form-control:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .form-control:valid ~ .valid-tooltip {
    display: block;
}
.has-rose .is-focused .form-check-input.is-valid ~ .form-check-label,
.was-validated .has-rose .is-focused .form-check-input:valid ~ .form-check-label {
    color: #e91e63;
}
.has-rose .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-rose .is-focused .form-check-input.is-valid ~ .valid-tooltip,
.was-validated .has-rose .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.has-rose .is-focused .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .has-rose .is-focused .custom-control-input:valid ~ .custom-control-label {
    color: #e91e63;
}
.has-rose .is-focused .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .has-rose .is-focused .custom-control-input:valid ~ .custom-control-label::before {
    background-color: #f492b4;
}
.has-rose .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-rose .is-focused .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .has-rose .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.has-rose .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .has-rose .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #ee4c83;
}
.has-rose .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .has-rose .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.has-rose .is-focused .custom-file-input.is-valid ~ .custom-file-label,
.was-validated .has-rose .is-focused .custom-file-input:valid ~ .custom-file-label {
    border-color: #e91e63;
}
.has-rose .is-focused .custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .has-rose .is-focused .custom-file-input:valid ~ .custom-file-label::before {
    border-color: inherit;
}
.has-rose .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-rose .is-focused .custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .has-rose .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-rose .is-focused .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.has-rose .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .has-rose .is-focused .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.has-rose .is-focused [class*=" bmd-label"],
.has-rose .is-focused [class^="bmd-label"] {
    color: #e91e63;
}
.has-rose .is-focused .bmd-label-placeholder {
    color: #e91e63;
}
.has-rose .is-focused .form-control {
    border-color: #e91e63;
}
.has-rose .is-focused .bmd-help {
    color: #555;
}
.bmd-form-group {
    position: relative;
    padding-top: 27px;
}
.bmd-form-group:not(.has-success):not(.has-danger) [class*=" bmd-label"].bmd-label-floating,
.bmd-form-group:not(.has-success):not(.has-danger) [class^="bmd-label"].bmd-label-floating {
    color: #aaa;
}
.bmd-form-group [class*=" bmd-label"],
.bmd-form-group [class^="bmd-label"] {
    position: absolute;
    pointer-events: none;
    transition: 0.3s ease all;
}
.bmd-form-group [class*=" bmd-label"].bmd-label-floating,
.bmd-form-group [class^="bmd-label"].bmd-label-floating {
    will-change: left, top, contents;
    margin: 0;
    line-height: 1.4;
    font-weight: 400;
}
.bmd-form-group.is-filled .bmd-label-placeholder {
    display: none;
}
.bmd-form-group.bmd-collapse-inline {
    display: flex;
    align-items: center;
    padding: 0;
    min-height: 2.1em;
}
.bmd-form-group.bmd-collapse-inline .collapse {
    flex: 1;
    display: none;
}
.bmd-form-group.bmd-collapse-inline .collapse.show {
    max-width: 1200px;
}
.bmd-form-group.bmd-collapse-inline .collapse.show,
.bmd-form-group.bmd-collapse-inline .collapsing,
.bmd-form-group.bmd-collapse-inline .width:not(.collapse) {
    display: block;
}
.bmd-form-group.bmd-collapse-inline .collapsing {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-form-group .form-control,
.bmd-form-group input::placeholder,
.bmd-form-group label {
    line-height: 1.1;
}
.bmd-form-group label {
    color: #aaa;
}
.bmd-form-group .checkbox label,
.bmd-form-group .radio label,
.bmd-form-group .switch label,
.bmd-form-group label.checkbox-inline,
.bmd-form-group label.radio-inline {
    line-height: 1.5;
}
.bmd-form-group .checkbox label,
.bmd-form-group .radio label,
.bmd-form-group label {
    font-size: 0.875rem;
}
.bmd-form-group .bmd-label-floating,
.bmd-form-group .bmd-label-placeholder {
    top: 2.3125rem;
}
.bmd-form-group .is-filled .bmd-label-floating,
.bmd-form-group .is-focused .bmd-label-floating {
    top: 1rem;
    left: 0;
    font-size: 0.6875rem;
}
.bmd-form-group .bmd-label-static {
    top: 0.35rem;
    left: 0;
    font-size: 0.875rem;
}
.bmd-form-group .bmd-help {
    margin-top: 0;
    font-size: 0.75rem;
}
.bmd-form-group .form-control.form-control-danger,
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group .form-control.form-control-danger,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning {
    background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm input::placeholder,
.bmd-form-group.bmd-form-group-sm label {
    line-height: 1.1;
}
.bmd-form-group.bmd-form-group-sm label {
    color: #aaa;
}
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm .switch label,
.bmd-form-group.bmd-form-group-sm label.checkbox-inline,
.bmd-form-group.bmd-form-group-sm label.radio-inline {
    line-height: 1.5;
}
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label {
    font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .bmd-label-placeholder {
    top: 1.875rem;
}
.bmd-form-group.bmd-form-group-sm .is-filled .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .is-focused .bmd-label-floating {
    top: 0.75rem;
    left: 0;
    font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-static {
    top: 0.1rem;
    left: 0;
    font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-help {
    margin-top: 0;
    font-size: 0.65625rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right 0.34375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning {
    background-size: 100% 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg input::placeholder,
.bmd-form-group.bmd-form-group-lg label {
    line-height: 1.1;
}
.bmd-form-group.bmd-form-group-lg label {
    color: #aaa;
}
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg .switch label,
.bmd-form-group.bmd-form-group-lg label.checkbox-inline,
.bmd-form-group.bmd-form-group-lg label.radio-inline {
    line-height: 1.5;
}
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label {
    font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .bmd-label-placeholder {
    top: 2.4375rem;
}
.bmd-form-group.bmd-form-group-lg .is-filled .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .is-focused .bmd-label-floating {
    top: 1rem;
    left: 0;
    font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-static {
    top: 0.35rem;
    left: 0;
    font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-help {
    margin-top: 0;
    font-size: 0.9375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right 0.59375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning {
    background-size: 100% 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.form-control,
input::placeholder,
label {
    line-height: 1.1;
}
label {
    color: #aaa;
}
.checkbox label,
.radio label,
.switch label,
label.checkbox-inline,
label.radio-inline {
    line-height: 1.5;
}
.checkbox label,
.radio label,
label {
    font-size: 0.875rem;
}
.bmd-label-floating,
.bmd-label-placeholder {
    top: 2.3125rem;
}
.is-filled .bmd-label-floating,
.is-focused .bmd-label-floating {
    top: 1rem;
    left: 0;
    font-size: 0.6875rem;
}
.bmd-label-static {
    top: 0.35rem;
    left: 0;
    font-size: 0.875rem;
}
.bmd-help {
    margin-top: 0;
    font-size: 0.75rem;
}
.form-control.form-control-danger,
.form-control.form-control-success,
.form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group.is-focused .form-control.form-control-danger,
.bmd-form-group.is-focused .form-control.form-control-success,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger,
.form-control.form-control-danger:focus,
.form-control.form-control-success,
.form-control.form-control-success:focus,
.form-control.form-control-warning,
.form-control.form-control-warning:focus {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}
.bmd-form-group.is-focused .form-control.form-control-danger,
.bmd-form-group.is-focused .form-control.form-control-success,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger:focus,
.form-control.form-control-success:focus,
.form-control.form-control-warning:focus {
    background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
select,
select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
}
@media (min-width: 576px) {
    .form-inline .input-group {
        display: inline-flex;
        align-items: center;
    }
}
.form-control-feedback {
    position: absolute;
    top: 33px;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
    opacity: 0;
}
.has-success .form-control-feedback {
    color: #4caf50;
    opacity: 1;
}
.has-danger .form-control-feedback {
    color: #f44336;
    opacity: 1;
}
.form-group {
    margin-bottom: 25px;
    position: relative;
}
textarea {
    height: auto !important;
    resize: none;
    line-height: 1.428571 !important;
}
.form-group input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.form-newsletter .form-group,
.form-newsletter .input-group {
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
    padding-top: 5px;
}
.form-newsletter .btn {
    float: left;
    width: 20%;
    margin: 9px 0 0;
}
.form-file-upload .input-group-btn:last-child > .btn-round {
    border-radius: 30px;
}
.form-file-upload .input-group-btn .btn {
    margin: 0;
}
.form-file-upload .input-group {
    width: 100%;
}
.input-group .input-group-btn {
    padding: 0 12px;
}
.input-group .input-group-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px 0 15px;
    background-color: transparent;
    border-color: transparent;
}
.input-group .input-group-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 0;
}
.list-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0.5rem 0;
}
.list-group.bmd-list-group-sm {
    padding: 0.25rem 0;
}
.list-group.bmd-list-group-sm .list-group-item {
    padding: 0.5rem 1rem;
}
.bmd-list-group-col {
    display: flex;
    flex-direction: column;
    min-width: 0;
}
.list-group-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 1rem;
    line-height: 1;
}
.list-group-item .list-group-item-text {
    min-width: 0;
    max-height: 2.188rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list-group-item :first-child {
    margin-right: 2rem;
}
.list-group-item > * ~ .label:last-child,
.list-group-item > * ~ .material-icons:last-child,
.list-group-item > .pull-lg-right,
.list-group-item > .pull-md-right,
.list-group-item > .pull-sm-right,
.list-group-item > .pull-xl-right,
.list-group-item > .pull-xs-right {
    margin-right: 0;
    margin-left: auto;
}
.list-group-item .material-icons.pull-lg-right,
.list-group-item .material-icons.pull-md-right,
.list-group-item .material-icons.pull-sm-right,
.list-group-item .material-icons.pull-xl-right,
.list-group-item .material-icons.pull-xs-right,
.list-group-item .material-icons ~ .material-icons:last-child {
    padding-left: 1rem;
}
.list-group-item .list-group-item-text {
    font-size: 0.875rem;
    color: #555;
}
.nav-link {
    text-transform: uppercase;
}
.navbar-nav .nav-link {
    padding: 0.5321rem;
    font-size: 0.875rem;
    font-weight: 400;
}
.nav-pills,
.nav-tabs {
    border: 0;
    border-radius: 3px;
    padding: 0 15px;
}
.nav-pills .nav-link,
.nav-tabs .nav-link {
    padding: 1.4286em 0.8575em;
    font-size: 0.875rem;
    font-weight: 500;
    border: 0;
}
.nav .nav-item {
    position: relative;
}
.img-thumbnail {
    border-radius: 16px;
}
.img-raised {
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.rounded {
    border-radius: 6px !important;
}
.makeStyles-appBar-6,
.navbar {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 15px 0 !important;
    margin-bottom: 20px;
    color: #fff !important;
    background-color: #1e2023 !important;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
}
.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4);
    background-color: #fff;
    color: #555;
}
.navbar .navbar-toggler .navbar-toggler-icon {
    background-color: #555;
}
.navbar.fixed-top {
    border-radius: 0;
}
.navbar .navbar-nav .nav-item .nav-link {
    position: relative;
    color: inherit;
    padding: 0.9375rem;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    line-height: 20px;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn-just-icon) .fa {
    position: relative;
    top: 2px;
    margin-top: -4px;
    margin-right: 4px;
}
.navbar .navbar-nav .nav-item .nav-link .fa,
.navbar .navbar-nav .nav-item .nav-link .material-icons {
    font-size: 1.25rem;
    max-width: 24px;
    margin-top: -1.1em;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
    margin-top: -7px;
    top: 3px;
    position: relative;
    margin-right: 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo {
    padding: 0;
    margin: 0 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo:after {
    display: none;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .profile-photo-small {
    height: 40px;
    width: 40px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .ripple-container {
    border-radius: 50%;
}
.navbar .navbar-nav .dropdown-menu-right {
    transform-origin: 100% 0;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:focus,
.navbar .navbar-nav .nav-item.active .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
.navbar .btn,
.navbar .navbar-nav .nav-item .btn {
    margin-top: 0;
    margin-bottom: 0;
}
.navbar .navbar-toggler {
    cursor: pointer;
}
.navbar .navbar-toggler .navbar-toggler-icon {
    position: relative;
    width: 22px;
    height: 2px;
    vertical-align: middle;
    outline: 0;
    display: block;
    border-radius: 1px;
}
.navbar .navbar-toggler .navbar-toggler-icon + .navbar-toggler-icon {
    margin-top: 4px;
}
.navbar.navbar-absolute {
    position: absolute;
    width: 100%;
    padding-top: 10px;
    z-index: 1029;
}
.navbar .navbar-wrapper {
    display: inline-flex;
    align-items: center;
}
.navbar .navbar-brand {
    position: relative;
    color: inherit;
    height: 50px;
    font-size: 1.125rem;
    line-height: 30px;
    padding: 0.625rem 0;
}
.navbar.bg-primary {
    color: #fff;
    background-color: #eec666 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46);
}
.navbar.bg-primary .dropdown-item:focus,
.navbar.bg-primary .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
    background-color: #eec666;
    color: #fff;
}
.navbar.bg-primary .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
}
.navbar.bg-info {
    color: #fff;
    background-color: #00bcd4 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46);
}
.navbar.bg-info .dropdown-item:focus,
.navbar.bg-info .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
    background-color: #00bcd4;
    color: #fff;
}
.navbar.bg-info .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
}
.navbar.bg-warning {
    color: #fff;
    background-color: #ff9800 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46);
}
.navbar.bg-warning .dropdown-item:focus,
.navbar.bg-warning .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
    background-color: #ff9800;
    color: #fff;
}
.navbar.bg-warning .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
}
.navbar.bg-rose {
    color: #fff;
    background-color: #e91e63 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46);
}
.navbar.bg-rose .dropdown-item:focus,
.navbar.bg-rose .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
    background-color: #e91e63;
    color: #fff;
}
.navbar.bg-rose .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
}
.navbar.bg-danger {
    color: #fff;
    background-color: #f44336 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46);
}
.navbar.bg-danger .dropdown-item:focus,
.navbar.bg-danger .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
    background-color: #f44336;
    color: #fff;
}
.navbar.bg-danger .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
}
.navbar.bg-success {
    color: #fff;
    background-color: #4caf50 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46);
}
.navbar.bg-success .dropdown-item:focus,
.navbar.bg-success .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
    background-color: #4caf50;
    color: #fff;
}
.navbar.bg-success .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
}
.navbar.bg-dark {
    color: #fff;
    background-color: #212121 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46);
}
.navbar.bg-dark .dropdown-item:focus,
.navbar.bg-dark .dropdown-item:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4);
    background-color: #212121;
    color: #fff;
}
.navbar.bg-dark .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
}
.navbar.navbar-transparent {
    background-color: transparent !important;
    box-shadow: none;
    padding-top: 25px;
    color: #fff;
}
#navbar .navbar {
    border-radius: 0;
}
.badge {
    padding: 5px 12px;
    text-transform: uppercase;
    font-size: 10px;
    color: #fff;
}
.badge.badge-primary {
    background-color: #eec666;
}
.badge.badge-info {
    background-color: #00bcd4;
}
.badge.badge-success {
    background-color: #4caf50;
}
.badge.badge-warning {
    background-color: #ff9800;
}
.badge.badge-danger {
    background-color: #f44336;
}
.badge.badge-rose {
    background-color: #e91e63;
}
.badge.badge-default {
    background-color: #999;
}
.badge-default[href]:focus,
.badge-default[href]:hover {
    background-color: #c1c1c1;
}
.badge-primary[href]:focus,
.badge-primary[href]:hover {
    background-color: #eec666;
}
.badge-info[href]:focus,
.badge-info[href]:hover {
    background-color: #00a5bb;
}
.badge-success[href]:focus,
.badge-success[href]:hover {
    background-color: #449d48;
}
.badge-warning[href]:focus,
.badge-warning[href]:hover {
    background-color: #e68900;
    color: #fff;
}
.badge-danger[href]:focus,
.badge-danger[href]:hover {
    background-color: #f32c1e;
}
.badge-rose[href]:focus,
.badge-rose[href]:hover {
    background-color: #d81558;
}
.alert {
    border: 0;
    border-radius: 0;
    padding: 20px 15px;
    line-height: 20px;
}
.alert b {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
}
.alert,
.alert.alert-default {
    background-color: #fff;
    color: #555;
}
.alert .alert-link,
.alert a,
.alert.alert-default .alert-link,
.alert.alert-default a {
    color: #555;
}
.alert.alert-inverse {
    background-color: #292929;
    color: #fff;
}
.alert.alert-inverse .alert-link,
.alert.alert-inverse a {
    color: #fff;
}
.alert.alert-primary {
    background-color: #a72abd;
    color: #fff;
}
.alert.alert-primary .alert-link,
.alert.alert-primary a {
    color: #fff;
}
.alert.alert-success {
    background-color: #55b559;
    color: #fff;
}
.alert.alert-success .alert-link,
.alert.alert-success a {
    color: #fff;
}
.alert.alert-info {
    background-color: #00cae3;
    color: #fff;
}
.alert.alert-info .alert-link,
.alert.alert-info a {
    color: #fff;
}
.alert.alert-warning {
    background-color: #ff9e0f;
    color: #fff;
}
.alert.alert-warning .alert-link,
.alert.alert-warning a {
    color: #fff;
}
.alert.alert-danger {
    background-color: #f55145;
    color: #fff;
}
.alert.alert-danger .alert-link,
.alert.alert-danger a {
    color: #fff;
}
.alert.alert-rose {
    background-color: #ea2c6d;
    color: #fff;
}
.alert.alert-rose .alert-link,
.alert.alert-rose a {
    color: #fff;
}
.alert-danger,
.alert-info,
.alert-success,
.alert-warning {
    color: #fff;
}
.alert-default .alert-link,
.alert-default a {
    color: rgba(0, 0, 0, 0.87);
}
.alert .alert-icon {
    display: block;
    float: left;
    margin-right: 1.071rem;
}
.alert .alert-icon i {
    margin-top: -7px;
    top: 5px;
    position: relative;
}
.alert .close {
    color: #fff;
    text-shadow: none;
    opacity: 0.9;
}
.alert .close i {
    font-size: 20px;
}
.alert .close:focus,
.alert .close:hover {
    opacity: 1;
}
.pagination > .page-item > .page-link,
.pagination > .page-item > span {
    border: 0;
    border-radius: 30px !important;
    transition: all 0.3s;
    padding: 0 11px;
    margin: 0 3px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    color: #999;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: 0 0;
    text-align: center;
}
.pagination > .page-item > .page-link:focus,
.pagination > .page-item > .page-link:hover,
.pagination > .page-item > span:focus,
.pagination > .page-item > span:hover {
    color: #999;
}
.pagination > .page-item.active > a,
.pagination > .page-item.active > span {
    color: #999;
}
.pagination > .page-item.active > a,
.pagination > .page-item.active > a:focus,
.pagination > .page-item.active > a:hover,
.pagination > .page-item.active > span,
.pagination > .page-item.active > span:focus,
.pagination > .page-item.active > span:hover {
    background-color: #eec666;
    border-color: #eec666;
    color: #fff;
    box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14), 0 1px 10px 0 rgba(156, 39, 176, 0.12), 0 2px 4px -1px rgba(156, 39, 176, 0.2);
}
.pagination.pagination-info > .page-item.active > a,
.pagination.pagination-info > .page-item.active > a:focus,
.pagination.pagination-info > .page-item.active > a:hover,
.pagination.pagination-info > .page-item.active > span,
.pagination.pagination-info > .page-item.active > span:focus,
.pagination.pagination-info > .page-item.active > span:hover {
    background-color: #00bcd4;
    border-color: #00bcd4;
    box-shadow: 0 4px 5px 0 rgba(0, 188, 212, 0.14), 0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2);
}
.pagination.pagination-success > .page-item.active > a,
.pagination.pagination-success > .page-item.active > a:focus,
.pagination.pagination-success > .page-item.active > a:hover,
.pagination.pagination-success > .page-item.active > span,
.pagination.pagination-success > .page-item.active > span:focus,
.pagination.pagination-success > .page-item.active > span:hover {
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: 0 4px 5px 0 rgba(76, 175, 80, 0.14), 0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2);
}
.pagination.pagination-warning > .page-item.active > a,
.pagination.pagination-warning > .page-item.active > a:focus,
.pagination.pagination-warning > .page-item.active > a:hover,
.pagination.pagination-warning > .page-item.active > span,
.pagination.pagination-warning > .page-item.active > span:focus,
.pagination.pagination-warning > .page-item.active > span:hover {
    background-color: #ff9800;
    border-color: #ff9800;
    box-shadow: 0 4px 5px 0 rgba(255, 152, 0, 0.14), 0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2);
}
.pagination.pagination-danger > .page-item.active > a,
.pagination.pagination-danger > .page-item.active > a:focus,
.pagination.pagination-danger > .page-item.active > a:hover,
.pagination.pagination-danger > .page-item.active > span,
.pagination.pagination-danger > .page-item.active > span:focus,
.pagination.pagination-danger > .page-item.active > span:hover {
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: 0 4px 5px 0 rgba(244, 67, 54, 0.14), 0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2);
}
.pagination .page-item .page-link:focus {
    box-shadow: none;
}
.nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
    margin-left: 5px;
}
.nav-pills.flex-column .nav-item + .nav-item {
    margin-top: 5px;
}
.nav-pills .nav-item .nav-link {
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    text-align: center;
    color: #555;
    transition: all 0.3s;
    border-radius: 30px;
    padding: 10px 15px;
}
.nav-pills .nav-item .nav-link:hover {
    background-color: rgba(200, 200, 200, 0.2);
}
.nav-pills .nav-item .nav-link.active {
    color: #fff;
    background-color: #eec666;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.nav-pills .nav-item i {
    display: block;
    font-size: 30px;
    padding: 15px 0;
}
.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
    background-color: #00bcd4;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 188, 212, 0.6);
    color: #fff;
}
.nav-pills.nav-pills-rose .nav-item .nav-link.active,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:hover {
    background-color: #e91e63;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
    color: #fff;
}
.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
    background-color: #4caf50;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(76, 175, 80, 0.6);
    color: #fff;
}
.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
    background-color: #ff9800;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(255, 152, 0, 0.6);
    color: #fff;
}
.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
    background-color: #f44336;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(244, 67, 54, 0.6);
    color: #fff;
}
.nav-pills.nav-pills-icons .nav-item .nav-link {
    border-radius: 4px;
}
.tab-space {
    padding: 20px 0 50px 0;
}
.info .icon {
    color: #999;
}
.info .icon > i {
    font-size: 3.85rem;
}
.info .info-title {
    color: #000;
    margin: 1.75rem 0 0.875rem;
}
.info p {
    color: #999;
}
.info-horizontal .icon {
    float: left;
    margin-top: 24px;
    margin-right: 10px;
}
.info-horizontal .icon > i {
    font-size: 2.25rem;
}
.info-horizontal .description {
    overflow: hidden;
}
.icon.icon-primary {
    color: #eec666;
}
.icon.icon-info {
    color: #00bcd4;
}
.icon.icon-success {
    color: #4caf50;
}
.icon.icon-warning {
    color: #ff9800;
}
.icon.icon-danger {
    color: #f44336;
}
.icon.icon-rose {
    color: #e91e63;
}
.h1,
.h2,
.h3,
.h4,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.5em;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}
.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}
html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.h1,
h1 {
    font-size: 40px;
    line-height: 1.15em;
}
.h2,
h2 {
    font-size: 2.25rem;
    line-height: 1.5em;
}
.h3,
h3 {
    font-size: 1.5625rem;
    line-height: 1.4em;
}
.h4,
h4 {
    font-size: 1.125rem;
    line-height: 1.5em;
}
.h5,
h5 {
    font-size: 1.0625rem;
    line-height: 1.55em;
    margin-bottom: 15px;
}
.h6,
h6 {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
}
p {
    font-size: 16px;
    margin: 0 0 10px;
}
b {
    font-weight: 700;
}
.small,
small {
    font-size: 75%;
    color: #777;
}
.card-title,
.footer-big h4,
.footer-big h5,
.footer-brand,
.info-title,
.media .media-heading,
.title {
    font-weight: 500;
    font-family: 'Oxanium', cursive;
}
.card-title,
.card-title a,
.footer-big h4,
.footer-big h4 a,
.footer-big h5,
.footer-big h5 a,
.footer-brand,
.footer-brand a,
.info-title,
.info-title a,
.media .media-heading,
.media .media-heading a,
.title,
.title a {
    color: #000;
    text-decoration: none;
}
h2.title {
    margin-bottom: 1rem;
}
.card-description,
.description,
.footer-big p {
    color: #999;
}
.text-warning {
    color: #ff9800 !important;
}
.text-primary {
    color: #eec666 !important;
}
.text-danger {
    color: #f44336 !important;
}
.text-success {
    color: #4caf50 !important;
}
.text-info {
    color: #00bcd4 !important;
}
.text-rose {
    color: #e91e63 !important;
}
.text-gray {
    color: #999 !important;
}
.space {
    height: 130px;
    display: block;
}
.space-110 {
    height: 110px;
    display: block;
}
.space-50 {
    height: 50px;
    display: block;
}
.space-70 {
    height: 70px;
    display: block;
}
.blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee;
}
.blockquote p {
    font-size: 1.063rem;
    font-style: italic;
}
.blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
}
.nav-tabs {
    border: 0;
    border-radius: 3px;
    padding: 0 15px;
}
.nav-tabs .nav-item .nav-link {
    color: #fff;
    border: 0;
    margin: 0;
    border-radius: 3px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 15px;
    background-color: transparent;
    transition: 0.3s background-color 0s;
}
.nav-tabs .nav-item .nav-link:hover {
    border: 0;
}
.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-item .nav-link:focus,
.nav-tabs .nav-item .nav-link:hover {
    border: 0 !important;
    color: #fff !important;
    font-weight: 500;
}
.nav-tabs .nav-item.disabled .nav-link,
.nav-tabs .nav-item.disabled .nav-link:hover {
    color: rgba(255, 255, 255, 0.5);
}
.nav-tabs .nav-item .material-icons {
    margin: -1px 5px 0 0;
}
.nav-tabs .nav-item .nav-link.active {
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.3s background-color 0.2s;
}
.nav-tabs .nav-link {
    border-bottom: 0.214rem solid transparent;
}
.nav-tabs .nav-link {
    color: #555;
}
.nav-tabs .nav-link.active {
    color: rgba(0, 0, 0, 0.87);
    border-color: #eec666;
}
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover {
    border-color: #eec666;
}
.nav-tabs .nav-link.disabled {
    color: #999;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:focus,
.nav-tabs .nav-link.disabled:hover {
    color: #999;
}
.nav-tabs.header-primary .nav-link {
    color: #fff;
}
.nav-tabs.header-primary .nav-link.active {
    color: #fff;
    border-color: #fff;
}
.nav-tabs.header-primary .nav-link.active:focus,
.nav-tabs.header-primary .nav-link.active:hover {
    border-color: #fff;
}
.nav-tabs.header-primary .nav-link.disabled {
    color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.header-primary .nav-link.disabled,
.nav-tabs.header-primary .nav-link.disabled:focus,
.nav-tabs.header-primary .nav-link.disabled:hover {
    color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link {
    color: #fff;
}
.nav-tabs.bg-inverse .nav-link.active {
    color: #fff;
    border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.active:focus,
.nav-tabs.bg-inverse .nav-link.active:hover {
    border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.disabled {
    color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link.disabled,
.nav-tabs.bg-inverse .nav-link.disabled:focus,
.nav-tabs.bg-inverse .nav-link.disabled:hover {
    color: rgba(255, 255, 255, 0.84);
}
.card-nav-tabs {
    margin-top: 45px;
}
.card-nav-tabs .card-header {
    margin-top: -30px !important;
}
.tooltip-arrow {
    display: none;
}
.tooltip.show {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.tooltip {
    opacity: 0;
    transition: opacity, transform 0.2s ease;
    -webkit-transform: translate3d(0, 5px, 0);
    -moz-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
    font-size: 0.875rem;
}
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
    border-right-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-left .arrow::before {
    border-left-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #fff;
}
.tooltip-inner {
    padding: 10px 15px;
    min-width: 130px;
}
.popover,
.tooltip-inner {
    line-height: 1.5em;
    background: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    color: #555;
}
.popover {
    padding: 0;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.popover.bottom > .arrow,
.popover.left > .arrow,
.popover.right > .arrow,
.popover.top > .arrow {
    border: none;
}
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-left .arrow::before,
.popover.bs-popover-right .arrow::before,
.popover.bs-popover-top .arrow::before {
    border: 0;
}
.popover-header {
    background-color: #fff;
    border: none;
    padding: 15px 15px 5px;
    font-size: 1.125rem;
    margin: 0;
    color: #555;
}
.popover-body {
    padding: 10px 15px 15px;
    line-height: 1.4;
    color: #555;
}
.modal-dialog .modal-content {
	box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
	border-radius: 6px;
	border: none;
	background: #000;
	border: 1px solid #0079cd;
}
.modal-dialog .modal-content .card-signup {
    margin: 0;
}
.modal-dialog .modal-content .card-signup .modal-header {
    padding-top: 0;
}
.modal-dialog .modal-header {
    border-bottom: none;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px;
    color: #fff;
}
.modal-dialog .modal-body {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    color: #fff;
}
.modal-dialog .modal-footer {
    border-top: none;
    padding: 24px;
}
.modal-dialog .modal-footer.text-center {
    text-align: center;
}
.modal-dialog .modal-footer button {
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
}
.modal-dialog .modal-footer button.pull-left {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    left: -5px;
}
.modal-dialog .modal-body + .modal-footer {
    padding-top: 0;
}
.modal-backdrop {
    background: rgba(0, 0, 0, 0.6);
}
.modal .modal-header .close {
    color: #999;
}
.modal .modal-header .close:focus,
.modal .modal-header .close:hover {
    opacity: 1;
}
.modal .modal-header .close i {
    font-size: 16px;
}
.dropdown:after {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
.dropdown.show > .dropdown-toggle:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.dropdown-menu {
    display: none;
    padding: 0.3125rem 0;
    border: 0;
    opacity: 0;
    transform: scale(0);
    transform-origin: 0 0;
    will-change: transform, opacity;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu.showing {
    animation-name: bmd-dropdown-animation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.dropdown-menu.show,
.open > .dropdown-menu {
    display: block;
    opacity: 1;
    transform: scale(1);
}
.dropdown-menu.hiding {
    display: block;
    opacity: 0;
    transform: scale(0);
}
.dropdown-menu[x-placement="bottom-start"] {
    transform-origin: 0 0;
}
.dropdown-menu[x-placement="bottom-end"] {
    transform-origin: 100% 0;
}
.dropdown-menu[x-placement="top-start"] {
    transform-origin: 0 100%;
}
.dropdown-menu[x-placement="top-end"] {
    transform-origin: 100% 100%;
}
.dropdown-menu .disabled > a {
    color: #777;
}
.dropdown-menu .disabled > a:focus,
.dropdown-menu .disabled > a:hover {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    color: #777;
}
.dropdown-menu.dropdown-with-icons .dropdown-item {
    padding: 0.75rem 1.25rem 0.75rem 0.75rem;
}
.dropdown-menu.dropdown-with-icons .dropdown-item .material-icons {
    vertical-align: middle;
    font-size: 24px;
    position: relative;
    margin-top: -4px;
    top: 1px;
    margin-right: 12px;
    opacity: 0.5;
}
.dropdown-menu .dropdown-item,
.dropdown-menu li > a {
    position: relative;
    width: auto;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    color: #333;
    font-weight: 400;
    text-decoration: none;
    font-size: 0.8125rem;
    border-radius: 0.125rem;
    margin: 0 0.3125rem;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    min-width: 7rem;
    padding: 0.625rem 1.25rem;
    overflow: hidden;
    line-height: 1.428571;
    text-overflow: ellipsis;
    word-wrap: break-word;
}
@media (min-width: 768px) {
    .dropdown-menu .dropdown-item,
    .dropdown-menu li > a {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu a.active,
.dropdown-menu a:active,
.dropdown-menu a:focus,
.dropdown-menu a:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
    background-color: #eec666;
    color: #fff !important;
}
.dropdown-toggle.bmd-btn-fab::after,
.dropdown-toggle.bmd-btn-icon::after {
    display: none;
}
.dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-right,
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-right {
    bottom: 2rem;
}
.dropdown-toggle:after {
    will-change: transform;
    transition: transform 150ms linear;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
.dropdown-toggle.open:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-right {
    bottom: 2.5rem;
}
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu {
    margin: 0;
}
.dropdown-header {
    font-size: 0.75rem;
    padding-top: 0.1875rem;
    padding-bottom: 0.1875rem;
    text-transform: none;
    color: #777;
    line-height: 1.428571;
    font-weight: inherit;
}
@keyframes bmd-dropdown-animation {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
.dropdown-menu.bootstrap-datetimepicker-widget {
    opacity: 0;
    transform: scale(0);
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: 0 0;
    will-change: transform, opacity;
    top: 0;
}
.dropdown-menu.bootstrap-datetimepicker-widget.top {
    transform-origin: 0 100%;
}
.dropdown-menu.bootstrap-datetimepicker-widget.open {
    opacity: 1;
    transform: scale(1);
    top: 0;
}
.bmd-layout-drawer {
    position: absolute;
    z-index: 5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 100%;
    overflow: visible;
    overflow-y: auto;
    font-size: 0.875rem;
    transition: transform;
    will-change: transform;
    transform-style: preserve-3d;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-drawer > * {
    flex-shrink: 0;
}
.bmd-layout-drawer > header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
}
.bmd-layout-drawer > header .navbar-brand {
    padding-left: 1.5rem;
    font-size: 1.25rem;
}
.bmd-layout-drawer > .list-group {
    padding-top: 1rem;
}
.bmd-layout-drawer > .list-group .list-group-item {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    font-size: 0.8125rem;
    font-weight: 500;
}
.bmd-drawer-f-l > .bmd-layout-drawer {
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    transform: translateX(-250px);
}
.bmd-drawer-f-l > .bmd-layout-content,
.bmd-drawer-f-l > .bmd-layout-header {
    margin-left: 0;
}
.bmd-drawer-f-r > .bmd-layout-drawer {
    top: 0;
    right: 0;
    width: 240px;
    height: 100%;
    transform: translateX(250px);
}
.bmd-drawer-f-r > .bmd-layout-content,
.bmd-drawer-f-r > .bmd-layout-header {
    margin-right: 0;
}
.bmd-drawer-f-t > .bmd-layout-drawer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    transform: translateY(-110px);
}
.bmd-drawer-f-t > .bmd-layout-content {
    margin-top: 0;
}
.bmd-drawer-f-b > .bmd-layout-drawer {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    transform: translateY(110px);
}
.bmd-drawer-f-b > .bmd-layout-content {
    margin-bottom: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l > .bmd-layout-drawer {
    transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l > .bmd-layout-content {
    margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r > .bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r > .bmd-layout-drawer {
    transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r > .bmd-layout-content {
    margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t > .bmd-layout-header {
    margin-top: 100px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t > .bmd-layout-drawer {
    transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b > .bmd-layout-drawer {
    transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b > .bmd-layout-content {
    margin-bottom: 100px;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay > .bmd-layout-backdrop.in {
        pointer-events: auto;
        opacity: 1;
    }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-l > .bmd-layout-content,
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-l > .bmd-layout-header {
    width: 100%;
    margin-left: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-r > .bmd-layout-content,
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-r > .bmd-layout-header {
    width: 100%;
    margin-right: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay > .bmd-layout-backdrop.in {
        pointer-events: auto;
        opacity: 1;
    }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-t > .bmd-layout-header {
    margin-top: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-b > .bmd-layout-content {
    margin-bottom: 0;
}
@media (min-width: 576px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l > .bmd-layout-content {
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-right: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r > .bmd-layout-content {
        margin-right: 240px;
    }
}
@media (min-width: 576px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-t > .bmd-layout-header {
        margin-top: 100px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-t > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-b > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-b > .bmd-layout-content {
        margin-bottom: 100px;
    }
}
@media (max-width: 767.98px) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down > .bmd-layout-backdrop .in {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
    }
    @supports (pointer-events: auto) {
        :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down > .bmd-layout-backdrop.in {
            pointer-events: auto;
            opacity: 1;
        }
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-l > .bmd-layout-content,
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-l > .bmd-layout-header {
        width: 100%;
        margin-left: 0;
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-r > .bmd-layout-content,
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-r > .bmd-layout-header {
        width: 100%;
        margin-right: 0;
    }
}
@media (max-width: 767.98px) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down > .bmd-layout-backdrop .in {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
    }
    @supports (pointer-events: auto) {
        :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down > .bmd-layout-backdrop.in {
            pointer-events: auto;
            opacity: 1;
        }
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-t > .bmd-layout-header {
        margin-top: 0;
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-b > .bmd-layout-content {
        margin-bottom: 0;
    }
}
@media (min-width: 768px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l > .bmd-layout-content {
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-right: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r > .bmd-layout-content {
        margin-right: 240px;
    }
}
@media (min-width: 768px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-t > .bmd-layout-header {
        margin-top: 100px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-t > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-b > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-b > .bmd-layout-content {
        margin-bottom: 100px;
    }
}
@media (max-width: 991.98px) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down > .bmd-layout-backdrop .in {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
    }
    @supports (pointer-events: auto) {
        :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down > .bmd-layout-backdrop.in {
            pointer-events: auto;
            opacity: 1;
        }
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-l > .bmd-layout-content,
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-l > .bmd-layout-header {
        width: 100%;
        margin-left: 0;
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-r > .bmd-layout-content,
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-r > .bmd-layout-header {
        width: 100%;
        margin-right: 0;
    }
}
@media (max-width: 991.98px) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down > .bmd-layout-backdrop .in {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
    }
    @supports (pointer-events: auto) {
        :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down > .bmd-layout-backdrop.in {
            pointer-events: auto;
            opacity: 1;
        }
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-t > .bmd-layout-header {
        margin-top: 0;
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-b > .bmd-layout-content {
        margin-bottom: 0;
    }
}
@media (min-width: 992px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l > .bmd-layout-content {
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-right: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r > .bmd-layout-content {
        margin-right: 240px;
    }
}
@media (min-width: 992px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-t > .bmd-layout-header {
        margin-top: 100px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-t > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-b > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-b > .bmd-layout-content {
        margin-bottom: 100px;
    }
}
@media (max-width: 1199.98px) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down > .bmd-layout-backdrop .in {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
    }
    @supports (pointer-events: auto) {
        :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down > .bmd-layout-backdrop.in {
            pointer-events: auto;
            opacity: 1;
        }
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-l > .bmd-layout-content,
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-l > .bmd-layout-header {
        width: 100%;
        margin-left: 0;
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-r > .bmd-layout-content,
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-r > .bmd-layout-header {
        width: 100%;
        margin-right: 0;
    }
}
@media (max-width: 1199.98px) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down > .bmd-layout-backdrop .in {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
    }
    @supports (pointer-events: auto) {
        :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down > .bmd-layout-backdrop.in {
            pointer-events: auto;
            opacity: 1;
        }
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-t > .bmd-layout-header {
        margin-top: 0;
    }
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-b > .bmd-layout-content {
        margin-bottom: 0;
    }
}
@media (min-width: 1200px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l > .bmd-layout-content {
        margin-left: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r > .bmd-layout-header {
        width: calc(100% - 240px);
        margin-right: 240px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r > .bmd-layout-drawer {
        transform: translateX(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r > .bmd-layout-content {
        margin-right: 240px;
    }
}
@media (min-width: 1200px) {
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-t > .bmd-layout-header {
        margin-top: 100px;
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-t > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-b > .bmd-layout-drawer {
        transform: translateY(0);
    }
    :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-b > .bmd-layout-content {
        margin-bottom: 100px;
    }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop.in {
        pointer-events: auto;
        opacity: 1;
    }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-l > .bmd-layout-content,
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-l > .bmd-layout-header {
    width: 100%;
    margin-left: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-r > .bmd-layout-content,
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-r > .bmd-layout-header {
    width: 100%;
    margin-right: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down > .bmd-layout-backdrop.in {
        pointer-events: auto;
        opacity: 1;
    }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-t > .bmd-layout-header {
    margin-top: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-b > .bmd-layout-content {
    margin-bottom: 0;
}
.progress {
    height: 4px;
    border-radius: 0;
    box-shadow: none;
    background: #ddd;
    margin-bottom: 20px;
}
.progress .progress-bar {
    box-shadow: none;
}
.progress .progress-bar.progress-bar-primary {
    background: #eec666 !important;
}
.progress .progress-bar.progress-bar-info {
    background: #00bcd4;
}
.progress .progress-bar.progress-bar-success {
    background: #4caf50;
}
.progress .progress-bar.progress-bar-warning {
    background: #ff9800;
}
.progress .progress-bar.progress-bar-danger {
    background: #f44336;
}
.progress.progress-line-primary {
    background: rgba(156, 39, 176, 0.2);
}
.progress.progress-line-info {
    background: rgba(0, 188, 212, 0.2);
}
.progress.progress-line-success {
    background: rgba(76, 175, 80, 0.2);
}
.progress.progress-line-warning {
    background: rgba(255, 152, 0, 0.2);
}
.progress.progress-line-danger {
    background: rgba(244, 67, 54, 0.2);
}
.togglebutton {
    vertical-align: middle;
}
.togglebutton,
.togglebutton .toggle,
.togglebutton input,
.togglebutton label {
    user-select: none;
}
.togglebutton label {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label {
    color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label:focus,
.form-group.is-focused .togglebutton label:hover {
    color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .togglebutton label {
    color: rgba(0, 0, 0, 0.26);
}
.togglebutton label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}
.togglebutton label .toggle {
    text-align: left;
    margin-left: 5px;
}
.togglebutton label .toggle,
.togglebutton label input[type="checkbox"][disabled] + .toggle {
    content: "";
    display: inline-block;
    width: 30px;
    height: 15px;
    background-color: rgba(80, 80, 80, 0.7);
    border-radius: 15px;
    margin-right: 15px;
    transition: background 0.3s ease;
    vertical-align: middle;
}
.togglebutton label .toggle:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -5px;
    top: -2.5px;
    border: 1px solid rgba(0, 0, 0, 0.54);
    transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type="checkbox"][disabled] + .toggle:after,
.togglebutton label input[type="checkbox"][disabled]:checked + .toggle:after {
    background-color: #bdbdbd;
}
.togglebutton label input[type="checkbox"] + .toggle:active:after,
.togglebutton label input[type="checkbox"][disabled] + .toggle:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type="checkbox"]:checked + .toggle:after {
    left: 15px;
}
.togglebutton label input[type="checkbox"]:checked + .toggle {
    background-color: rgba(156, 39, 176, 0.7);
}
.togglebutton label input[type="checkbox"]:checked + .toggle:after {
    border-color: #eec666;
}
.togglebutton label input[type="checkbox"]:checked + .toggle:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 39, 176, 0.1);
}
.ripple {
    position: relative;
}
.ripple-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    border-radius: inherit;
}
.ripple-container .ripple-decorator {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 100%;
    opacity: 0;
    transform: scale(1);
    transform-origin: 50%;
}
.ripple-container .ripple-decorator.ripple-on {
    opacity: 0.1;
    transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}
.ripple-container .ripple-decorator.ripple-out {
    opacity: 0;
    transition: opacity 0.1s linear 0s !important;
}
.page-header {
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
}
.page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
}
.page-header .carousel .carousel-indicators {
    bottom: 60px;
}
.page-header > .container {
    color: #fff;
}
.page-header .iframe-container iframe {
    width: 100%;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.header-filter {
    position: relative;
    overflow: hidden;
    background: url(../src/assets/images/banner.png) #0d0d0d no-repeat;
    background-position: center;
    z-index: 10;
    background-size: cover;
    /* height: 100vh; */
}
.header-filter:after,
.header-filter:before {
    position: absolute;
    z-index: -1;
    display: block;
    content: "";
}
.header-filter::before {
    /* background-image: url(../src/assets/images/login_top.png); */
    background-position: right top;
    width: 478px;
    height: 329px;
    right: 0;
    top: 80px;
}
.header-filter::after {
    /* background-image: url(../src/assets/images/login_bottom.png); */
    background-position: left bottom;
    width: 479px;
    height: 330px;
    left: 0;
    bottom: -10px;
}
.header-filter .container {
    z-index: 2;
    position: relative;
}
.clear-filter::before {
    background: 0 0;
}
.purple-filter:after {
    background: rgba(101, 47, 142, 0.64);
    background: linear-gradient(45deg, rgba(101, 47, 142, 0.88) 0, rgba(125, 46, 185, 0.45) 100%);
    background: -moz-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0, rgba(125, 46, 185, 0.45) 100%);
    background: -webkit-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0, rgba(125, 46, 185, 0.45) 100%);
}
footer {
    padding: 0.9375rem 0;
    display: -webkit-flex;
    display: flex;
    text-align: left !important;
}
footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}
footer ul li {
    display: inline-block;
}
footer ul li a {
    color: inherit;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
}
footer ul li a:hover {
    text-decoration: none;
}
footer ul li .btn {
    margin: 0;
}
footer ul.links-horizontal:first-child a {
    padding-left: 0;
}
footer ul.links-horizontal:last-child a {
    padding-right: 0;
}
footer ul.links-vertical li {
    display: block;
    margin-left: -5px;
    margin-right: -5px;
}
footer ul.links-vertical li a {
    padding: 5px;
}
footer .social-buttons .btn,
footer .social-buttons a {
    margin-top: 5px;
    margin-bottom: 5px;
}
footer .footer-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    margin-left: -15px;
}
footer .footer-brand:focus,
footer .footer-brand:hover {
    color: #000;
}
footer .copyright {
    padding: 15px 0;
}
footer .copyright .material-icons {
    font-size: 18px;
    position: relative;
    top: 3px;
}
footer .pull-center {
    display: inline-block;
    float: none;
}
.card {
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card .card-category:not([class*="text-"]) {
    color: #999;
}
.card .card-category {
    margin-top: 10px;
}
.card .card-category .material-icons {
    position: relative;
    top: 8px;
    line-height: 0;
}
.card .form-check {
    margin-top: 15px;
}
.card .card-title {
    margin-top: 0.625rem;
}
.card .card-title:last-child {
    margin-bottom: 0;
}
.card.no-shadow .card-header-image,
.card.no-shadow .card-header-image img {
    box-shadow: none !important;
}
.card .card-body,
.card .card-footer {
    padding: 0.9375rem 1.875rem;
}
.card .card-body + .card-footer {
    padding-top: 0;
    border: 0;
    border-radius: 6px;
}
.card .card-footer {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
}
.card .card-footer .author,
.card .card-footer .stats {
    display: inline-flex;
}
.card .card-footer .stats {
    color: #999;
}
.card .card-footer .stats .material-icons {
    position: relative;
    top: 3px;
    margin-right: 3px;
    margin-left: 3px;
    font-size: 18px;
}
.card.bmd-card-raised {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
    .card.bmd-card-flat {
        box-shadow: none;
    }
}
.card .card-header {
    border-radius: 3px;
    padding: 1rem 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border: 0;
    background: linear-gradient(60deg, #eee, #bdbdbd);
}
.card .card-header .title {
    color: #fff;
}
.card .card-header:not([class*="header-"]) {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header .nav-tabs {
    padding: 0;
}
.card .card-header.card-header-image {
    position: relative;
    padding: 0;
    z-index: 1;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px;
}
.card .card-header.card-header-image a {
    display: block;
}
.card .card-header.card-header-image img {
    width: 100%;
    border-radius: 6px;
    pointer-events: none;
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image .card-title {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #fff;
    font-size: 1.125rem;
    text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}
.card .card-header.card-header-image .colored-shadow {
    transform: scale(0.94);
    top: 12px;
    filter: blur(12px);
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
    transition: opacity 0.45s;
    opacity: 0;
}
.card .card-header.card-header-image.no-shadow {
    box-shadow: none;
}
.card .card-header.card-header-image.no-shadow.shadow-normal {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image.no-shadow .colored-shadow {
    display: none !important;
}
.card .card-header-primary,
.card.bg-primary,
.card.card-rotate.bg-primary .back,
.card.card-rotate.bg-primary .front {
    background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}
.card .card-header-info,
.card.bg-info,
.card.card-rotate.bg-info .back,
.card.card-rotate.bg-info .front {
    background: linear-gradient(60deg, #26c6da, #0097a7);
}
.card .card-header-success,
.card.bg-success,
.card.card-rotate.bg-success .back,
.card.card-rotate.bg-success .front {
    background: linear-gradient(60deg, #66bb6a, #388e3c);
}
.card .card-header-warning,
.card.bg-warning,
.card.card-rotate.bg-warning .back,
.card.card-rotate.bg-warning .front {
    background: linear-gradient(60deg, #ffa726, #f57c00);
}
.card .card-header-danger,
.card.bg-danger,
.card.card-rotate.bg-danger .back,
.card.card-rotate.bg-danger .front {
    background: linear-gradient(60deg, #ef5350, #d32f2f);
}
.card .card-header-rose,
.card.bg-rose,
.card.card-rotate.bg-rose .back,
.card.card-rotate.bg-rose .front {
    background: linear-gradient(60deg, #ec407a, #c2185b);
}
.card .card-header-primary {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.card .card-header-danger {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(244, 67, 54, 0.6);
}
.card .card-header-rose {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
}
.card .card-header-warning {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(255, 152, 0, 0.6);
}
.card .card-header-info {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 188, 212, 0.6);
}
.card .card-header-success {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(76, 175, 80, 0.6);
}
.card [class*="header-"],
.card[class*="bg-"] {
    color: #fff;
}
.card [class*="header-"] .card-title,
.card [class*="header-"] .card-title a,
.card [class*="header-"] .icon i,
.card[class*="bg-"] .card-title,
.card[class*="bg-"] .card-title a,
.card[class*="bg-"] .icon i {
    color: #fff;
}
.card [class*="header-"] .icon i,
.card[class*="bg-"] .icon i {
    border-color: rgba(255, 255, 255, 0.25);
}
.card [class*="header-"] .author a,
.card [class*="header-"] .card-category,
.card [class*="header-"] .card-description,
.card [class*="header-"] .stats,
.card[class*="bg-"] .author a,
.card[class*="bg-"] .card-category,
.card[class*="bg-"] .card-description,
.card[class*="bg-"] .stats {
    color: rgba(255, 255, 255, 0.8);
}
.card [class*="header-"] .author a:active,
.card [class*="header-"] .author a:focus,
.card [class*="header-"] .author a:hover,
.card[class*="bg-"] .author a:active,
.card[class*="bg-"] .author a:focus,
.card[class*="bg-"] .author a:hover {
    color: #fff;
}
.card .author .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
}
.card .author a {
    color: #000;
    text-decoration: none;
}
.card .author a .ripple-container {
    display: none;
}
.card .card-category-social .fa {
    font-size: 24px;
    position: relative;
    margin-top: -4px;
    top: 2px;
    margin-right: 5px;
}
.card .card-category-social .material-icons {
    position: relative;
    top: 5px;
}
.card[class*="bg-"],
.card[class*="bg-"] .card-body {
    border-radius: 6px;
}
.card[class*="bg-"] .card-body h1 small,
.card[class*="bg-"] .card-body h2 small,
.card[class*="bg-"] .card-body h3 small,
.card[class*="bg-"] h1 small,
.card[class*="bg-"] h2 small,
.card[class*="bg-"] h3 small {
    color: rgba(255, 255, 255, 0.8);
}
.card .card-stats {
    background: 0 0;
    display: flex;
}
.card .card-stats .author,
.card .card-stats .stats {
    display: inline-flex;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .card {
        display: inline-block;
    }
}
.card-carousel {
    overflow: hidden;
}
.card-login .card-header {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -40px;
    padding: 20px 0;
    margin-bottom: 15px;
}
.card-login .text-divider {
    margin-top: 30px;
    margin-bottom: 0;
    text-align: center;
}
.card-login .card-body {
    padding: 0 30px 0 10px;
}
.card-login .form-check {
    padding-top: 27px;
}
.card-login .form-check label {
    margin-left: 18px;
}
.card-login .form-check .form-check-sign {
    padding-right: 27px;
}
.card-login .input-group {
    padding-bottom: 7px;
    margin: 27px 0 0 0;
}
.card-login .form {
    min-height: 420px;
}
.card-login .social-line {
    margin-top: 1rem;
    text-align: center;
    padding: 0;
}
.card-login .social-line .btn {
    color: #fff;
    margin-left: 5px;
    margin-right: 5px;
}
.login-page .footer {
    position: absolute;
    width: 100%;
    background: 0 0;
    bottom: 0;
    color: #fff;
    z-index: 2;
}
.login-page .footer .copyright a {
    color: #fff;
}
.card-plain {
    background: 0 0;
    box-shadow: none;
}
.card-plain .card-header:not(.card-avatar) {
    margin-left: 0;
    margin-right: 0;
}
.card-plain .card-body {
    padding-left: 5px;
    padding-right: 5px;
}
.card-plain .card-header-image {
    margin: 0 !important;
    border-radius: 6px;
}
.card-plain .card-header-image img {
    border-radius: 6px;
}
.card-plain .card-footer {
    padding-left: 5px;
    padding-right: 5px;
    background-color: transparent;
} /*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.17.37
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.next::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.bootstrap-datetimepicker-widget {
    list-style: none;
}
.bootstrap-datetimepicker-widget a:hover {
    box-shadow: none !important;
}
.bootstrap-datetimepicker-widget a .btn:hover {
    background-color: transparent;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
    padding: 4px;
    width: 19em;
}
@media (min-width: 768px) {
    .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em;
    }
}
@media (min-width: 991px) {
    .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em;
    }
}
@media (min-width: 1200px) {
    .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
        width: 38em;
    }
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after,
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
    right: auto;
    left: 12px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top {
    margin-top: auto;
    margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top.open {
    margin-top: auto;
    margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
    left: auto;
    right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
    left: auto;
    right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
    margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
    padding: 0;
    margin: 0;
    border-width: 0;
    background-color: transparent;
    color: #eec666;
    box-shadow: none;
}
.bootstrap-datetimepicker-widget a[data-action]:hover {
    background-color: transparent;
}
.bootstrap-datetimepicker-widget a[data-action]:hover span {
    background-color: #eee;
    color: #eec666;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
    box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: 300;
    font-size: 1.125rem;
    margin: 0;
    border-radius: 50%;
}
.bootstrap-datetimepicker-widget button[data-action] {
    width: 38px;
    height: 38px;
    margin-right: 3px;
    padding: 0;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
    content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
    content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
    content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
    content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
    content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
    content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
    content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
    content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
    content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
    text-align: center;
    border-radius: 3px;
    font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
    content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
    line-height: 2.5;
    height: 2.5em;
    width: 100%;
    border-radius: 3px;
    margin: 2px 0 !important;
}
.bootstrap-datetimepicker-widget table {
    width: 100%;
    margin: 0;
}
.bootstrap-datetimepicker-widget table.table-condensed tr > td {
    text-align: center;
}
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th > div {
    text-align: center;
}
.bootstrap-datetimepicker-widget table th {
    height: 20px;
    line-height: 20px;
    width: 20px;
    font-weight: 500;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
    width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
    background: 0 0;
    color: rgba(0, 0, 0, 0.12);
    cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.next span,
.bootstrap-datetimepicker-widget table th.prev span {
    border-radius: 3px;
    height: 27px;
    width: 27px;
    line-height: 28px;
    font-size: 12px;
    border-radius: 50%;
    text-align: center;
}
.bootstrap-datetimepicker-widget table th.prev::after {
    content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
    content: "Next Month";
}
.bootstrap-datetimepicker-widget table th.dow {
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 10px;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
    cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch:hover,
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span {
    background: #eee;
}
.bootstrap-datetimepicker-widget table td > div {
    border-radius: 3px;
    height: 54px;
    line-height: 54px;
    width: 54px;
    text-align: center;
}
.bootstrap-datetimepicker-widget table td.cw > div {
    font-size: 0.8em;
    height: 20px;
    line-height: 20px;
    color: #999;
}
.bootstrap-datetimepicker-widget table td.day > div {
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    padding: 0;
    border-radius: 50%;
    position: relative;
    z-index: -1;
    color: #000;
    font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget table td.hour > div,
.bootstrap-datetimepicker-widget table td.minute > div {
    border-radius: 50%;
}
.bootstrap-datetimepicker-widget table td.day:hover > div,
.bootstrap-datetimepicker-widget table td.hour:hover > div,
.bootstrap-datetimepicker-widget table td.minute:hover > div,
.bootstrap-datetimepicker-widget table td.second:hover > div {
    background: #eee;
    cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.new > div,
.bootstrap-datetimepicker-widget table td.old > div {
    color: #999;
}
.bootstrap-datetimepicker-widget table td.today > div {
    position: relative;
}
.bootstrap-datetimepicker-widget table td.active:hover > div,
.bootstrap-datetimepicker-widget table td.active > div {
    background-color: #eec666;
    color: #fff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
}
.bootstrap-datetimepicker-widget table td.active.today:before > div {
    border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget table td.disabled:hover > div,
.bootstrap-datetimepicker-widget table td.disabled > div {
    background: 0 0;
    color: rgba(0, 0, 0, 0.12);
    cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
}
.bootstrap-datetimepicker-widget table td span:hover {
    background: #eee;
}
.bootstrap-datetimepicker-widget table td span.active {
    background-color: #eec666;
    color: #fff;
}
.bootstrap-datetimepicker-widget table td span.old {
    color: #999;
}
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
    background: 0 0;
    color: rgba(0, 0, 0, 0.12);
    cursor: not-allowed;
}
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span,
.bootstrap-datetimepicker-widget .timepicker-picker span {
    border-radius: 50% !important;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
    height: 27px;
    line-height: 27px;
}
.input-group.date .input-group-addon {
    cursor: pointer;
}
.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
    padding: 1px;
    text-align: center;
    z-index: 1;
    cursor: pointer;
} /*! nouislider - 14.1.1  */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.noUi-target {
    position: relative;
}
.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.noUi-connects {
    overflow: hidden;
    z-index: 0;
}
.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
}
.noUi-connect {
    height: 100%;
    width: 100%;
}
.noUi-origin {
    height: 10%;
    width: 10%;
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    left: auto;
    right: 0;
}
.noUi-vertical .noUi-origin {
    width: 0;
}
.noUi-horizontal .noUi-origin {
    height: 0;
}
.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}
.noUi-touch-area {
    height: 100%;
    width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}
.noUi-state-drag * {
    cursor: inherit !important;
}
.noUi-horizontal {
    height: 2px;
    margin: 15px 0;
}
.noUi-horizontal .noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: 100%;
    top: -7px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #eec666;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-vertical {
    height: 100%;
    width: 2px;
    margin: 0 15px;
}
.noUi-vertical .noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -8px;
    top: -10px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #eec666;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    right: -10px;
    left: auto;
}
.noUi-target {
    background: #c8c8c8;
    border-radius: 4px;
}
.noUi-connects {
    border-radius: 3px;
}
.noUi-connect {
    background: #3fb8af;
}
.noUi-draggable {
    cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}
.noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -10px;
    top: -6px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-active {
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
    transform: scale3d(1.5, 1.5, 1);
}
.noUi-handle:after {
    left: 17px;
}
.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}
.noUi-vertical .noUi-handle:after {
    top: 17px;
}
[disabled] .noUi-connect {
    background: #b8b8b8;
}
[disabled] .noUi-handle,
[disabled].noUi-handle,
[disabled].noUi-target {
    cursor: not-allowed;
}
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.noUi-pips {
    position: absolute;
    color: #999;
}
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}
.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}
.noUi-marker {
    position: absolute;
    background: #ccc;
}
.noUi-marker-sub {
    background: #aaa;
}
.noUi-marker-large {
    background: #aaa;
}
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}
.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}
.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}
.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}
.slider {
    background: #c8c8c8;
}
.slider .noUi-connect {
    background-color: #eec666;
    border-radius: 4px;
}
.slider .noUi-handle {
    border-color: #eec666;
}
.slider.slider-info .noUi-connect {
    background-color: #00bcd4;
}
.slider.slider-info .noUi-handle {
    border-color: #00bcd4;
}
.slider.slider-success .noUi-connect {
    background-color: #4caf50;
}
.slider.slider-success .noUi-handle {
    border-color: #4caf50;
}
.slider.slider-warning .noUi-connect {
    background-color: #ff9800;
}
.slider.slider-warning .noUi-handle {
    border-color: #ff9800;
}
.slider.slider-danger .noUi-connect {
    background-color: #f44336;
}
.slider.slider-danger .noUi-handle {
    border-color: #f44336;
}
.slider.slider-rose .noUi-connect {
    background-color: #e91e63;
}
.slider.slider-rose .noUi-handle {
    border-color: #e91e63;
}
.index-page .section-components h6.description {
    margin-top: 5px;
    margin-bottom: 30px;
}
.index-page .page-header {
    overflow: hidden;
}
.index-page .brand {
    color: #fff;
    text-align: center;
}
.index-page .brand h1 {
    font-size: 4.2rem;
    font-weight: 600;
    display: inline-block;
    position: relative;
}
.index-page .brand h3 {
    font-size: 1.313rem;
    max-width: 500px;
    margin: 10px auto 0;
}
.index-page h4.description {
    font-size: 1.5em;
}
.section {
    padding: 80px 0;
}
.profile-page .page-header {
    height: 380px;
    background-position: top center;
}
.profile-page .profile {
    text-align: center;
}
.profile-page .profile img {
    max-width: 160px;
    width: 100%;
    margin: 0 auto;
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    -o-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
}
.profile-page .profile .name {
    margin-top: -80px;
}
.profile-page .follow {
    position: absolute;
    top: 0;
    right: 0;
}
.profile-page .description {
    margin: 1.071rem auto 0;
    max-width: 600px;
}
.profile-page .profile-tabs {
    margin-top: 4.284rem;
}
.profile-page .gallery {
    margin-top: 3.213rem;
    padding-bottom: 50px;
}
.profile-page .gallery img {
    width: 100%;
    margin-bottom: 2.142rem;
}
.profile-page .follow .btn-fab {
    margin-top: -28px;
}
.profile-page .card-background .content {
    padding: 30% 0;
    min-width: 160px;
}
.profile-page .work {
    padding: 40px 0;
}
.profile-page .work .collections {
    margin-top: 20px;
}
.profile-page .work .stats ul > li {
    padding: 5px 0;
    font-size: 1em;
}
.profile-page .work .stats ul > li b {
    font-size: 1.2em;
}
.profile-page .connections {
    padding: 40px 0;
}
.profile-page .connections .card-profile {
    text-align: left;
}
.profile-page .tab-content .collections .card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.profile-page .tab-content .collections .card .card-body .badge {
    display: inline-table;
    margin: 0 auto;
}
.signup-page .page-header {
    min-height: 100vh;
    height: auto;
    display: inherit;
}
.signup-page .page-header .container {
    padding-top: 20vh;
}
.signup-page .card-signup {
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin-bottom: 100px;
    padding: 40px 0;
}
.signup-page .info-horizontal {
    padding: 0 0 20px;
}
.signup-page .social .btn {
    margin: 5px;
}
.signup-page .social h4 {
    margin-top: 20px;
}
.signup-page .footer .container {
    padding: 0;
}
.signup-page .footer .copyright,
.signup-page .footer a {
    color: #fff;
}
@media all and (max-width: 991px) {
    [class*="navbar-expand-"] > .container,
    [class*="navbar-expand-"] > .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
    .dropdown-menu.show .dropdown-item.open + .dropdown-menu.show {
        right: 101% !important;
    }
    .dropdown-menu.show .dropdown-item.open + .dropdown-menu.show .dropdown-item.open + .dropdown-menu,
    .dropdown-menu.show .dropdown-item.open + .dropdown-menu.show .dropdown-item.open + .dropdown-menu.show {
        left: -165px !important;
    }
    .navbar .navbar-collapse .navbar-nav > li.button-container {
        padding: 15px;
    }
    .carousel .card .card-body {
        max-width: 340px;
        margin: 0 auto;
        min-height: 400px;
    }
    .navbar-collapse {
        position: fixed;
        display: block;
        top: 0;
        height: 100vh;
        width: 230px;
        right: 0;
        margin-right: 0 !important;
        z-index: 1032;
        visibility: visible;
        background-color: #999;
        overflow-y: visible;
        border-top: none;
        text-align: left;
        padding-right: 0;
        padding-left: 0;
        max-height: none !important;
        -webkit-transform: translate3d(230px, 0, 0);
        -moz-transform: translate3d(230px, 0, 0);
        -o-transform: translate3d(230px, 0, 0);
        -ms-transform: translate3d(230px, 0, 0);
        transform: translate3d(230px, 0, 0);
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .navbar-collapse::after {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #fff;
        display: block;
        content: "";
        z-index: 1;
    }
    .navbar-collapse .dropdown-toggle:after {
        position: absolute;
        right: 16px;
        margin-top: 8px;
    }
    .navbar-collapse .navbar-nav {
        position: relative;
        z-index: 3;
    }
    .navbar-collapse .navbar-nav .nav-item .nav-link {
        color: #000;
        margin: 5px 15px;
    }
    .navbar-collapse .navbar-nav .nav-item.active .nav-link,
    .navbar-collapse .navbar-nav .nav-item.active .nav-link:active,
    .navbar-collapse .navbar-nav .nav-item.active .nav-link:focus,
    .navbar-collapse .navbar-nav .nav-item.active .nav-link:hover {
        background-color: #ecedef;
        color: #000;
    }
    .navbar-collapse .navbar-nav .nav-item.button-container .nav-link {
        margin: 15px;
    }
    .navbar-collapse .navbar-nav .nav-item:after {
        width: calc(100% - 30px);
        content: "";
        display: block;
        height: 1px;
        margin-left: 15px;
        background-color: #e5e5e5;
    }
    .navbar-collapse .navbar-nav .nav-item:last-child:after {
        display: none;
    }
    .nav-open .navbar-collapse {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .nav-open .navbar-translate {
        -webkit-transform: translate3d(-230px, 0, 0);
        -moz-transform: translate3d(-230px, 0, 0);
        -o-transform: translate3d(-230px, 0, 0);
        -ms-transform: translate3d(-230px, 0, 0);
        transform: translate3d(-230px, 0, 0);
    }
    .navbar .navbar-translate {
        width: 100%;
        position: relative;
        display: flex;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .navbar .dropdown.show .dropdown-menu {
        display: block;
    }
    .navbar .dropdown .dropdown-menu {
        display: none;
    }
    .navbar .dropdown-menu .dropdown-item {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .navbar .dropdown .dropdown-menu,
    .navbar .dropdown.show .dropdown-menu {
        background-color: transparent;
        border: 0;
        padding-bottom: 15px;
        transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        transform: none !important;
        width: auto;
        margin-bottom: 15px;
        padding-top: 0;
        height: 300px;
        animation: none;
        opacity: 1;
        overflow-y: scroll;
    }
    .navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon {
        background-color: #fff;
    }
    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 0;
        top: 0;
        left: auto;
        right: 230px;
        content: "";
        z-index: 1029;
        overflow-x: hidden;
    }
    #navbar .navbar-collapse,
    #navigation .navbar-collapse {
        display: none !important;
    }
}
@media all and (min-width: 991px) {
    .navbar .navbar-nav {
        align-items: center;
    }
    .navbar .navbar-nav .button-container {
        margin-left: 0.1875px;
    }
    .contactus-2 .card-contact {
        margin: 80px 0 80px 150px;
    }
    .index-page .fileinput-new.thumbnail.img-circle {
        margin-top: 50px;
    }
}
@media screen and (max-width: 991px) {
    .presentation-page .section-components .components-macbook {
        max-width: 850px !important;
        max-height: 480px !important;
        margin-top: 12vh;
        left: -12px;
    }
    .presentation-page .section-components .coloured-card-img,
    .presentation-page .section-components .table-img {
        display: none;
    }
    .presentation-page .section-components .social-img {
        left: 47%;
        top: 37%;
    }
    .presentation-page .section-components .pin-btn-img {
        top: 54%;
    }
    .presentation-page .section-components .share-btn-img {
        top: 12%;
    }
    .presentation-page .section-components .coloured-card-btn-img {
        top: -2%;
        left: 65%;
    }
    .presentation-page .section-content .area-img {
        max-width: 130px;
        max-height: 170px;
    }
    .presentation-page .section-content .info-img {
        max-width: 170px;
        max-height: 120px;
    }
    @keyframes topbar-x {
        0% {
            top: 0;
            transform: rotate(0);
        }
        45% {
            top: 6px;
            transform: rotate(145deg);
        }
        75% {
            transform: rotate(130deg);
        }
        100% {
            transform: rotate(135deg);
        }
    }
    @-webkit-keyframes topbar-x {
        0% {
            top: 0;
            -webkit-transform: rotate(0);
        }
        45% {
            top: 6px;
            -webkit-transform: rotate(145deg);
        }
        75% {
            -webkit-transform: rotate(130deg);
        }
        100% {
            -webkit-transform: rotate(135deg);
        }
    }
    @-moz-keyframes topbar-x {
        0% {
            top: 0;
            -moz-transform: rotate(0);
        }
        45% {
            top: 6px;
            -moz-transform: rotate(145deg);
        }
        75% {
            -moz-transform: rotate(130deg);
        }
        100% {
            -moz-transform: rotate(135deg);
        }
    }
    @keyframes topbar-back {
        0% {
            top: 6px;
            transform: rotate(135deg);
        }
        45% {
            transform: rotate(-10deg);
        }
        75% {
            transform: rotate(5deg);
        }
        100% {
            top: 0;
            transform: rotate(0);
        }
    }
    @-webkit-keyframes topbar-back {
        0% {
            top: 6px;
            -webkit-transform: rotate(135deg);
        }
        45% {
            -webkit-transform: rotate(-10deg);
        }
        75% {
            -webkit-transform: rotate(5deg);
        }
        100% {
            top: 0;
            -webkit-transform: rotate(0);
        }
    }
    @-moz-keyframes topbar-back {
        0% {
            top: 6px;
            -moz-transform: rotate(135deg);
        }
        45% {
            -moz-transform: rotate(-10deg);
        }
        75% {
            -moz-transform: rotate(5deg);
        }
        100% {
            top: 0;
            -moz-transform: rotate(0);
        }
    }
    @keyframes bottombar-x {
        0% {
            bottom: 0;
            transform: rotate(0);
        }
        45% {
            bottom: 6px;
            transform: rotate(-145deg);
        }
        75% {
            transform: rotate(-130deg);
        }
        100% {
            transform: rotate(-135deg);
        }
    }
    @-webkit-keyframes bottombar-x {
        0% {
            bottom: 0;
            -webkit-transform: rotate(0);
        }
        45% {
            bottom: 6px;
            -webkit-transform: rotate(-145deg);
        }
        75% {
            -webkit-transform: rotate(-130deg);
        }
        100% {
            -webkit-transform: rotate(-135deg);
        }
    }
    @-moz-keyframes bottombar-x {
        0% {
            bottom: 0;
            -moz-transform: rotate(0);
        }
        45% {
            bottom: 6px;
            -moz-transform: rotate(-145deg);
        }
        75% {
            -moz-transform: rotate(-130deg);
        }
        100% {
            -moz-transform: rotate(-135deg);
        }
    }
    @keyframes bottombar-back {
        0% {
            bottom: 6px;
            transform: rotate(-135deg);
        }
        45% {
            transform: rotate(10deg);
        }
        75% {
            transform: rotate(-5deg);
        }
        100% {
            bottom: 0;
            transform: rotate(0);
        }
    }
    @-webkit-keyframes bottombar-back {
        0% {
            bottom: 6px;
            -webkit-transform: rotate(-135deg);
        }
        45% {
            -webkit-transform: rotate(10deg);
        }
        75% {
            -webkit-transform: rotate(-5deg);
        }
        100% {
            bottom: 0;
            -webkit-transform: rotate(0);
        }
    }
    @-moz-keyframes bottombar-back {
        0% {
            bottom: 6px;
            -moz-transform: rotate(-135deg);
        }
        45% {
            -moz-transform: rotate(10deg);
        }
        75% {
            -moz-transform: rotate(-5deg);
        }
        100% {
            bottom: 0;
            -moz-transform: rotate(0);
        }
    }
    .navbar .navbar-toggler .navbar-toggler-icon:nth-child(2) {
        top: 0;
        -webkit-animation: topbar-back 0.5s linear 0s;
        -moz-animation: topbar-back 0.5s linear 0s;
        animation: topbar-back 0.5s 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    .navbar .navbar-toggler .navbar-toggler-icon:nth-child(3) {
        opacity: 1;
    }
    .navbar .navbar-toggler .navbar-toggler-icon:nth-child(4) {
        bottom: 0;
        -webkit-animation: bottombar-back 0.5s linear 0s;
        -moz-animation: bottombar-back 0.5s linear 0s;
        animation: bottombar-back 0.5s 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(2) {
        top: 6px;
        -webkit-animation: topbar-x 0.5s linear 0s;
        -moz-animation: topbar-x 0.5s linear 0s;
        animation: topbar-x 0.5s 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(3) {
        opacity: 0;
    }
    .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(4) {
        bottom: 6px;
        -webkit-animation: bottombar-x 0.5s linear 0s;
        -moz-animation: bottombar-x 0.5s linear 0s;
        animation: bottombar-x 0.5s 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
}
@media screen and (max-width: 767px) {
    .presentation-page .section-components .components-macbook {
        max-width: 350px !important;
        max-height: 250px !important;
        margin-top: 12vh;
        left: -12px;
    }
    .presentation-page .section-components .coloured-card-img,
    .presentation-page .section-components .table-img {
        display: none;
    }
    .presentation-page .section-components .social-img {
        left: -7%;
        top: 37%;
    }
    .presentation-page .section-components .pin-btn-img {
        top: 54%;
    }
    .presentation-page .section-components .share-btn-img {
        top: 7%;
    }
    .presentation-page .section-components .coloured-card-btn-img {
        top: -2%;
    }
    .card.card-form-horizontal .input-group {
        padding-bottom: 10px;
    }
    .index-page #cd-vertical-nav,
    .presentation-page #cd-vertical-nav,
    .section-page #cd-vertical-nav {
        display: none;
    }
    .index-page .cd-section .tim-typo .tim-note {
        width: 60px;
    }
    .login-page .page-header > .container {
        padding-bottom: 200px;
    }
}
@media screen and (max-width: 400px) {
    .cd-vertical-nav {
        display: none !important;
    }
}
@media screen and (min-width: 767px) {
    .bd-docs .bd-example .card.card-profile .card-header-image {
        max-height: 100% !important;
    }
    .card-profile .card-header-image {
        max-height: 215px;
    }
}
@media screen and (max-width: 991px) {
    .menu-on-left .main,
    .menu-on-left .navbar > div,
    .menu-on-left .page-header {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .menu-on-left .navbar .collapse {
        left: 0;
        top: -25px;
        right: auto;
        -webkit-transform: translate3d(-230px, 0, 0);
        -moz-transform: translate3d(-230px, 0, 0);
        -o-transform: translate3d(-230px, 0, 0);
        -ms-transform: translate3d(-230px, 0, 0);
        transform: translate3d(-230px, 0, 0);
    }
    .menu-on-left .navbar .navbar-translate .navbar-brand {
        position: absolute;
        right: 0;
        top: -15px;
    }
    .nav-open .menu-on-left .main,
    .nav-open .menu-on-left .page-header {
        -webkit-transform: translate3d(230px, 0, 0);
        -moz-transform: translate3d(230px, 0, 0);
        -o-transform: translate3d(230px, 0, 0);
        -ms-transform: translate3d(230px, 0, 0);
        transform: translate3d(230px, 0, 0);
    }
    .nav-open .menu-on-left .navbar .collapse {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .nav-open .menu-on-left .navbar > div .navbar-translate {
        -webkit-transform: translate3d(230px, 0, 0);
        -moz-transform: translate3d(230px, 0, 0);
        -o-transform: translate3d(230px, 0, 0);
        -ms-transform: translate3d(230px, 0, 0);
        transform: translate3d(230px, 0, 0);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
}
@media (min-width: 575px) and (max-width: 991px) {
    .menu-on-left .navbar .collapse {
        -webkit-transform: translate3d(-255px, 0, 0);
        -moz-transform: translate3d(-255px, 0, 0);
        -o-transform: translate3d(-255px, 0, 0);
        -ms-transform: translate3d(-255px, 0, 0);
        transform: translate3d(-255px, 0, 0);
    }
    .nav-open .menu-on-left .navbar .collapse {
        -webkit-transform: translate3d(-25px, 0, 0);
        -moz-transform: translate3d(-25px, 0, 0);
        -o-transform: translate3d(-25px, 0, 0);
        -ms-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
        width: 245px;
    }
}
a,
ul,
li,
button {
    font-family: 'Oxanium', cursive !important; 
}
body::-webkit-scrollbar {
    width: 6px;
}
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
    background-color: #00a5e7;
}
::-webkit-scrollbar {
    width: 6px;
    height: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
    background-color: #00a5e7;
}
.mobile_nav {
	background-color: #0d445f !important;
}
.home_page_menu li::after {
    display: none;
}
.home_page_menu ul {
    display: flex;
    align-items: center;
}
.auth_panel {
    /* background: url(../src/assets/images/login_stars.png) #eec666; */
    overflow: hidden;
    position: relative;
    padding-top: 100px;
}
.auth_panel::before {
    content: "";
    /* background-image: url(../src/assets/images/login_top.png); */
    background-position: right top;
    width: 478px;
    height: 329px;
    position: absolute;
    right: 0;
    top: 80px;
}
.auth_panel::after {
    content: "";
    /* background-image: url(../src/assets/images/login_bottom.png); */
    background-position: left bottom;
    width: 479px;
    height: 330px;
    position: absolute;
    left: 0;
    bottom: -10px;
}
.ct-particle-animate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.ct-particle-animate div {
    position: absolute;
    top: 0;
    left: 0;
}
.ct-particle-animate #ct_particle_animate-a461fd71 {
    left: 7%;
    top: 18%;
}
.ct-particle-animate .shape-animate1 {
    animation: animationFramesTwo 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd72 {
    left: 25%;
    top: 10%;
}
.ct-particle-animate .shape-animate2 {
    animation: animationFramesOne 15s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd73 {
    left: 18%;
    top: 45%;
}
.ct-particle-animate #ct_particle_animate-a461fd83 {
    left: 18%;
    top: 15%;
}
.ct-particle-animate .shape-animate3 {
    animation: animationFramesOne 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd74 {
    left: 72%;
    top: 50%;
}
.ct-particle-animate #ct_particle_animate-a461fd84 {
    left: 70%;
    top: 25%;
}
.ct-particle-animate .shape-animate4 {
    animation: animationFramesFour 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd75 {
    left: 74%;
    top: 24%;
}
.ct-particle-animate .shape-animate5 {
    animation: animationFramesThree 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd77 {
    left: 20%;
    top: 70%;
}
.ct-particle-animate #ct_particle_animate-a461fd87 {
    left: 20%;
    top: 35%;
}
.ct-particle-animate .shape-left-right {
    animation: NewsletterAnimated2 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd76 {
    left: 90%;
    top: 60%;
}
.ct-particle-animate #ct_particle_animate-a461fd86 {
    left: 90%;
    top: 29%;
}
.ct-particle-animate .shape-right-left {
    animation: NewsletterAnimated1 12s infinite linear alternate;
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(111px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(93px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-70px, 72px) rotate(124deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}
@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0, 0) rotate(0);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(111px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(124deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(0);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0, 0) rotate(0) scale(1);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
    }
    100% {
        transform: translate(0, 0) rotate(0) scale(1);
    }
}
@-webkit-keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0, 0) rotate(0) scale(1);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(0) scale(1);
    }
}
@keyframes animationFramesThree {
    0% {
        transform: translate(165px, -179px);
    }
    100% {
        transform: translate(-346px, 200px);
    }
}
@-webkit-keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
    }
    100% {
        -webkit-transform: translate(-346px, 200px);
    }
}
@keyframes animationFramesFour {
    0% {
        transform: translate(-300px, 151px) rotate(0);
    }
    100% {
        transform: translate(251px, -200px) rotate(180deg);
    }
}
@-webkit-keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-300px, 151px) rotate(0);
    }
    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
    }
}
@keyframes animationFramesFive {
    0% {
        transform: translate(61px, -99px) rotate(0);
    }
    21% {
        transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        transform: translate(-1px, 0) rotate(180deg);
    }
}
@-webkit-keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0);
    }
    21% {
        -webkit-transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(-1px, 0) rotate(180deg);
    }
}
@keyframes animationFramesSix {
    0% {
        transform: translate(31px, -45px) rotate(0);
    }
    21% {
        transform: translate(4px, -80px) rotate(19deg);
    }
    41% {
        transform: translate(-70px, -100px) rotate(36deg);
    }
    60% {
        transform: translate(-133px, -84px) rotate(54deg);
    }
    80% {
        transform: translate(-98px, -24px) rotate(72deg);
    }
    100% {
        transform: translate(-1px, 0) rotate(180deg);
    }
}
@keyframes NewsletterAnimated1 {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-40px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(40px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes NewsletterAnimated2 {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(40px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(-40px);
    }
    100% {
        transform: translateX(0);
    }
}
.auth_main_div {
	background: #272727;
	border-radius: 19px;
	box-shadow: 4px 9px 17px #000;
	padding: 25px 0;
	margin-top: 20px;
}
.auth_main_div .nav-pills {
    border: 0;
    border-radius: 3px;
    border-bottom: 1px solid #1e2023;
    padding: 0 80px;
}
.auth_main_div .nav-pills .nav-item .nav-link {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 400;
    min-width: 100px;
    color: #c4c4c4;
    border-radius: 0;
    background-color: transparent;
}
.auth_main_div .nav-pills .nav-item .nav-link.active {
    color: #ffca10;
    background-color: transparent;
    box-shadow: none;
}
.auth_main_div .nav-pills .nav-item .nav-link span {
    padding: 0 15px 10px;
}
.auth_main_div .nav-pills .nav-item .nav-link.active span {
	border-bottom: 2px solid #ffca10;
}
form.auth_form {
    padding: 50px 50px 0;
}
form.auth_form .auth_label {
    display: block;
    font-size: 14px;
    color: #fff;
}
form.auth_form .auth_inp {
	width: 100%;
	border: 1px solid #199fe1;
	height: 42px;
	font-size: 14px;
	padding: 8px;
	border-radius: 0;
	background: #1e2023;
	color: #fff;
	border-radius: 7px;
	padding: 5px 8px;
	box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
	-webkit-box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
	-moz-box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
}
button.primary_btn {
     background: rgb(203,68,166);
    background: linear-gradient(77deg, rgba(211,59,24,1) 0%, rgba(22,160,226,1) 67%, rgba(4,109,194,1) 100%); 
    box-shadow: 1px 1px 6px rgb(95 102 107 / 40%), -1px -1px 6px rgb(95 102 107 / 40%);
    border-radius: 50px;
    padding: 5px 32px;
    font-size: 18px;
    color: #ffffff;
    text-transform: unset;
    font-weight: 600;
}
button.primary_btn a {
    color: #ffffff;
    text-decoration: none;
}
button.primary_btn:hover {
    background: linear-gradient(77deg, rgba(4,109,194,1) 0%, rgba(22,160,226,1) 67%, rgba(211,59,24,1) 100%);    
    color: #fff;
}
button.primary_btn:hover a {
    color: #fff;
}
.referral_reward {
  position: relative;
  z-index: 10;
}
.auth_link {
    font-size: 14px;
    color: #eec666;
    display: block;
    margin-top: 12px;
}
.auth_link:hover {
    color: #eec666;
    text-decoration: underline;
}
.auth_social_links {
    text-align: center;
}
.auth_social_links p {
    color: #c6c6c6;
    font-size: 14px;
    margin: 10px 0 0;
}
.auth_social_links ul {
    list-style: none;
    padding: 0;
}
.auth_social_links ul li {
    display: inline-block;
    margin: 8px;
}
.auth_social_links ul li a {
    background-color: #eec666;
    color: #003fca;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}
form.auth_form .custom-control-label {
    margin-bottom: 0;
    position: initial;
    vertical-align: initial;
}
.auth_form p {
    font-size: 14px;
}
.auth_form h2 {
    font-size: 22px;
    color: #f2d489;
}
.auth_panel.forgot_panel {
    padding-bottom: 50px;
}
button.menu_btn {
   /* background-image: linear-gradient(to right, #ee561a 0%, #fa875a 100%);*/
    background: rgb(203,68,166);
    background: linear-gradient(77deg, rgba(211,59,24,1) 0%, rgba(22,160,226,1) 67%, rgba(4,109,194,1) 100%); 
    border-radius: 50px;
    padding: 10px 30px;
    font-size: 18px;
    color: #fff;
    text-transform: unset;
    transition: 0.2s all;
}
button.menu_btn:hover {
    background: linear-gradient(77deg, rgba(4,109,194,1) 0%, rgba(22,160,226,1) 67%, rgba(211,59,24,1) 100%);
    color: #fff;
}
.top_navbar ul {
    display: flex;
    align-items: center;
}
.top_nav_login {
	color: #fff !important;
	font-size: 15px;
	cursor: pointer;
	font-weight: 600;
	letter-spacing: 0.2px;
}
.top_nav_login:hover {
    color: #055bb6;
}
.inner_pageheader {
    padding-top: 20px !important;
    padding-bottom: 100px !important;
}
.dash_top_bar {
    position: relative;
    overflow: hidden;
}
.dash_top_bar::before {
    content: "";
    /* background-image: url(../src/assets/images/dash_bg.png); */
    background-position: center;
    width: 100%;
    height: 620px;
    position: absolute;
    background-size: cover;
}
.content_page h2 {
	color: #fff;
	font-size: 28px;
}   
.content_page h3 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
}
.content_page p,
.content_page ul li {
    color: #bcbcbc;
    font-size: 14px;
}
.content_page h4 {
    color: #bcbcbc;
    font-size: 14px;
    font-weight: 600;
}
.dash_top_bar.content_page::before {
	height: 100%;
	background: #090909;
}
.content_page a {
	color: #055bb6;
	margin-left: 6px;
}
.dash_menu {
    z-index: 5;
}
.dash_menu li a button.menu_btn {
    padding: 10px 20px;
    width: 230px;
    font-size: 18px;
}
.dash_menu li a.active button.menu_btn {
	background-color: transparent;
	color: #055bb6;
}
.dash_box_panel {
	background: #272727;
	border-radius: 30px;
	box-shadow: 2px 0px 7px #000;
	padding: 5px 20px 20px;
	margin-top: 20px;
	text-align: center;
	min-height: 265px;
	z-index: 10;
	position: relative;
    margin-bottom: 12px;
    margin-top: 8px;
}
img.user_img {
    width: 60px;
    border-radius: 50%;
}
.dash_box_panel h2 {
    font-size: 16px;
    color: #ccc;
    font-weight: 500;
}
.user_name {
    color: #f3d690;
    font-size: 16px;
    font-weight: 500;
}
.kyc_status {
    color: #0dc81e;
    font-size: 12px;
}
.edit_icon a i {
    font-size: 20px;
    color: #f3d690;
}
a.tb_btn span {
    font-size: 14px;
    margin-left: 5px;
}
a.tb_btn {
    background: #ee561a;
    padding:8px 25px;
    border-radius: 30px;
    color: #000;
    text-decoration: none;
}
.tb_btn img {
    width: 18px;
    height: 18px;
    vertical-align: middle;
}
h1.token_bal {
    font-size: 22px !important;    
    color: #12a2e4;
    margin-top: 10px;
    margin-bottom: 30px;
}
h1.token_bal span {
    font-size: 18px;
    color: #ccc;    
    text-transform: uppercase;
    display: block;
}
.token_bal_note {
    font-size: 13px;
    margin: 15px 0 0;
}
.wb_btn span {
    font-size: 20px;    
    color: #f3d690;
    margin-left: 5px;
}
.wb_btn {
    margin: 25px 0;
}
.wallet_address_panel {
	background: #272727;
	border-radius: 10px;
	box-shadow: 0px 8px 15px #000;
	padding: 5px 50px 10px;
	margin-top: 8px;
    color: #fff;
    margin-bottom: 50px;
}
.wallet_address_panel h2 {
    font-size: 16px;
    text-align: center;    
    color: #fff;
}
button.tron_link_btn {
	background-color: #0d0d0d;
	color: #055bb6;
	font-size: 22px;	
	text-transform: capitalize;
	padding: 7px 45px;
	border-radius: 30px;
	vertical-align: middle;
	border: 2px solid #ee561a;
}
button.tron_link_btn img {
    margin-right: 10px;
    vertical-align: middle;
    width: 30px;
}
button.tron_link_btn:hover {
	background-color: transparent;
	color: #055bb6;
	border: 2px solid #ee561a;
}
.wallet_address span {
	font-size: 20px;
	color: #d53d18;
	word-break: break-all;
    display: block;
}
.wallet_address {
    color: #b5b5b5;
    font-size: 14px;    
    margin: 18px 0;
    text-align: center;
}
.djack_equavalent {
    font-size: 22px;
    text-align: center;
    color: #00a8e9;    
}
.djack_equavalent span {
	color: #fff;
}
.form_text_prepend {
    position: relative;
}
.form_text_prepend span {
    position: absolute;
    color: #199fe1;
    top: 10px;
    z-index: 1;
    right: 9px;
    font-size: 14px;    
    background-color: transparent;
}
.text_red {
    color: #b09500;
}
.token_details_panel {
    background-color: #e4ebfb;
    border-radius: 10px;
    box-shadow: -1px -1px 8px 2px rgb(0 0 0 / 20%), 1px 2px 7px 3px rgb(0 0 0 / 0.1);
    padding: 5px 20px 10px 20px;
    margin-top: 30px;
    color: #000;
}
.token_details_panel_left {
    background-color: #011f55;
    text-align: center;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 15px 0 10px;
}
.token_details_panel_left h2 {
    color: #fff;
    font-size: 16px !important;
    border-bottom: 1px solid #f3d690;
    padding: 10px 0;
}
.token_details_panel_left_row {
    font-size: 14px;
    margin: 15px 0;
    color: #fff;
}
.token_details_panel_left_row span {
    color: #f3d690;
    font-size: 22px;
    display: block;
    margin: 3px 0;
}
.token_details_panel_left_row.font_big {
    font-size: 18px;
}
.token_details_panel_left_row.font_big span {
    font-size: 26px;
}
.form_text_flex {
    display: flex;
    align-items: center;
}
form.auth_form .form_text_flex .auth_inp {
    width: 50%;
    margin-right: 10px;
}
.token_details_panel h2 {
    text-align: center;
    font-size: 24px;
}
.roi_plan_panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.roi_plan {
    background-color: #011f55;
    border-radius: 10px;
    width: 85px;
    text-align: center;
    padding: 10px 0;
    margin: 5px 10px;
    cursor: pointer;
}
.roi_plan p {
    font-size: 14px;
    color: #fff;
    margin: 0;    
}
.roi_plan h2 {
    font-size: 20px;
    color: #fff;
    margin: 0;
}
.roi_plan.roi_plan_selected,
.roi_plan:hover {
    background-color: #f3d690;
}
.roi_plan.roi_plan_selected h2,
.roi_plan.roi_plan_selected p,
.roi_plan:hover h2,
.roi_plan:hover p {
    color: #055bb6;
}
.roi_plan_panel h4 {
    color: #ababab;
    font-size: 14px;    
}
.roi_plan_panel h4 span {
    font-size: 16px;
}
.text_grey {
    color: #ababab;
}
.custom-checkbox .custom-control-label::before {
	border-radius: 6px;
	box-shadow: none;
	background-color: #1a1c1e;
	border: none;
}
.dash_top_bar .custom-checkbox .custom-control-label::before {
	border: 1px solid #199fe1;
}
.dash_top_bar .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #199fe1;
}
.total_income {
    color: #011f55;
    font-size: 16px;
    margin: 50px 0 0;
}
.total_income span {
    color: #055bb6;
    font-size: 36px;
    display: block;
    margin: 10px 0 20px;
}
.table_box {
	background-color: #1e2023;
	border-radius: 10px;
	box-shadow: none;
	padding: 5px 20px;
	margin-top: 30px;
	border: 1px solid #1e2023;
	margin-bottom: 30px;
}

.table_thumb_icon {
    width: 20px;
    height: 20px;
}
span.table_trans_id {
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}
.table_box table thead tr {
	background-color: #090909;
}
.table_box table thead tr th {
    color: #fff;
    font-size: 12px;    
    padding: 10px;
    border-bottom: 1px solid #2e2e2e;
}
.table_box table tbody tr td {
    padding: 10px;
    font-size: 12px;
    color: #bcbcbc;    
    border-bottom: 1px solid #2e2e2e;
}
.table_box table tbody tr {
    transition: all 0s;
    cursor: default;
}
.table_box table tbody tr:hover {
    background-color: rgb(243 214 144 / 0.3);
}
.table_box table tfoot tr td {
    border: 0;
}
.table_box h2 {
    font-size: 20px;
    color: #fff;
}
footer.footer_inner h2 {
	color: #fff;
	font-size: 16px;
	margin-bottom: 15px;
	font-weight: 600;
	letter-spacing: 0.3px;
}
footer.footer_inner p {
	color: #bcbcbc;
	font-size: 12px;
	line-height: 16px;
}
footer.footer_inner ul {
    padding: 0;
    margin: 0;
}
footer.footer_inner ul.quick_links li {
    display: block;
}
footer.footer_inner ul.quick_links li a {
    color: #bcbcbc;
    font-size: 10px;
    text-transform: uppercase;
    padding: 0;
}
footer.footer_inner ul.quick_links li a:hover {
    color: #ffca10;
}
.copyright_txt,
.copyright_txt a {
    color: #fff;
    font-size: 11px;
}
footer.footer_inner {
    background-color: #000;
}
ul.social_links li {
    display: inline-block;
    margin: 8px;
}
/* ul.social_links li a {
    background-color: #011f55;
    color: #e4ebfb;
    font-size: 16px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
ul.social_links li a:hover {
    background-color: #b00;
} */
.search_box input {
    border: 1px solid #eec666;
    width: 100%;
    border: 1px solid #eec666;
    height: 42px;    
    font-size: 14px;
    padding: 8px;
}
.search_box span {
    color: #a2a2a2;
}
.dash_inner_header h1 {
	font-size: 26px;
	color: #fff;	
	position: relative;
	padding: 25px 0 20px 50px;
	border-bottom: 1px solid #1e2023;
	margin-top: 0;
	font-weight: bold;
	letter-spacing: 0.3px;
}
.wallet_address_panel.dash_inner_header {
    padding: 0;
}
.dash_inner_header form.auth_form .auth_label {
    color: #bfbfbf;
}
.avil_balance {
    color: #000;
    font-size: 12px;
    margin-bottom: -15px;
}
.avil_balance span {
    color: #055bb6;
}
.coin_type {
    font-size: 14px;
}
select.custom-select.auth_inp {
    background: #272727 url(../src/assets/images/select_dropdown.png) no-repeat right 0.75rem center;
}
.font_14 {
    font-size: 14px;
}
.deposit_address_sec label {
    font-size: 14px;
    color: #fff;    
    margin-bottom: 0;
}
.deposit_address_sec h4 {    
    color: #055bb6;
    font-size: 20px;
    margin: 0 0 20px;
    word-break: break-all;
}
button.primary_btn.copy_btn {
    font-size: 12px;
    padding: 5px 23px;
}
.login_social_links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.login_social_links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.login_social_links ul li {
    display: inline-block;
    margin: 0 12px;
}
.login_social_links ul li a img {
    transition: all 0s;
}
.login_social_links ul li a:hover img {
    filter: drop-shadow(2px 2px 8px rgb(0 0 0 / 30%));
}
h2.dash_subtitle {
    font-size: 20px !important;
    color: #fff;
    position: relative;
    overflow: hidden;
}
.dash_subtitle::after {
    content: "";
    background-color: transparent;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 4px;
}
.dash_subtitle span {
    color: #055bb6;
}
.dash_inner_padding {
    padding: 5px 50px 40px;
}
.auth_inp.tfa_code {
    background: #f0f0f0;
    border: 1px solid #f0f0f0 !important;
    text-align: center;
    font-size: 18px !important;
    color: #000;    
}
.support_ticket_status p {
    font-size: 14px;    
    color: #bcbcbc;
}
.support_ticket_status p a {
	color: #a68e0b;
	text-decoration: underline;
}
.support_ticket_status p a:hover {
    color: #011f55;
}
.ticket_status_header {
    border-bottom: 1px solid #eec666;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dash_inner_header .ticket_status_header h1 {
    border-bottom: 0;
}
.ticket_status_header select.custom-select.auth_inp {
    width: 100%;
    border: 1px solid #eec666;
    height: 42px;    
    font-size: 14px;
    padding: 8px;
    border-radius: 0;
}
.ticket_status_header .auth_label {
    display: block;
    font-size: 14px;
    color: #bfbfbf;    
    width: 120px;
    margin-bottom: 0;
}
.support_ticket_panel {
    padding: 20px;
}
.support_ticket_row {
    display: flex;
    align-items: flex-start;
    padding: 8px 0;
}
.support_ticket_row img.user_img {
    margin-right: 15px;
    width: 40px;
}
.ticket_details {
    border-bottom: 1px solid #bfbfbf;
}
.ticket_details p {
    color: #bfbfbf;
    font-size: 14px;
}
.wallet_address_panel.dash_inner_header.dash_white_box {
    background-image: none;
    background-color: #fff;
}
ul.dash_inner_menu {
	border-bottom: 1px solid #1e2023;
	padding: 10px 35px 0;
}
.dash_inner_menu li {
    margin: 0 15px;
}
.dash_inner_menu li a.nav-link {
    font-size: 22px;
    color: #fff;
    text-transform: capitalize;
    padding: 20px 10px;
}
.dash_inner_menu li a.nav-link.active {
	color: #055bb6;
	border-bottom: 4px solid #ee561a;
}
.djack_equavalent_buy_sec.djack_equavalent span {
    color: #fff;
}
.djack_equavalent.djack_equavalent_buy_sec {
	background-color: #090909;
	padding: 15px;
	margin: 15px 0 30px;
	border-radius: 6px;
}
.auth_inp.yellow_bg {
    background-color: #fff3d6;
}
.input-group.mobile_select {
    flex-wrap: nowrap;
}
.input-group.mobile_select select {
    width: 100px;
    border-right: 0;
}
.mobile_select input.auth_inp {
    border-left: 0 !important;
}
.file_upload_group .custom-file-input:lang(en) ~ .custom-file-label::after {
	content: "Upload";
	height: 40px;
	background-color: #055bb6;
	color: #000;
	line-height: 26px;
	top: 0px;
	padding: 8px 30px;
	border-radius: 0px 5px 5px 0px;
}
.file_upload_group .custom-file-label {
    padding: 8px 8px !important;
}
form.auth_form .file_upload_group .auth_inp {
    line-height: 24px;
}
img.upload_prev_img {
    height: 300px;
}
.account_details_panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #1e2023;
    padding-bottom: 10px;
}
.account_details_panel h2 {
    font-size: 20px !important;
}
.account_details_panel p {
    font-size: 12px;
    margin-bottom: 0;
    color: #fff;
}
.referal .text-success{
    color: #ee561a !important;
}
.account_details_panel p span {
    color: #055bb6;
}
.top_navbar ul li::after {
    display: none;
}
.padding_container {
    padding-bottom: 50px !important;
    padding-top: 50px !important;
}
.homepage_container {
    padding-top: 15vh !important;
    padding-bottom: 60px !important;
}
.page-header h1 {
    color: #ffffff;
    font-size: 80px;
    line-height: 70px;
    font-weight: 600;
    text-shadow: 4px 5px 15px rgb(255 0 66 / 30%);
}
.page-header h1 span {
    font-size: 54px;
    display: block;
}
.page-header h2 {
    color: #fff;
    font-size: 40px;
    position: relative;
}
.page-header h6 {
    color: #e1e1e1;
    font-size: 14px;
    line-height: 24px;
    text-transform: unset;
}
.page-header h6 span {
    font-size: 18px;
    color: #fff;
    display: block;
    white-space: normal;
    word-break: break-all;
}
.token_sale_panel {
    background-image: linear-gradient(2deg, #0040cf, #011f55) !important;
    border-radius: 15px;
    box-shadow: -1px -1px 3px -1px rgb(195 195 195/40%), 1px 2px 3px -1px rgb(0 0 0/0.8);
    padding: 10px 30px 25px;
    margin: 20px auto;
    width: 750px;
}
.token_sale_panel h4 {
    font-size: 24px;
    color: #f2d489;
}
.token_sale_panel h5 {
    font-size: 20px;    
    color: #fff;
}
.token_sale_panel h5 span {
    color: #f2d489;
}
.timer_panel {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: 10px 0 20px;
}
.timer_time {
    font-size: 60px;
    text-align: center;
    font-weight: 500;
    color: #00a5e7;
    line-height: 62px;
}
span.timer_label {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #fff;
}
span.timer_dots {
    font-size: 40px;
    color: #edeaea;
    line-height: 65px;
}
.timerdiv_panel_inner h2 {
    font-size: 18px;
    color: #e1e1e1;
}
.timerdiv_panel_inner h2 span {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}
.timerdiv_panel {
     background-color: rgb(21 25 57 / .35) !important;
    border-radius: 15px;
    padding: 3px;
}
.timerdiv_panel_inner {
   
    border-radius: 12px;
    padding: 30px 40px;
}
.timerdiv_panel p {
    color: #ffffff;
    font-size: 30px;
}
.token_sale_notes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.token_sale_notes_right h1 {
    font-size: 40px;
    color: #fff;
}
.token_sale_notes_left p {
    font-size: 14px;
    color: #bfbfbf;
    margin: 0;
}
.token_sale_notes_left h3 {
    font-size: 18px;
    margin: 0;
}
.banner_token_btn {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
}
.banner_token_btn button.primary_btn {
    font-size: 22px;
    margin-top: 5px;
    text-transform: uppercase;
    padding: 8px 60px;
    border: 2px solid #f2d489;
}
.top_navbar_left {
    position: absolute;
    left: 0;
    bottom: -32px;
}
.top_navbar_left li::after {
    display: none;
}
.header_home header {
	/* padding: 17px 0px; */
    background: transparent;
}
.blue_bg_section {
    position: relative;
}
.blue_bg_section::before {
    content: "";
    background-color: #e4ebfb;
    position: absolute;
    height: 75%;
    width: 100%;
}
p.home_para {
    color: #000;
}
.whitepaper_panel {
    width: 500px;
    position: absolute;
    right: 150px;
    height: 100%;
    background: #011f55;
    padding: 40px;
    text-align: center;
}
.whitepaper_panel p {
    font-size: 14px;
    color: #f2d489;
}
.whitepaper_panel button.primary_btn {
    padding: 7px 40px;
    font-size: 16px;
}
.whitepaper_panel button.primary_btn a {
    text-decoration: none;
    color: #fff;
}
.token_sale_section {
    background-image: url(../src/assets/images/token_bg.png);
}
.ico_details_panel {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.ico_details_panel h2 {
    font-size: 22px;
    color: #055bb6;
    margin: 0;    
}
.ico_details_panel p {
    color: #5f5f5f;
    font-size: 16px;
}
.ico_details_panel > div {
    width: 50%;
    margin-top: 30px;
}
.ico_details_panel h4 {
    font-size: 18px;
    color: #055bb6;
    margin: 0;
}
.slick_vertical_nav {
    text-align: right;
    border-right: 1px solid #eec666;
    padding-right: 40px;
    margin-top: 50px;
}
.slick_vertical_nav h3 {
    color: #055bb6;
    font-size: 18px;
}
.slick_vertical_nav p {
    color: #011f55;
    font-size: 18px;
}
.slick_vertical_nav .slick-slide.slick-active.slick-current h3 {
    background-color: #eec666;
    display: inline-block;
    padding: 0 20px;
}
/* ul.slick_slider_list {
    list-style-image: url(../src/assets/images/list_image.png);
    text-align: left;
    margin-top: 25px;
    padding: 0;
} */
ul.slick_slider_list li {
    margin: 10px 0 0 50px;
}
ul.slick_slider_list li span {
    vertical-align: super;
}
.slick_vertical_nav .slick-prev {
    left: unset;
    top: -15px;
    right: -50px;
    z-index: 10;
    background-image: url(../src/assets/images/slick_prev_arrow.png);
    width: 18px;
    height: 10px;
}
.slick_vertical_nav .slick-next {
    right: -50px;
    bottom: -30px;
    top: unset;
    z-index: 10;
    background-image: url(../src/assets/images/slick_next_arrow.png);
    width: 18px;
    height: 10px;
}
.hamburger_btn {
    color: #fff !important;
}
.slick_vertical_nav .slick-next:before,
.slick_vertical_nav .slick-prev:before {
    display: none;
}
.slick_vertical_nav .slick-prev:focus,
.slick_vertical_nav .slick-prev:hover {
    background-image: url(../src/assets/images/slick_prev_arrow.png);
}
.slick_vertical_nav .slick-next:focus,
.slick_vertical_nav .slick-next:hover {
    background-image: url(../src/assets/images/slick_next_arrow.png);
}
.slick_vertical_mainnav {
    margin-top: 50px;
}
.press_section img {
    transition: 0.4s all;
}
.press_section img:hover {
    transform: scale(1.2);
}
.team_image {
    position: relative;
}
.team_image img {
    border-radius: 50%;
    width: 140px;
    height: 140px;
    filter: grayscale(1);
}
.team_details h2 {
    color: #011f55;
    font-size: 24px;
    margin: 5px 0 0;
}
.team_details h4 {
    font-size: 16px;
    color: #a0a0a0;
    margin: 0;
}
.team_social {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.team_social li {
    margin: 5px 0;
}
.team_social li a {
    color: #e1e1e1;
}
.team_panel {
    background: linear-gradient(2deg, #fff, #fff);
    border-radius: 15px;
    padding: 15px 5px 15px;
    cursor: default;
    margin-top: 15px;
    transition: 0.4s all;
}
.team_panel:hover {
    background: linear-gradient(2deg, #0040cf, #011f55);
}
.team_details p {
    color: #f2d489;
    font-size: 14px;
    margin: 10px 0 8px;
    line-height: 20px;
    display: none;
}
.team_panel:hover .team_details p {
    display: block;
}
.team_panel:hover .team_details h2,
.team_panel:hover .team_details h4 {
    display: none;
}
.team_panel:hover .team_image img {
    filter: grayscale(0);
}
.faq_section {
    background: url(../src/assets/images/faqbg.jpg) no-repeat scroll center top;
    background-size: cover;
}
.faq_section .MuiAccordionSummary-content {
    margin: 0 !important;
}
.faq_section .MuiAccordionSummary-root,
.faq_section .MuiAccordionSummary-root.Mui-expanded {
    min-height: unset !important;
    line-height: 0;
}
h2.accordian_head {
    color: #d7d7d7;
    margin: 3px 0 5px;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
}
p.accordian_para {
    text-align: left;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
}
.minus svg,
.plus svg {
    font-size: 24px;
}
.footer_contact_form form {
    background-image: linear-gradient(2deg, #0040cf, #011f55) !important;
    border-radius: 30px;
    box-shadow: -1px -1px 3px -1px rgb(195 195 195/40%), 1px 2px 3px -1px rgb(0 0 0/0.8);
    padding: 10px 120px 25px;
    margin-top: 50px;
}
.footer_contact_form form h2 {
    font-size: 24px;
    color: #ffab00;
}
.footer_contact_form form .auth_inp {
    width: 100%;
    border-radius: 5px;
    height: 42px;
    font-size: 16px;
    padding: 8px;
    background-color: #01092f;
    border: 1px solid #01092f;
    color: #fff;
}
.footer_contact_form form .auth_inp:focus {
    border: 1px solid #0f3eff;
}
footer.footer_home {
    background-color: #011f55;
}
footer.footer_home .login_social_links {
    margin-top: 250px;
    margin-bottom: 40px;
    display: none;
}
.footer_contact_form {
    position: absolute;
    z-index: 5;
}
.form-group input[type="file"] {
    z-index: 5;
}
.gif_loader {
    position: absolute;
    z-index: 5;
    top: -160px;
}
.loader_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.google_login {
    border: 1px solid #eec667 !important;
}
.google_login div {
    margin: 0 !important;
    padding: 2px 7px !important;
    background: #eec667 !important;
}
.google_login span {
    display: none;
}
.team_section p {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
}
.team_section button.primary_btn {
    padding: 15px 50px;
    font-size: 20px;
}


html {
    font-family: 'Oxanium', cursive;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
	margin: 0;
	font-family: 'Oxanium', cursive !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	background-color: #0d0d0d;
	color: #b4b4b4;
	overflow-x: hidden;
}
.timer_compnant .flex_time_content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px 21px;
	padding-left: 0;
}
.timer_compnant .time_text {
	font-size: 60px;
	margin-bottom: 17px;
	color: #ffd90f;
	font-weight: 600;
}
.timer_compnant {
	padding: 20px 0px;
}
.timer_compnant span {
	width: 100%;
	padding: 2px 8px;
	font-size: 13px;
	text-transform: uppercase;
	color: #8a8a8a;
	font-weight: 600;
	letter-spacing: 0.2px;
}
.timer_compnant i {
	font-style: normal;
	padding-left: 14px;
}
.progress_in_head .progress {
	height: 24px;
	border-radius: 0;
	box-shadow: none;
	background: #fff;
	margin-bottom: 10px;
	border: 3px solid #fff;
	border-radius: 20px;
}
.progress_in_head .progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #000;
	text-align: right;
	background-color: #ffd700;
	transition: width 0.6s ease;
	font-weight: 600;
	font-size: 12px !important;
	padding-right: 12px;
	border-radius: 0px 15px 15px 0px;
    padding-left: 8px;
}
.brohress_label {
	display: flex;
	justify-content: space-between;
	align-content: center;
	padding: 0px 6px;
}
.brohress_label span {
	font-size: 18px;
	color: #707070;
}
.gray_full_theam {
	background: #121212;
	margin-left: -485px;
	height: 413px;
	padding: 62px 1px;
	border-radius: 50px;
}
.for_padin_section {
	padding-right: 0;
	position: relative;
    margin: 10px 71px;
    margin-left: 0;
}
.main_title {
    position: relative;
    margin-bottom: 30px !important;
    font-size: 40px !important;
    color: #fff !important;
    font-weight: 600 !important;
}
.main_title_center {
    text-align: center;
}
.main_title::after {
    content: "";
    background: url("../src/assets/images/titleLine.png") no-repeat center bottom;
    position: absolute;
    bottom: 0;
    width: 120px;
    height: 8px;
    bottom: -15px;  
    left: 0;  
}
.main_title_center::after {
    right: 0;
    margin: 0 auto;
}
.abt_imgs {
    display: flex;
}
.abt_imgs img {
    width: 47px;
    margin: 0 15px;
}
.abt_imgs img:first-child {
    margin-left: 0;
}
.for_padin_section h3 {
	margin: 0;
	font-size: 38px;
	color: #fff;
	font-weight: bold;
	letter-spacing: 0.3px;
	padding: 4px 0px;
}
.for_padin_section p {
  font-size: 16px;
  color: #b4b4b4;
  padding-bottom: 24px;
  margin-top: 10px;
}
.section_bg_brown_color main{
 position: relative;
}
.img-g_rignt {
	margin-left: -83px;
	max-width: 421px;
}
.bgOrangeRadial{
    padding-top: 55px;
    padding-bottom: 35px;
    background: #1f2025 url("../src/assets/images/bgOrangeShade.png") no-repeat right bottom;
}
.section_bg_brown_color {
	padding: 0px 0px 55px;
	width: 100%;
    background: #1f2025;
    position: relative;
}
.box-alighn_right {
	float: right;
	border-radius: 117px 0px 0px 117px !important;
}
.feature_section {
    background: #000000 url("../src/assets/images/features_bg.png") no-repeat;
    background-position: center;
    background-size: cover;
}
.how_secr_e h3{
	font-size: 38px;
	font-weight: bold;
	letter-spacing: 0.2px;
}
.noteContent p {
  font-size: 14px;
  color: #fff;
}
.noteContent h4{
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 7px;
}
.referral_reward h4{
    font-size: 20px;
    color: #ffffff;
}
.noteContent {
  margin-top: 20px;
}
.left_box_height {
	width: 60%;
	height: 350px;
}
.card_how {
	margin: 0 36px;
	background: transparent;
	padding: 15px 37px;
	margin-bottom: 50px;
    text-align: center;
    transition: 0.2s all;
}
.margin_topo_33 {
	margin-top: -50px !important;
}
.padin_top_how_s2 {
	padding-top: 86px;
}
.card_how{
    position: relative;
    transition: .5s;
}
.card_how h5 {
	font-size: 24px;
	margin: 0 0 10px;
	padding: 4px 0px;    
    text-transform: uppercase;
    position: relative;
    transition: .5s;
    font-weight: 600;
    margin-top: -18px;
}
.card_how h5  small{
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}
.card_how img {
	width: 58px;
	margin: 7px 0px;
}
.margr_toe_how_3 {
	margin: 0 !important;
	margin-top: -28px !important;
}
.card_how:before{
    content: "";
    width: 300px;
    height: 45px;
    position: absolute;
    top: -7px;
    left: 50%;
    margin-left: -150px;
}
.card_how:hover::before {
     background: url("../src/assets/images/box-head.png") no-repeat scroll 0 0;
     transition-duration: 0.3s;
}
.card_how:hover {
	background: #18191f;
	cursor: pointer;
	transition-duration: 0.3s;
}
.card_how:hover.card_how > img {
  filter: brightness(0) invert(0);
  /* transition-delay: 0.1s;
  transition-duration: 0.3s; */
}
.card_how:hover.card_how > h5 {
  color: #fff;
  /* transition-delay: 0.1s;
  transition-duration: 0.3s; */
}
.card_how:hover.card_how > p {
  color: #ffffff;
  /* transition-delay: 0.1s;
  transition-duration: 0.3s; */
}
.bg_comming {
	background: #0d0d0d;
}
.tokensale_section {
    background: #222126 url("../src/assets/images/saletokenbg.jpg") no-repeat center bottom;
    background-size: cover;
    padding: 70px 0;
}
.comming_section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
  align-items: flex-start;
  padding: 33px;
}
.comming_section h1 {
	font-size: 62px;
	font-weight: 600;
	margin: 0;
	color: #ffd700;
} 
.comming_section h3 {
	margin: 0;
	font-size: 30px;
	color: #fff;
	font-weight: 600;
	letter-spacing: 0.2px;
	padding: 11px 0px;
}
.img_phone {
	margin-top: -11px;
	margin-bottom: 8px;
}
.chart_img {
	width: 530px;
	float: right;
	margin: 40px 4px;

}
.tokendistripution h3 {
	margin: 0;
	font-size: 38px;
	color: #fff;
	font-weight: 600;
	padding: 10px 0px;
	margin-bottom: 7px;
}
.tokendistripution p {
	margin: 0;
	color: #fff;
}
.token_contern_o {
	padding-left: 50px;
	margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.token_contern_o > li {
	position: relative;
	list-style: none;
	margin-bottom: 12px;
	width: 33%;
	padding-left: 35px;
}   
.token_contern_o > li::before {
	content: '';
	position: absolute;
	height: 6px;
	width: 6px;
	background: #ccc;
	left: 0px;
	border-radius: 50%;
	top: 21px;
}
.token_contern_o h5 {
	font-size: 18px;
	color: #ffd602;
	font-weight: 600;
	letter-spacing: 0.3px;
	margin: 0;
	padding: 8px 0px;
}
.roadmap_section {
     background: #222126 url("../src/assets/images/roadmapbg.jpg") no-repeat center center;
     background-size: cover;
	padding: 60px 0px;
    overflow: hidden;
    position: relative;  
}
.roadmap_section  h3 {
	margin: 0;
	font-size: 38px;
	font-weight: 600;
	margin-bottom: 24px;
}
.year_content h5 {
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	color: #ffd700;
	margin-bottom: 14px;
	margin-top: 11px;
}
.year_content p {
	font-size: 15px;
	padding-right: 5px;
}
.circule_line {
	width: 20px;
	height: 20px;
	display: block;
	background: #ffd700;
	border-radius: 50%;
  margin-top: 37px;
  position: relative;
}
.circule_line::after {
	position: absolute;
	content: '';
	width: 143px;
	height: 6px;
	background: #ffd700;
	left: 30px;
	top: 8px;
}
.year_content {
	padding-top: 15px;
}
.slider_content{
  padding-top: 40px;
}
.p-top_30{
  padding-top: 30px;
}
._sliderlastchild::after{
  visibility: hidden !important;
}
.color_2 {
	background: #af7100;
}
.color_2:after{
	background: #af7100;
}
.color_3 {
	background: #f6e67e;
}
.color_3:after{
	background: #f6e67e;
}
.color_4{
  background: #f5f1c5;
}
.img_teme_sect {
	display: flex !important;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
}
.img_teme_sect .img_hover {
	width: 200px;
	overflow: hidden;
	border-radius: 21px;
	margin-bottom: 28px;
	position: relative;
  height: 200px;
  cursor: pointer;
}
.mat-_000 {
	margin-top: 0 !important;
}
.pa_top_45 {
	padding-top: 45px;
}


.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;

 
  }
  .img_hover:hover .overlay {
    opacity: 1;
    background-color: #ffd700a1;
  }
  .inner_img_content {
    position: relative;
    bottom: -138px;
  }
  .inner_img_content h4 {
    margin: 0;
  
    text-align: center;
    font-weight: 600;
  }
  .inner_img_content p {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
  }
  .referral_reward h3 {
    margin: 0;    
    font-size: 36px;
    font-weight: 400;
    color: #fff;
  }
  .referral_reward h2 {
    margin: 0;
    font-size: 50px;
    font-family: 'Oxanium', cursive;
    font-weight: 600;
    color: #12a2e4;
  }
  .referral_reward p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding: 0 250px;
  }
  .button_referal {
    padding: 73px 0;
    display: flex;
  }
  .button_referal button {
    background: #ffd700;
    border: none;
    padding: 14px 34px;
    border-radius: 10px;
    color: #000;
  }
  .button_referal a {
    font-weight: 600;
    color: #000;
  }
  .FAQ_SWEC h1 {
    margin: 0;
    text-align: center;
    padding: 10px 0px;
    margin-bottom: 9px;
    font-weight: 600;
    color: #fff;
  }
  .FAQ_SWEC p {
    text-align: center;
    color: #fff;
    font-size: 16px;
  }
  .footer_newe_ h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
  }
  .footer_newe_ p {
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 17px;
  }
  .footer_input_box input {
    width: 50%;
    height: 50px;
    padding: 17px 15px;
    font-weight: 600;
    border: none;
    border-radius: 15px 0px 0px 15px;
  }
  .footer_input_box button {
    border: none;
    height: 50px;
    padding: 1px 25px;
    background: #ffd700;
    border-radius: 15px 15px 15px 15px;
    margin-left: -16px;
    margin-top: -1px;
    font-size: 15px;
    font-weight: 600;
  }
  .icon_social i {
    color: #000;
    /* padding: 10px 10px; */
    background: #3c3c3c;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 23px;
    margin: 20px 15px;
  }
  .icon_social i:hover{
    background: #ffd700;
    cursor: pointer;
  }
  .icon_social ul {
    padding: 15px 0px;
  }
  .footer_newe__bottom ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .footer_newe__bottom p {
    color: #bcbcbc;
    font-size: 14px;
    margin: 0;
  }
  .footer_newe__bottom {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
    border-top: 1px solid #151515;
  }
  /* .makeStyles-container-1 {
	padding-top: 0 !important;
	padding-bottom: 0px !important;
} */
.faq_section .MuiAccordion-rounded {
	border-radius: 2px !important;
	margin: 15px 0 !important;
	box-shadow: none;
	background-color: #141414;
	color: #fff;
	border-radius: 20px !important;
} 
.faq_section .MuiAccordion-rounded.Mui-expanded {
    background-color: #055bb6;
}
.faq_section .MuiAccordion-rounded.Mui-expanded h2.accordian_head,
.faq_section .MuiAccordion-rounded.Mui-expanded p.accordian_para {
    color: #000;
}
.footer_newe_ h2 {
	color: #fff;
	font-size: 30px;
	font-weight: 600;
	margin: 0;
}
.footer_newe_ p {
	color: #fff;
	text-align: center;
	margin-bottom: 25px;
	margin-top: 17px;
}
.footer_input_box input {
	width: 50%;
	height: 50px;
	padding: 17px 15px;
	font-weight: 600;
	border: none;
	border-radius: 15px 0px 0px 15px;
}
.footer_input_box button {
	border: none;
	height: 50px;
	padding: 1px 25px;
	background: #ffd700;
	border-radius: 15px 15px 15px 15px;
	margin-left: -16px;
	margin-top: -1px;
	font-size: 15px;
	font-weight: 600;
}
footer ul {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
}
.icon_social ul {
	padding: 15px 0px;
}
.icon_social i {
	color: #000;
	/* padding: 10px 10px; */
	background: #3c3c3c;
	height: 45px;
	width: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 23px;
	margin: 20px 15px;
}
.footer_newe__bottom {
	display: flex;
	justify-content: space-between;
	padding: 12px 0px;
	border-top: 1px solid #151515;
}
.footer_newe__bottom ul {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
footer ul li a img {
    transform: rotateY(360deg);
    transition: 0.12s all;
}
footer ul li a:hover img {
    transform: rotateY(-360deg);
}
.footer_newe_ {
	text-align: center;
}
.nav-link {
	text-transform: capitalize;
}
.main_navbar li a {
	color: #edeaea !important;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
}
.main_navbar li a.active, .main_navbar li a:hover {
	color: #00adec !important;
	/* background: #ffd700; */
	border-radius: 6px;
}
.footer{
  background: #1f2025;
}
.footer_input_box {
	display: flex;
	justify-content: center;
	align-items: center;
}   
.banner_btn {
	margin-top: 40px;
}
.banner_btn button.auth_btn {
	width: 220px;
	padding: 13px;
	margin-right: 15px;
}
button.auth_btn {
	/* background-image: linear-gradient(to right, #0a7690 0%, #17abce 100%); */
	box-shadow: 0 4px 8px 2px rgb(0 0 0 / 0.3);
	color: #000 !important;
	font-size: 20px;
	font-weight: 5600;
	border-radius: 14px;
	width: 250px;
	background: linear-gradient(90deg, rgba(255,215,0,1) 0%, rgba(255,224,57,1) 100%);
}
button.auth_btn {
	color: #000 !important;
	font-size: 20px;
	font-weight: 5600;
}
.banner_btn a {
	color: #000;
	font-size: 18px !important;
}
.pdin_top_pos {
	/* padding-top: 54px; */
	/* color: #000; */
}
._only_footer i {
	margin: 10px 3px;
}
.fooer_logo_s {
	width: 200px;
	margin: 12px auto;
	/* display: block; */
}
.asrt-page-length .input-group-addon {
	background: transparent !important;
}
.PhoneInputInput {
    border-radius: 7px;
    background: #1e2023;
    color: #fff;
	padding: 8px 8px;
	box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
	-webkit-box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
	-moz-box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
	border: 1px solid #ee561a;
}
.PhoneInputCountry {
	border: 1px solid #ee561a;
	height: 42px;
	font-family: 'Oxanium', cursive;
	font-size: 14px;
	padding: 8px;
	border-radius: 0;
	background: #1e2023;
	color: #fff;
	border-radius: 7px;
	padding: 13px 8px;
	box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
	-webkit-box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
	-moz-box-shadow: -1px 6px 20px 10px rgba(0,0,0,0.3) inset;
}
#as-react-datatable-table-foot{
    color: #fff;
}
.importtant{
    color: #fff;
}
.fa-clipboard{
    color: #00a8e9;
}
.color_black{
    color: #000;
}
.color_black:hover{
    color:#ee561a;
}
.top_navbar li a.nav-link{
    color: #edeaea !important;
    font-size: 16px;
    font-weight: 400;
}
.top_navbar li a.active, 
.top_navbar li a:hover {
    color: #ee561a !important;
    border-radius: 6px;
}
.top_navbar button.menu_btn {
	background-color: transparent;
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
	padding: 6px 30px;
	border-radius: 8px;
	/* color: #fff; */
	font-weight: 600;
	border: 0px solid #ee561a;
	box-shadow: none;
}
.top_navbar button.menu_btn:hover{
    color: #ffdb00;
}
.single_about {
	padding: 15px 20px;
	background: rgb(6 26 39 / 60%);	
	border-radius: 10px;
	width: 97% !important;
}
.single_about > div {
    display: flex;
    align-items: center;
}
.single_about img {
	margin-right: 10px;
}
.img_commung {
	min-width: 199px;
}
.single_about h4 {
	font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
}
.single_about h1 {
	font-size: 30px;
	font-weight: bold;
	color: #ffd801;
	margin-bottom: 0;
}
.single_about p {
	font-size: 16px !important;
	line-height: 21px !important;
	text-align: left !important;
	margin: 8px 0;
}
.about_panel {
    border-radius: 15px;
    padding: 3px;
    cursor: default;
    margin-bottom: 30px;
    height: 100%;
}
.about_panel_inner {
    background: #18191f !important;
    border-radius: 12px;
    padding: 30px 40px 0;
    position: relative;  
    height: 100%;  
}
.about_panel h2 {
    color: #fff;
    font-size: 24px;
    text-align: center;
    display: block;
    margin-top: -32px;
    position: relative;
    font-weight: 600;
}
.about_panel p {
    color: #edeaea;
    font-size: 16px;
    text-align: center;
    padding-top: 15px;
}
.abt_create::before,
.abt_swap::before,
.abt_nft::before {
    content: "";
    position: absolute;
    top: -7px;
    left: 50%;
    margin-left: -150px;
    transition: .5s;
}
.abt_create::before {
    background: url("../src/assets/images/box-head-default.png") no-repeat scroll 0 0;
    width: 300px;
    height: 45px;
}
.abt_create:hover::before {
     background: url("../src/assets/images/box-head.png") no-repeat scroll 0 0;
}
.abt_swap::before {
    background: url("../src/assets/images/box-head-default.png") no-repeat scroll 0 0;
    width: 300px;
    height: 45px;
}
.abt_swap:hover::before {
     background: url("../src/assets/images/box-head.png") no-repeat scroll 0 0;
}
.abt_nft::before {
    background: url("../src/assets/images/box-head-default.png") no-repeat scroll 0 0;
    width: 300px;
    height: 45px;
}
.abt_nft:hover::before {
     background: url("../src/assets/images/box-head.png") no-repeat scroll 0 0;
}
.width_480 {
	width: 718px;
	margin-right: -66px;
	margin-top: 70px;
}
.banner_IDD_content {
	color: #ffde2e;
	font-style: inherit;
	padding: 1px 47px;
	padding-left: 0;
}
.banner_IDD_content span {
	font-size: 23px;
	margin: 0 8px;
	/* margin-top: -7px !important; */
}
.mt-minus_token {
	margin-top: -80px;
}
.fund_text_new {
	color: #ffd700;
	font-size: 29px;
	font-weight: 600;
}
.main_black {
	background: #1f2025;
}
.MuiAppBar-positionFixed{
    border-bottom: 0 !important;
}
.news_ticker_sec {
	padding-top: 50px;
}
.news_ticker_sec .slick-dots li button::before {
	font-size: 10px;
    line-height: 10px;
    color: #b8b8b8;
    opacity: 0.5;
}
.news_ticker_sec .slick-dots li.slick-active button::before {
	opacity: .75;
	color:  #00a8e7;
}
.news_ticker_sec ul.slick-dots li {
    display: block;
    width: 10px;
    height: 10px;
    margin: 5px;
}
.news_ticker_sec ul.slick-dots {
    top: 0;
    bottom: 0;
    display: flex !important;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    width: unset;
    right: 0;
}
.news_ticker_sec .slick-prev::before {
	content: url(../src/assets/images/arrow_left.png);
    display: none !important;
}
.news_ticker_sec .slick-next::before {
	content: url(../src/assets/images/arrow_right.png);
    display: none !important;
}
.news_ticker_sec .slick-prev {
	left: -74px;
}
.footer{
    color: #000;
}
.foort_home i {
	margin: 22px 16px;
}
.height_ois {
	height: 207px;
}
.inner_pageheader {
    padding-top: 80px !important;
}
.fiat_deposit_list {
    padding: 0;
}
.fiat_deposit_list li a {
    font-size: 18px !important;
    text-transform: capitalize !important;
}
.fiat_deposit_panel label.auth_label {
    color: #fff !important;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
.upload_btn {
    border: 1px solid #ee561a;
    color: #055bb6;
    background-color: #1b1d1f;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
}
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.admin_bank_details_panel {
    text-align: left;
    color: #fff;
}
.admin_bank_details_panel h2 {
    text-align: left;
    font-size: 20px;
    border-bottom: 1px solid #424242;
    padding-bottom: 5px;
}
.admin_bank_details_panel > div {
    margin-bottom: 15px;
}
.admin_bank_details_panel > div span {
    font-size: 16px;
    width: 40%;
    display: inline-block;
}
.admin_bank_details_panel > div span:nth-child(2) {
    color: #055bb6;
}
.bank_details_left_border {
    border-left: 1px solid #444;
}
.banner_inp_box input {
    background-color: #151839;
    color: #fff;
    border: 1px solid #28516c;
    border-radius: 30px;
}
.banner_inp_box button {
    background: rgb(203,68,166);
    background: linear-gradient(77deg, rgba(211,59,24,1) 0%, rgba(22,160,226,1) 67%, rgba(4,109,194,1) 100%); 
    border-radius: 30px;
    text-transform: unset;
    color: #ffffff;
}
.banner_inp_box button:hover {
    background: linear-gradient(77deg, rgba(4,109,194,1) 0%, rgba(22,160,226,1) 67%, rgba(211,59,24,1) 100%);
}
.banner_inp_box button {
    margin-left: -30px
}
.token_supply {
    background: #050505;
    text-align: center;
    padding: 20px;
    width: 80%;
    margin: 50px auto 30px;
    border-radius: 8px;
}
.token_supply h3 {
    color: #b4b4b4;
    font-size: 20px;
    margin: 5px 0 8px;
}
.orange_txt {
    color: #12a2e4;
}
table {
    width: 100%;
}
.don_token_table th{
    padding:8px 0 !important;
    width: auto !important;
} 
.don_token_table td {
    padding: 0 !important;
    width: auto !important;
}
table.don_token_table {
    background: #1f2025;
    font-size: 16px;
}
.don_token_table thead tr {
    background-color: #1f2025 !important;
}
.per_token_table tbody tr:nth-child(odd) {
    background: #1f2025;
}
table.per_token_table thead tr,
table.total_token_table thead tr {
    background-color: #1f2025 !important;
    font-size: 16px;
}
.don_token_table thead tr th {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
}
.total_token_table tr th {
    color: #fff !important;
}
.per_token_table thead tr th {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}
.don_token_table tbody tr td {
    color: #fff;
    font-size: 16px;
    border-left: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
}
.don_token_table{
    border: 1px solid #ffffff;
}
.total_token_table tbody tr:nth-child(even) {
    background: #1f2025;
}
.total_token_table tbody tr:nth-child(odd) {
    background: #1f2025;
}
table.per_token_table thead th,
table.total_token_table thead th,
table.per_token_table tbody td,
table.total_token_table tbody td {
    padding: 5px !important;
    white-space: nowrap;
}
.don_token_table tfoot tr {
    background: #1f2025;
}
.don_token_table tfoot td {
    padding: 5px !important;
}
.don_token_table tbody tr td:nth-child(2),
.per_token_table thead tr th:nth-child(2) {
    text-align: right;
}
table tbody td:first-child, table thead th:first-child {
    padding: 3px 0 3px 3px;
    width: 30px;
}
table tbody td {
    font-size: 14px;
}
table tbody td, table thead th {
    font-size: 14px;
}
.per_token_table tr th:first-child{
    border-right: 1px solid #ffffff;
}
.token_table_head {
    display: inline-block;
    font-size: 20px;
    color: #fff;
    position: absolute;
    top: -13px;
    margin-left: 5px;
}
.referral_section {
    background-image: linear-gradient(to top, #000000 0%, #242323 100%);
    text-align: center;
    padding: 50px 0;
    position: relative;
}
.referral_section .ct-particle-animate img {
    
}
.subTitle{
    font-size: 24px;
    margin-top: 15px;
    font-weight: 600;
    color: #ffffff;
}
.launchingSoonText{
    font-size: 42px !important;
    color: #00a5e7 !important;
    font-weight: 600;
}
.nft_section {
    background: url("../src/assets/images/nft_banner.png") no-repeat;
    background-position: right;
    background-size: cover;
    padding: 50px 0;
}
.nft_scene {
    display: flex;
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-30px);
	}
	100% {
		transform: translatey(0px);
	}
}
.nft_scene div {
    animation: float 5s ease-in-out infinite;
    margin: 10px;
}
.nft_scene div:nth-child(odd) {
    margin-top: 100px
}
.coin_info_bg {
    background: url("../src/assets/images/coin_info_bg.png") no-repeat;
    background-position: center;
    background-size: contain;
}
.coin_info_bg h2 {
    font-size: 36px;
    color: #fff;
    font-weight: 400;
}
.coin_info_bg h2 span {
    font-size: 36px;
    color: #055bb6;
    font-family: 'Oxanium', cursive;
    font-weight: 600;
}
.coin_info_bg p {
    font-size: 16px;
    color: #fff;
    padding: 0 200px;
}
.coin_info_table {
    background-image: linear-gradient(to bottom, #3b3b3b, #ffffff, #868686) !important;
    border-radius: 15px;
    padding: 3px;
    cursor: default;
}
.coin_info_table_inner {
    background-image: linear-gradient(to bottom, #e6c501, #e6c501) !important;
    border-radius: 12px;
    padding: 15px 20px;
    position: relative;
}
.coin_info_table_inner table tbody tr td:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
    display: inline-block;
}
.coin_info_table_inner table thead tr th {
    font-size: 16px;
    color: #000;
    padding: 10px;
}
.coin_info_table_inner table tbody tr td {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    padding: 4px 3px;
    padding: 10px;
}
.coin_info_table_panel h2 {
    font-size: 22px;
    text-align: center;
}
.coin_info_section {
    padding: 50px 0;
    background: #0d0d0d;
}
#cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}
#cd-timeline::before {
    content: "";
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 2px;
    background: #323232;
}
.cd-timeline-block {
    position: relative;
    margin: 2em 0;
}
.cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
}
.cd-timeline-block:first-child {
    margin-top: 0;
}
.cd-timeline-block:last-child {
    margin-bottom: 0;
}
.cd-timeline-img {
    position: absolute;
    top: 8px;
    left: 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.cd-timeline-img {
    background: #d63d18;
}
.cd-timeline-content-inner {
    background: #161920;
    border-radius: 2px;
    padding: 1em;
    position: relative;
}
.cd-timeline-content ul {
    padding: 0;
    list-style: none;
}
.cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}
.cd-timeline-content h2 {
    color: #12a2e4;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
    text-align: right;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content h2 {
    text-align: left;
}
.cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}
.cd-timeline-content-inner::before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #292929;
}
.wallet_section {
    background: url("../src/assets/images/walletSupportbg.jpg") no-repeat;
    background-position: center;
    background-size: cover;
}
.wallet_section p {
    font-size: 18px;
    margin: 15px 0 0;
    color: #fff;
}
.wallet_section h5 {
    font-size: 16px;
    color: #b4b4b4;
    margin: 0;
}
.wallet_section h4 {
    font-size: 24px;
    color: #055bb6;
    margin: 0;
}
.footer {
    display: block;
    padding: 0 0 30px 0;
    font-size: 14px;
}
.footer .copyright {
    text-align: center;
    padding-top: 30px;
    color: #012970;
}
.footer_top {
    background-image: linear-gradient(to top, #15a1e4 0%, #0666c4 100%);
    padding: 12px;
}
.footer_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    padding: 0;
    list-style: none;
}
.footer_list li:first-child {
    border-left: 0;
}
.footer_list li {
    margin: 10px 0;
    flex-grow: 1;
    flex-basis: auto;
    text-align: center;
    border-left: 1px solid #ffffff;
}
.footer_list li a {
    font-size: 18px;
    color: #ffffff;
    position: relative;
    transition: 0.2s all;
}
.footer_list li a:hover {
    text-decoration: underline;
}
ul.social_links {
    margin: 15px;
    padding: 0;
    list-style: none;
}
footer ul.social_links li {
    display: inline-block;
    margin: 0 10px;
}
.copyright h5 {
    color: #aeaeae;
    font-size: 18px;
}
.copyright p {
    color: #aeaeae;
    font-size: 14px;
}
.primary_modal {
    z-index: 1150;
}
.primary_modal .modal-dialog .modal-header {
    display: block;
}
.primary_modal .modal-header .close {
    float: none;
    position: absolute;
    color: #ffffff;
    top: 24px;
    right: 20px;
    font-size: 18px;
}
.primary_modal h5.modal-title {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: #961a22;
}
.primary_modal .modal-body {
    max-height: calc( 100vh - 200px );
    overflow: auto;
}
.primary_modal .modal-title {
    font-size: 22px;
    color: #961a22;
    margin: 0;
}
.primary_modal .modal-header {
    border-bottom: 1px solid #961a22;
    padding-top: 20px;
    padding-bottom: 15px;
}
.primary_modal .modal-dialog {
    max-width: 380px;
}
.primary_modal .modal-dialog .modal-content {
    border-radius: 30px;
    background: #0d0d0d;
}
body.modal-open {
    padding-right: 0 !important;
}
.wallet_panel {
    background-color: #0079cd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 16px;
    cursor: pointer;
    margin-bottom: 12px;
}
.wallet_panel img {
    width: 31px;
}
#wallet_modal h5.modal-title {
    color: #0079cd;
}
#wallet_modal .modal-header {
    border-bottom: 1px solid #0079cd;
}
.wallet_panel h2 {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
    font-weight: 700;
}
.MuiTableFooter-root .MuiToolbar-root {
    color: #edeaea;
}
.MuiTableFooter-root .MuiIconButton-root {
    color: #fff;
}
.MuiTableFooter-root .MuiIconButton-root.Mui-disabled {
    color: rgb(255 255 255 / 30%);
}
.MuiTableFooter-root .MuiSelect-icon {
    color: #fff;
}
.wb_btn img {
    width: 28px;
}


/* Media Queries */
@media screen and (max-width: 1279px) {
    .token_details_panel_left::before {
        background: #f3d690;
        width: 100%;
        left: 0;
    }
    .page-header h1 {
        font-size: 36px;
    }
    .page-header h2 {
        font-size: 18px;
    }
    .banner_btn button.auth_btn {
        width: 201px;
        
    }
    .card_how {
        margin: 0 7px;
        margin-bottom: 50px;
        padding: 15px 32px;
    }
    .makeStyles-container-135 {
        color: #FFFFFF;
        width: 100%;
        z-index: 2;
        position: relative;
        margin-left: auto;
        padding-top: 0;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 200px;
    }
    .about_panel_overall {
        justify-content: center;
    } 
}
@media screen and (max-width: 1199px) {
    .header-filter {
        height: 100%;
    }
    .timer_time {
        font-size: 46px;
    }
}
@media screen and (max-width: 1169px) {
    .cd-timeline-content h2 {
        text-align: left;
    }
    .cd-timeline-content {
        margin-left: 70px;
    }
}
@media only screen and (min-width: 1170px) {
    #cd-timeline {
      margin-top: 3em;
      margin-bottom: 3em;
    }
    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-block {
      margin: 4em 0;
    }
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-img {
      width: 15px;
      height: 15px;
      left: 50%;
      margin-left: -8px;
      margin-top: 0;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
    }    
}
@media only screen and (min-width: 1170px) {
    .cd-timeline-content {
      color: #fff;
      margin-left: 0;
      padding: 0 30px 0;
      width: 35%;
      margin: 0 10%;
    }
    .cd-timeline-content-inner::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #292929;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content-inner::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #292929;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }    
  }
@media screen and (max-width: 991px) {
    .token_sale_panel {
        width: auto;
    }
    .page-header h1 {
        font-size: 28px;
        line-height: 28px;
    }
    .timerdiv_panel_inner h2,
    .timerdiv_panel_inner h2 span {
        font-size: 14px !important;
    }
    .timer_time {
        font-size: 42px;
    }
    span.timer_label {
        font-size: 14px;
    }
    .token_sale_panel h5 {
        font-size: 18px;
    }
    .banner_token_btn button.primary_btn {
        font-size: 18px;
        margin-top: 8px;
    }
    .ico_details_panel h2 {
        font-size: 16px;
    }
    .referral_reward p {
        padding: 0;
    }
}
@media screen and (max-width: 959px) {
    .page-header p {
        font-size: 23px;
        line-height: 20px;
        text-align: left;
    }
    .home_page_menu ul {
        display: block;
    }
    .tokensale_section img {
        width: 321px;
        margin: 0 auto;
        display: block;
    }
    .comming_section {
        justify-content: center;
        align-items: center;
    }
    .top_navbar ul {
        display: block;
    }
    .top_navbar_left {
        position: relative;
        bottom: 0;
    }
    .header_home header {
        height: auto;
    }
    .slick_vertical_nav p {
        font-size: 14px;
        margin: 0;
    }
    .slick_vertical_nav h3 {
        font-size: 14px;
    }
    ul.slick_slider_list li {
        margin: 5px 0 0 30px;
        font-size: 14px;
        line-height: 14px;
    }
    .main_title {
        font-size: 24px !important;
    }
    .token_supply h3 {
        font-size: 16px;
    }
    .coin_info_bg p {
        padding: 0 100px;
    }
}
@media screen and (max-width: 767px) {
    .referral_reward {
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .button_referal {
        padding: 27px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card_how {
        margin: 20px 5px;
    }
    .token_supply {
        width: 100%;
    }
    .how_secr_e {
        margin-top: 160px;
    }
    .img-g_rignt {
        margin-left: 0;
        max-width: 421px;
    }
    .account_details_panel {
        display: block;
        text-align: center;
    }
    .account_details_panel .text-right {
        text-align: center !important;
    }
    ul.dash_inner_menu {
        padding: 10px 10px 0;
    }
    .dash_inner_menu li {
        margin: 0 5px;
    }
    .dash_inner_menu li a.nav-link {
        font-size: 14px;
        padding: 10px 5px;
    }
    .auth_panel.forgot_panel {
        padding-bottom: 0;
    }
    .whitepaper_panel {
        right: 20px;
    }
    .footer_contact_form form {
        padding: 10px 60px 25px;
    }
    .single_about .d-flex,
    .dflex_content_banner a {
        display: block !important;
    }
    .single_about p {
        font-size: 14px !important;
        line-height: 18px !important;
    }
    .dflex_content_banner a {
        font-size: 14px !important;
    }
    .dflex_content_banner h4 {
        font-size: 16px !important;
    }
    h2.accordian_head {
        font-size: 18px;
    }
    .login_social_links {
        display: block;
        text-align: center;
    }
    .login_social_links ul li {
        margin: 10px 12px;
    }
    .about_section iframe {
        width: 100%;
    }
    .slick_vertical_nav {
        padding-right: 5px;
    }
    .slick_vertical_nav .slick-prev {
        right: -15px;
    }
    .slick_vertical_nav .slick-next {
        right: -15px;
    }
    .press_section {
        text-align: center;
    }
    .cd-timeline-content ul li {
        font-size: 14px;
    }
    .nft_scene {
        margin-top: 50px;
    }
    .token_table_head {
        position: relative;
        top: 0;
    }
    .per_token_table thead tr th,
    .don_token_table tbody tr td,
    .don_token_table thead tr th {
        font-size: 14px;
    }
    .referral_reward h3 {
        font-size: 24px;
    }
    .referral_reward h2 {
        font-size: 40px;
    }
    .nft_section {
        text-align: center;
    }
    .nft_section .main_title::after {
        right: 0;
        margin: 0 auto;
    } 
    .footer_list li {
        border-left: none;
        display: block;
    }
    .footer_list li a {
        font-size: 14px;
    }
    .footer_list {
        display: block;
    }
    .coin_info_bg h2 {
        font-size: 20px;
    }
    .coin_info_bg h2 span {
        font-size: 20px;
    }
    .about_panel h2 {
        font-size: 24px;
    }
    .page-header h1 span {
        font-size: 20px;
    }
    .coin_info_bg p {
        padding: 0;
    }
}
@media screen and (max-width: 540px) {
    .table_section_{
        overflow: hidden;
        overflow-x: scroll;
    }
    .page-header h6 span {
        font-size: 14px;
    }
    .timer_compnant .time_text {
        font-size: 47px;
     
    }
    .mt-minus_token {
        margin-top: -40px;
    }
    .tokendistripution {
        margin-top: 30px;
    }
    .token_contern_o > li {
        width: 100%;
    }
    .card_how {
        width: 100%;
    }
    .page-header p {
        font-size: 16px;
    }
    .banner_btn {
        margin-top: 0;
        text-align: center;
    }
    .banner_btn button.auth_btn {
        width: 176px;
        margin-right: 15px;
        margin-top: 21px;
        margin-bottom: 52px !important;
    }
    .banner_btn a {
        color: #000;
        font-size: 14px !important;
        font-weight: 600;
    }   
    .for_padin_section {
        padding-right: 0;
        position: relative;
        margin: 0;
        margin-left: 0;
    }
    .for_padin_section h3 {
        font-size: 30px;
    }
    .for_padin_section p {
        font-size: 16px;
    }
    .img-g_rignt {
        margin-left: 0;
        max-width: 359px;
        margin: 0 auto;
        display: block;
    }
    .section_bg_brown_color {
        padding: 80px 0px;
        display: block;
        width: 100%;
    }
    ._display_table{
        display: table;
    }
    .how_secr_e {
        margin-top: 111px;
    }
    .tokensale_section img {
        margin-top: 30px;
        width: 65%;
        margin: 0 auto;
        display: block;
    }
    .comming_section h3 {
        font-size: 25px;
    }
    .comming_section h1 {
        font-size: 40px;
    }
    .chart_img {
        width: 72%;
        float: none;
        margin: 30px 4px;
    }
    .img_teme_sect .img_hover {
        width: 160px;
        overflow: hidden;
        border-radius: 21px;
        margin-bottom: 28px;
        position: relative;
        height: 159px;
        cursor: pointer;
    }
    .referral_reward {
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .button_referal {
        padding: 27px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .FAQ_SWEC h1 {
        font-size: 28px;
    }
    h2.accordian_head {
        color: #fff;
        font-size: 14px;
        margin: 0;
        font-weight: 500;
    }
    .footer_newe__bottom ul {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;
    }
    .footer_newe__bottom ul {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;
    }
    .footer_newe__bottom {
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
    .footer_newe_ h2 {
        font-size: 25px;
    }
    .inner_img_content {
        position: relative;
        bottom: -84px;
    }
    form.auth_form {
        padding: 20px 10px 20px !important;
    }
    .auth_main_div {
        border-radius: 30px;
    }
    .auth_main_div .nav-pills {
        padding: 0;
    }
    .wallet_address_panel {
        padding: 5px 10px 10px;
    }
    button.tron_link_btn {
        font-size: 14px;
    }
    .deposit_address_sec h4,
    .djack_equavalent,
    .token_details_panel h2,
    .wallet_address span {
        font-size: 16px;
    }
    button.primary_btn {
        font-size: 14px;
    }
    .table_box h2 {
        font-size: 15px;
    }
    .table_box .d-flex {
        display: block !important;
        margin-bottom: 15px;
    }
    .table_box .d-flex button {
        margin-top: 10px;
    }
    .dash_inner_padding {
        padding: 5px 10px 40px;
    }
    h2.dash_subtitle {
        font-size: 16px !important;
    }
    .account_details_panel h2 {
        font-size: 14px !important;
    }
    form.auth_form .file_upload_group .auth_inp {
        font-size: 12px;
    }
    .file_upload_group .custom-file-input:lang(en) ~ .custom-file-label::after {
        padding: 8px 24px;
    }
    .timer_time {
        font-size: 32px;
    }
    span.timer_dots {
        font-size: 24px;
        line-height: 75px;
    }
    .whitepaper_section {
        height: auto;
    }
    .whitepaper_panel {
        right: unset;
        width: auto;
        position: relative;
    }
    .ico_details_panel h2 {
        font-size: 14px;
    }
    .ico_details_panel p {
        font-size: 14px;
    }
    .footer_contact_form form {
        padding: 10px 15px 25px;
        border-radius: 10px;
    }
    .footer_contact_form {
        left: 10px;
        right: 10px;
    }
    .section {
        padding: 40px 0;
    }
    p.home_para {
        font-size: 14px;
    }
    .team_details h2 {
        font-size: 18px;
    }
    .team_details h4 {
        font-size: 14px;
    }
    .token_sale_notes {
        display: block;
    }
    .banner_token_btn {
        position: relative;
    }
    .banner_token_btn button.primary_btn {
        padding: 8px 30px;
    }
}

  
h1.token_bal {
    font-size: 35px;
    color: #12a2e4;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    display: none !important;
}
img.img-fluid.mr-1.PayPal {
    width: 25px !important;
}

h4.referal {
    text-align: center !important;
}
h2.referal {
	font-size: 14px !important;
	color: #bcbcbc;
}
.login_empty {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login_empty img {
    max-width: 40px;
    margin-bottom: 10px;
}
.custom_phone input.PhoneInputInput {
    height: 42px;
}
a.tb_btn.eth {
    margin: auto;
}
.slick-track {
    height: auto !important;
}
.dash_inner_padding {
    padding: 11px 22px 40px;
}
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.embed-container embed,
.embed-container iframe,
.embed-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



/* radio _dashboard_butn */
.radio_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radio_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: transparent;
	border-radius: 50%;
	border: 1px solid #ee561a;
}
  
  /* On mouse-over, add a grey background color */
  .radio_container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .radio_container:hover input ~ .checkmark {
	background-color: #1a1c1f;
}
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio_container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radio_container .checkmark::after {
	top: 5px;
	left: 5px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #ee561a;
}
  .radio_paran_container {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.radio_container b {
	color: #055bb6;
	margin-left: 3px;
}
.a_hl_wet {
	width: 100%;
	display: block;
	text-align: center;
	text-transform: capitalize;
}
.table_section_ .table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid rgba(0, 0, 0, 0.06);
	background: #ffdb00;
    color: #000 !important;
}
.table_section_ .table td {
	padding: 0.75rem;
	vertical-align: top;
	border: 1px solid rgba(255, 219, 0, 0.42);
	color: #fff;
}
.padding_radio_butom {
	padding: 4px 1px;
	padding-left: 28px;
}
.dflex_content_banner {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.dflex_content_banner h4 {
    margin: 0;
    color: #d53d18;
    font-size: 20px;
}
.dflex_content_banner a {
    color: #055bb6;
    text-decoration: none;
    font-size: 15px;
}
.dflex_content_banner a:first-child {
    margin-right: 10px;
}
.padin_ri {
	padding: 5px 9px;
}
.font_sise {
	font-size: 28px !important;
	text-transform: capitalize;
	margin-top: -20px;
	margin-bottom: 10px;
	color: #fff !important;
}
.dlfec_s{
    display: flex;
    justify-content: center;
    align-items: center;
}
.dlfec_s img{
    width: 36px;
    margin-right: 6px;
}
.colot_whit{
    color: #fff;
}
.font_token {
	font-size: 20px !important;
	font-weight: 600 !important;
	margin-bottom: 10px !important;
	color: #f7d202 !important;
}
.padingtop_80{
    padding-top: 83px;

}